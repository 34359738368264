import { createAsyncThunk } from '@reduxjs/toolkit';
import { statsService } from '@services/stats.service';

const getError = (error: any, thunkAPI: any) => {
  const message = (error.data && error.data.message) || error.message || error.toString();
  return thunkAPI.rejectWithValue(message);
};

export const getActiveUsers: any = createAsyncThunk('stats/getActiveUsers', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getActiveUsers(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getAllTasks: any = createAsyncThunk('stats/getAllTasks', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getOpenTasks(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUsersTasks: any = createAsyncThunk('stats/getUsersTasks', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getUsersTasks(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUsersGoals: any = createAsyncThunk('stats/getUsersGoals', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getUsersGoals(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getPlanedTasks: any = createAsyncThunk('stats/getPlanedTasks', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getOpenTasks(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getCompletionTasks: any = createAsyncThunk('stats/getCompletionTasks', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getCompletionTasks(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getCreationTasks: any = createAsyncThunk('stats/getCreationTasks', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getCreationTasks(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getClosureTasks: any = createAsyncThunk('stats/getClosureTasks', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getClosureTasks(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getBusinessGoals: any = createAsyncThunk('stats/getBusinessGoals', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getBusinessGoals(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getPersonalGoals: any = createAsyncThunk('stats/getPersonalGoals', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await statsService.getPersonalGoals(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
