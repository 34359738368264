import React, { useState } from 'react';

import { Modal } from '../../../../../ui/modal';
import { useFormik } from 'formik';
import cn from 'classnames';
import { adminSchema } from '@components/schemas';
import { FormattedMessage, useIntl } from 'react-intl';
import './modalAdmin.scss';

interface IModalAdmin {
  handleAddAdmin: (params: {}) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalAdmin = ({ setIsVisible, handleAddAdmin }: IModalAdmin) => {
  const intl = useIntl();

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      login: '',
      password: '',
      repassword: '',
    },
    validationSchema: adminSchema,
    onSubmit: (values) => {
      handleAddAdmin(values);
    },
  });

  return (
    <Modal setIsVisible={setIsVisible}>
      <form className="modal-admin" onSubmit={handleSubmit} action="">
        <h1>
          <FormattedMessage id="addAdmin" />
        </h1>
        <div className="body">
          <div>
            <span>
              <FormattedMessage id="login" />
            </span>
            <input
              id="login"
              onBlur={handleBlur}
              value={values.login}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'enterLogin' })}
              className={cn({ 'input-error': errors.login && touched.login })}
            />
            {errors.login && touched.login && (
              <div className="error">
                <span className="line-error">{errors.login}</span>
              </div>
            )}
          </div>
          <div>
            <span>
              <FormattedMessage id="password" />
            </span>
            <input
              id="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              placeholder={intl.formatMessage({ id: 'enterPassword' })}
              className={cn({ 'input-error': errors.password && touched.password })}
            />
            {errors.password && touched.password && (
              <div className="error">
                <span className="line-error">{errors.password}</span>
              </div>
            )}
          </div>
          <div>
            <span>
              <FormattedMessage id="repeatPassword" />
            </span>
            <input
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'enterPassword' })}
              value={values.repassword}
              id="repassword"
              className={cn({ 'input-error': errors.repassword && touched.repassword })}
            />
            {errors.repassword && touched.repassword && (
              <div className="error">
                <span className="line-error">{errors.repassword}</span>
              </div>
            )}
          </div>
        </div>
        <button type="submit">
          <FormattedMessage id="add" />
        </button>
      </form>
    </Modal>
  );
};
