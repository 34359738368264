import { http } from '../utils/http';

class AssistService {
  constructor() {}

  getAssists = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/assistants/page?${params}`);
  };

  getManagers = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/managers/page?${params}`);
  };

  addAssists = async (data: any): Promise<any> => {
    return await http.post(`/api/settings/v1/assistant-settings/managers/add`, data);
  };

  deleteAssists = async (data: any): Promise<any> => {
    return await http.post(`/api/settings/v1/assistant-settings/managers/remove`, data);
  };

  updateAssists = async (data: any): Promise<any> => {
    return await http.patch(`/api/settings/v1/assistant-settings/managers`, data);
  };
  updateManager = async (data: any): Promise<any> => {
    return await http.patch(`/api/settings/v1/assistant-settings/assistants`, data);
  };
}

export const assistService = new AssistService();
