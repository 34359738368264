import React, { useMemo } from 'react';

import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { mobile, tablet } from '@components/constants/constants';
import styled from 'styled-components';

interface ITabsBarAssist {
  openForm: (arg: boolean) => void;
}

export const EmptyPage = ({ openForm }: ITabsBarAssist) => {
  const { isWideScreen, isTablet } = useWidthScreens();

  const sizeFont = useMemo(() => {
    if (isWideScreen) return '32px';
    return isTablet ? '28px' : '22px';
  }, [isWideScreen, isTablet]);

  return (
    <Container className="flex ">
      <ContWrap>
        <LineText fontSize={sizeFont}>Еще нет ни одного ассистента</LineText>
        <div className="flex  justify-center px-12">
          <ButtonMain buttonText="addAssist" clickEvent={() => openForm(true)} />
        </div>
      </ContWrap>
    </Container>
  );
};

export default EmptyPage;

const Container = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const ContWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px;
  gap: 24px;
  border-radius: 20px;
  background-color: ${colorsVar.white};

  @media screen and (max-width: ${tablet}px) {
    padding: 36px;
  }
  @media screen and (max-width: ${mobile}px) {
    padding: 12px;
    background-color: transparent;
  }
`;
