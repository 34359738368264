import React, { useRef, useState } from 'react';

import { ReactComponent as BinGreyIcon } from '@assets/icons/bin_ 821.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/check.svg';
import { ReactComponent as SetMenuIcon } from '@assets/icons/menu-set.svg';
import { IStateUser } from 'interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';

import cn from 'classnames';
import { useClickAway } from 'react-use';
import dayjs from 'dayjs';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';

interface ITableItemOthers {
  user: IStateUser;
  supervisor?: boolean;
  index: number;
  handleDelete?: (id: string) => void;
  handleAddEmployees?: (id: string) => void;
  onChangeTest?: (id: string, login: string, test: boolean) => void;
  onChangeAccess?: (id: string, login: string, isEnabled: boolean) => void;
}

export const TableItemOthers = ({ user, index, onChangeTest, handleDelete, handleAddEmployees }: ITableItemOthers) => {
  const [isActive, setIsActive] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsActive(false);
  });

  const getLogin = (user: IStateUser) => {
    let data = { id: '', login: '' };
    user.accounts &&
      user.accounts.map((account) => {
        if (account.type === 'PHONE_NUMBER') {
          data.login = account.login;
          data.id = account.id;
        }
        return account;
      });
    return data;
  };

  return (
    <div className="row" style={{ backgroundColor: index % 2 === 0 ? '#F5F9F8' : '#FFFFFF' }}>
      <div>
        <span>{getLogin(user).login}</span>
      </div>
      <div>
        <span>{`${user.lastName} ${user.firstName} ${user.middleName} `}</span>
      </div>
      <div className="dates">
        <RenderWithCondition condition={!!user?.activity?.firstActivityDate}>
          <span>{dayjs(user?.activity?.firstActivityDate).format('DD.MM.YY')}</span>
        </RenderWithCondition>
      </div>
      <div className="dates">
        <span>{user?.activity?.lastActivityDate && dayjs(user?.activity?.lastActivityDate).format('DD.MM.YY')}</span>
      </div>
      <div>
        <input
          type="checkbox"
          className="check-input"
          checked={!!user.isTest}
          onChange={(e) => onChangeTest && onChangeTest(user.id, user.login, e.target.checked)}
        />
        <label className="check"></label>
      </div>
      <div
        ref={ref}
        onClick={() => setIsActive(!isActive)}
        style={{ display: 'flex', width: 22, cursor: 'pointer', justifyContent: 'center', position: 'relative' }}
      >
        <SetMenuIcon fill="#5F6E67" />
        <div className={cn('tooltip', { active: isActive })}>
          <div className="tooltip-item" onClick={() => handleDelete && handleDelete(user.id)}>
            <span>
              <BinGreyIcon />
            </span>
            <span>
              <FormattedMessage id="delete" />
            </span>
          </div>
          <div className="tooltip-item" onClick={() => handleAddEmployees && handleAddEmployees(user.id)}>
            <span>
              <CheckIcon />
            </span>
            <span>
              <FormattedMessage id="addIntoEmployees" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
