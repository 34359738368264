import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { businessGoalsData } from '../../constants';
import { LineStatChart } from '@components/shared/lineChart/LineStatChart';
import { formattedDateMonth } from '../../statsUtils';
import { useStatsSelector } from '@store/statistic/selectors';
import { getBusinessGoals } from '@store/statistic/api';
import { addEndBusinessDate, addStartBusinessDate } from '@store/statistic/slice';
import { colorsVar } from '@components/constants/colorVariables';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import ControlBlockChart from '../ControlBlockChart';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';

interface ChartBusinessGoalsProps {
  tooltip: ({ active, payload, label }: any) => JSX.Element | null;
  position: CalendarPosition;
  isLoadingPage: boolean;
}

export const ChartBusinessGoals = ({ tooltip, position, isLoadingPage }: ChartBusinessGoalsProps) => {
  const { businessGoalsMonth, businessGoalsPeriod, isFavorites, isLoadingBusinessGoals } = useStatsSelector(
    (state) => state.stats,
  );

  const prevPeriod = useRef(businessGoalsPeriod);

  const dispatch = useDispatch();

  const handleStartDate = (event: dayjs.Dayjs) => {
    dispatch(addStartBusinessDate(event));
  };
  const handleEndDate = (event: dayjs.Dayjs) => {
    dispatch(addEndBusinessDate(event));
  };

  useEffect(() => {
    if (!businessGoalsMonth.length && isEmpty(businessGoalsPeriod)) {
      dispatch(addStartBusinessDate(dayjs().add(-12, 'month')));
      dispatch(addEndBusinessDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(businessGoalsPeriod)) {
      const value = isFavorites ? 'true' : 'false';
      const paramsString = new URLSearchParams({
        favoritesOnly: value,
        startYearMonth: dayjs(businessGoalsPeriod.startDate).format('YYYY-MM'),
        endYearMonth: dayjs(businessGoalsPeriod.endDate).format('YYYY-MM'),
      }).toString();
      if (!isEqual(prevPeriod.current, businessGoalsPeriod)) {
        dispatch(getBusinessGoals(paramsString));
      }
      prevPeriod.current = businessGoalsPeriod;
    }
  }, [businessGoalsPeriod, isFavorites]);

  return (
    <>
      <div className="graf-task">
        <ControlBlockChart
          onStartDate={handleStartDate}
          onEndDate={handleEndDate}
          startDate={businessGoalsPeriod.startDate}
          endDate={businessGoalsPeriod.endDate}
          position={position}
          textTitle="businessGoals"
        />
        <div className="graf-item">
          <span style={{ paddingLeft: 60, height: 10 }}></span>
          <RenderWithCondition condition={!isLoadingPage}>
            <LineStatChart
              fill={colorsVar.textColor}
              dot={{ r: 3 }}
              pointXaxis="yearMonth"
              data={formattedDateMonth(businessGoalsMonth)}
              renderLine={businessGoalsData}
              externalTooltip={tooltip}
            />
          </RenderWithCondition>
          <LocalLoader condition={isLoadingBusinessGoals || (isLoadingPage ?? false)} size={25} />
        </div>
      </div>
    </>
  );
};
