import { colorsVar } from '@components/constants/colorVariables';
import React from 'react';
import { Cell, Customized, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import './pieStatChart.scss';
import { uniqueId } from 'lodash';

interface PieStatChartProps {
  data: any;
  total: string;
}

export const PieStatChart = ({ data, total }: PieStatChartProps) => {
  const renderText = (props: any) => {
    const text = `${total} задач`;

    return (
      <g>
        <text x={'50%'} y={'50%'} dy={10} textAnchor="middle" fill={colorsVar.textColor}>
          <tspan className="text-center">{total ? '100%' : ''}</tspan>
        </text>
        {/* <text
          x={'50%'}
          y={'50%'}
          textAnchor="middle"
          dominantBaseline="central"
          fill={colorsVar.textColor}
          dy={10} >
          <tspan className='text-center'>{total ? text : ''}</tspan>
        </text> */}
      </g>
    );
  };

  // const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  //   const radius = outerRadius + 20; const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180)); const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
  //   return (
  //     <g>
  //       <line x1={cx} y1={cy} x2={x} y2={y} stroke="#8884d8" strokeWidth={2} /> <text x={x + 10} y={y + 20} fill="#8884d8" textAnchor="start" dominantBaseline="central">
  //         {`${data[index].name} (${(percent * 100).toFixed(0)}%)`}
  //       </text>
  //     </g>);
  // };

  return (
    <>
      <PieChart width={320} height={300} style={{ padding: 0 }}>
        <Pie
          cx={150}
          cy={150}
          textAnchor="start"
          aria-label="middle"
          label
          // azimuth={40}
          startAngle={410}
          endAngle={-50}
          labelLine={true}
          data={data}
          innerRadius={70}
          outerRadius={110}
          // fill="#8884d8"
          // paddingAngle={1}
          dataKey="value"
          nameKey="name"
          // activeShape={renderText}
        >
          {data.map((entry: any, index: number) => (
            <Cell key={uniqueId('cell')} fill={entry.fill} />
          ))}
        </Pie>
        {/* <Legend layout="vertical" align="left" verticalAlign="middle" style={{ paddingLeft: 10 }} >
        </Legend> */}
        <Customized component={renderText} />
        {/* <Tooltip /> */}
      </PieChart>
    </>
  );
};
