import { LocalLoader } from '@ui/loader/LocalLoader';
import styled from 'styled-components';

interface Props {
  condition: boolean;
  children: any;
}

export const RenderTableWithCondition = ({ condition, children }: Props) => {
  if (condition) {
    return (
      <Container>
        <LocalLoader condition={condition} posY="30px" />
      </Container>
    );
  }
  return children;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
