import React, { useEffect, useState } from 'react';

import { RenderTableWithCondition } from '@components/hoc/RenderTableWithCondition';
import { FormattedMessage } from 'react-intl';
import { formattedTooltip } from './statsUtils';
import { TooltipChart } from './components/tooltips/TooltipChart';
import { LineChartUsers } from './components/chartUsers/LineChartUsers';
import { PieChartTasks } from './components/pieChartTasks';
import { PieChartBusiness } from './components/pieChartBusiness';
import { ChartPlanComplete } from './components/chartPlanComplete';
import { ChartBusinessGoals } from './components/chartBusinessGoals';
import { ChartPersonalGoals } from './components/chartPersonalGoals';
import { RadioButtonsCheck } from '@ui/radioButtonsCheck';
import { closureTasksData, completeTasksData, creationTasksData, planedTasksData } from './constants';
import { useStatsSelector } from '@store/statistic/selectors';
import {
  addEndClosureDate,
  addEndCompleteDate,
  addEndCreationDate,
  addEndPlanedDate,
  addStartClosureDate,
  addStartCompleteDate,
  addStartCreationDate,
  addStartPlanedDate,
} from '@store/statistic/slice';
import { getClosureTasks, getCompletionTasks, getCreationTasks, getPlanedTasks } from '@store/statistic/api';
import { ChartTask } from './components/сhartTask';
import './home.scss';
import { colorsVar } from '@components/constants/colorVariables';
import { mapCompleteTasks, mapPlanedTasks } from '../month/components/tableDynamics/charts/constants';
import { useAppSelector } from '@store/hooks';
import { selectDeviceSlice } from '@store/device/device.slice';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';

export const commonTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return <TooltipChart label={label} payload={payload} formattedTooltip={formattedTooltip} />;
  }
  return null;
};

export const Home = () => {
  const { width } = useWidthScreens();

  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    creationTasks,
    creationTasksPeriod,
    closureTasks,
    closureTasksPeriod,
    isFavorites,
    planedTasks,
    planedTasksPeriod,
    completeTasks,
    completeTasksPeriod,
    isLoadingPlanedTasks,
    isLoadingCompleteTasks,
    isLoadingCreationTasks,
    isLoadingClosureTasks,
  } = useStatsSelector((state) => state.stats);

  const { isHiddenNavbar } = useAppSelector(selectDeviceSlice);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, [isHiddenNavbar, width]);

  return (
    <>
      <div className="stats-container">
        <RenderTableWithCondition condition={false}>
          <>
            <div className="users-wrap">
              <h1>
                <FormattedMessage id="statisticTitle" />
              </h1>
              <div className="users-title">
                <h2>
                  <FormattedMessage id="amountActiveUsers" />
                </h2>
                <span>
                  <span style={{ color: colorsVar.redLine }}>*</span>
                  <FormattedMessage id="aboutActiveUsers" />
                </span>
              </div>
              <div className="graf-container">
                <LineChartUsers tooltip={commonTooltip} position="right" isLoadingPage={isLoading} />
              </div>
            </div>
          </>
        </RenderTableWithCondition>
        <RenderTableWithCondition condition={false}>
          <>
            <div className="users-wrap tasks goals">
              <div className="graf-container">
                <PieChartTasks position="left" isLoadingPage={isLoading} />
                <PieChartBusiness position="left" isLoadingPage={isLoading} />
              </div>
            </div>
          </>
        </RenderTableWithCondition>
        <RenderTableWithCondition condition={false}>
          <>
            <div className="users-wrap tasks">
              <div className="title-dep">
                <h1>
                  <FormattedMessage id="statisticTitle2" />
                </h1>
                <div className=" task-filter">
                  {/* //TODO: пока не показывать */}
                  {/* <RadioButtonsCheck />   */}
                </div>
              </div>
              <>
                <div className="users-title second">
                  <h2>
                    <FormattedMessage id="businessTasks" />
                  </h2>
                </div>
              </>
              <div className="graf-container">
                <>
                  <ChartItem className="flex">
                    <ChartPlanComplete
                      planed={true}
                      data={planedTasks}
                      tooltip={commonTooltip}
                      getData={getPlanedTasks}
                      period={planedTasksPeriod}
                      renderLine={planedTasksData}
                      setEndDate={addEndPlanedDate}
                      isLoading={isLoadingPlanedTasks}
                      setStartDate={addStartPlanedDate}
                      titleChart="dynamicsPlaningTasks"
                      lineRectanglesData={mapPlanedTasks}
                      wrap
                      position="right"
                      isLoadingPage={isLoading}
                    />
                  </ChartItem>
                  <ChartItem className="flex">
                    <ChartPlanComplete
                      data={completeTasks}
                      tooltip={commonTooltip}
                      getData={getCompletionTasks}
                      period={completeTasksPeriod}
                      renderLine={completeTasksData}
                      setEndDate={addEndCompleteDate}
                      isLoading={isLoadingCompleteTasks}
                      setStartDate={addStartCompleteDate}
                      titleChart="closePlansDynamics"
                      lineRectanglesData={mapCompleteTasks}
                      wrap
                      position="right"
                      isLoadingPage={isLoading}
                    />
                  </ChartItem>
                  <ChartTask
                    data={creationTasks}
                    tooltip={commonTooltip}
                    nameLine="totalCreation"
                    isFavorites={isFavorites}
                    getData={getCreationTasks}
                    period={creationTasksPeriod}
                    renderLine={creationTasksData}
                    titleChart="createTasksDynamics"
                    setEndDate={addEndCreationDate}
                    isLoading={isLoadingCreationTasks}
                    setStartDate={addStartCreationDate}
                    position="right"
                    isLoadingPage={isLoading}
                  />
                  <ChartTask
                    data={closureTasks}
                    nameLine="totalClosure"
                    tooltip={commonTooltip}
                    getData={getClosureTasks}
                    isFavorites={isFavorites}
                    titleChart="closedDynamics"
                    period={closureTasksPeriod}
                    renderLine={closureTasksData}
                    setEndDate={addEndClosureDate}
                    isLoading={isLoadingClosureTasks}
                    setStartDate={addStartClosureDate}
                    position="right"
                    isLoadingPage={isLoading}
                  />
                  <ChartBusinessGoals tooltip={commonTooltip} position="right" isLoadingPage={isLoading} />
                  <ChartPersonalGoals tooltip={commonTooltip} position="right" isLoadingPage={isLoading} />
                </>
              </div>
            </div>
          </>
        </RenderTableWithCondition>
      </div>
    </>
  );
};

const ChartItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  @media screen and (max-width: 1169px) {
    width: 100%;
  }
`;
