import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectDateMonth } from '@components/shared/selectDate/SelectDateMonth';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { LineText, Spacer } from '@components/commons/commonsStyled';
import styled from 'styled-components';
import { Dayjs } from 'dayjs';
import { SelectLine } from '@ui/selectLine';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';

interface IControlBlockChart {
  onStartDate: (event: Dayjs) => void;
  onEndDate: (event: Dayjs) => void;
  startDate: string;
  endDate: string;
  position: CalendarPosition;
  textTitle: string;
  openSelectLines?: boolean;
}

const ControlBlockChart: FC<IControlBlockChart> = (props) => {
  const [isWideScreen, setIsWideScreen] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1170px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <Container className="flex">
      <LineText fontSize="18px" bold={'700'}>
        <FormattedMessage id={props.textTitle} />
      </LineText>
      <GroupSelect className="flex">
        <BlockSelect className="flex">
          <RenderWithCondition condition={props.openSelectLines && !isWideScreen}>
            <>
              <SelectLine />
              <Spacer px={10} />
            </>
          </RenderWithCondition>
          <SelectDateMonth
            type="input"
            onChange={props.onStartDate}
            id="firstEntryDateStartRange"
            value={props.startDate}
            position={props.position}
          />
          <span>-</span>
          <SelectDateMonth
            type="input"
            id="firstEntryDateStartRange"
            onChange={props.onEndDate}
            value={props.endDate}
            position={props.position}
          />
        </BlockSelect>
        <RenderWithCondition condition={props.openSelectLines && isWideScreen}>
          <SelectLine />
        </RenderWithCondition>
        {/* </div> */}
      </GroupSelect>
    </Container>
  );
};

export default ControlBlockChart;

const Container = styled.div`
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding-bottom: 20px;
  @media screen and (max-width: 1169px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const GroupSelect = styled.div`
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  position: relative;
`;

const BlockSelect = styled.div`
  align-items: center;
  gap: 7px;
`;
