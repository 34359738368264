import React, { useState, useEffect, ChangeEvent, useRef } from 'react';

import { ModalRenderWithCondition } from '@components/hoc/ModalRenderWithCondition';
import { TableAdmin } from './components/tableAdmin';
import { ModalAdmin } from './components/modalAdmin';
import { ModalFilter } from '@components/shared/modalFilter';
import { ControlBlock } from '@components/shared/controlBlock';
import { titleAdmin } from './constants';
import { useDispatch } from 'react-redux';
import { getAdmins, updateAdmin, getMoreAdmins, createAdmin, deleteAdmin } from '@store/admin/api';
import { useAdmins } from '@store/admin/selectors';
import { RenderTableWithCondition } from '@components/hoc/RenderTableWithCondition';
import { LIMIT_PAGE } from '@components/constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { IAddAdmin, IStateUser } from 'interfaces/user.interfaces';
import { ModalChooseAdmin } from './components/modalChooseAdmin';
import { addNextAdmin, setFilterAdmins } from '@store/admin/slice';
import { debounce } from 'lodash';
import { ModalPasswordAdmin } from './components/modalPasswordAdmin/ModalPasswordAdmin';
import { useAuthSelector } from '@store/auth/selectors';
import { getFilterWithoutNull } from '../../../utils/getOptionsDepartments';

export const Admin = () => {
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isVisibleAdmin, setIsVisibleAdmin] = useState(false);
  const [isVisiblePasswordAdmin, setVisiblePasswordAdmin] = useState(false);
  const [isVisibleChooseAdmin, setIsVisibleChooseAdmin] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');
  // const [valueDebonced] = useDebounce(search, 2500);

  const touchedSearchRef = useRef(false);
  if (search) touchedSearchRef.current = true;

  const refId = useRef({} as IStateUser);
  const refIdPass = useRef('');

  // @ts-ignore
  const { admins, isLoading, lengthList, filter } = useAdmins();
  const { supervisor } = useAuthSelector((state) => state.auth);

  // console.log('admins', admins);
  const { nextLoad, start } = usePagination(lengthList, LIMIT_PAGE);
  const dispatch = useDispatch();

  const debounceValue = debounce((value) => {
    setSearch(value);
  }, 2000);

  useEffect(() => {
    const usedFilter = Object.values(filter).every((value) => {
      if (value === null) {
        return true;
      }
      return false;
    });
    setIsFiltered(usedFilter);
  }, [filter]);

  useEffect(() => {
    if (!isFiltered) {
      handleFilteredAdmins();
    } else {
      const paramsString = new URLSearchParams({
        adminOnly: 'true',
      }).toString();
      dispatch(getAdmins(paramsString));
    }
  }, []);

  useEffect(() => {
    if (search || touchedSearchRef.current) {
      const tempObj = getFilterWithoutNull(filter);
      const paramsString = new URLSearchParams({
        query: search,
        adminOnly: 'true',
        ...tempObj,
      }).toString();
      dispatch(getAdmins(paramsString));
    }
  }, [search]);

  const handleChoosedmin = (user: IStateUser) => {
    setIsVisibleChooseAdmin(false);
    setIsVisibleAdmin(true);
    dispatch(addNextAdmin(user));
    refId.current = user;
  };

  const openForm = () => {
    // dispatch(getAllUsers())
    setIsVisibleChooseAdmin(true);
  };

  const handleAddAdmin = async (data: IAddAdmin) => {
    const { id, firstName, lastName } = refId.current;
    const newData = {
      id,
      data: {
        login: data.login,
        isAdmin: 'true',
        password: data.password,
        type: 'LOGIN',
        firstName,
        lastName,
      },
    };
    // @ts-ignore

    dispatch(createAdmin(newData));
    setIsVisibleAdmin(false);
    refId.current = {} as IStateUser;
  };

  const handleGetMoreAdmins = () => {
    nextLoad();
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      adminOnly: 'true',
      page: `${start}`,
      ...tempObj,
      size: `20`,
    }).toString();
    if (Number.isInteger(lengthList / LIMIT_PAGE)) {
      dispatch(getMoreAdmins(paramsString));
    }
  };

  const handleSearchAdmins = (event: ChangeEvent<HTMLInputElement>) => {
    debounceValue(event.target.value);
  };

  const handleDeleteAdmin = (id: string, data: Record<string, string>) => {
    console.log('login', id, data);

    const newData = {
      id,
      data: {
        ...data,
        type: 'LOGIN',
      },
    };
    // @ts-ignore
    dispatch(deleteAdmin(newData));
  };

  const handleGetIdPassword = (id: string) => {
    refIdPass.current = id;
  };

  const handleChangePassword = (values: Record<string, string>) => {
    const newData = { id: refIdPass.current, data: { password: values.password } };
    // @ts-ignore
    dispatch(updateAdmin(newData));
    setVisiblePasswordAdmin(false);
  };
  const handleFilteredAdmins = () => {
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      adminOnly: 'true',
      ...tempObj,
    }).toString();

    setIsVisibleFilter(false);
    dispatch(getAdmins(paramsString));
  };

  return (
    <>
      <ControlBlock
        title={titleAdmin}
        isAdmin={true}
        openForm={openForm}
        openFilter={setIsVisibleFilter}
        handleSearch={handleSearchAdmins}
      />
      <RenderTableWithCondition condition={!admins?.length && !search && isFiltered}>
        <TableAdmin
          adminsList={admins}
          isLoading={isLoading}
          handleGetAdmins={handleGetMoreAdmins}
          handleDeleteAdmin={handleDeleteAdmin}
          setModalOpen={setVisiblePasswordAdmin}
          handleGetIdPassword={handleGetIdPassword}
        />
      </RenderTableWithCondition>
      <ModalRenderWithCondition condition={isVisibleFilter}>
        <ModalFilter
          filter={filter}
          isFiltered={isFiltered}
          setFilter={setFilterAdmins}
          handleFiltered={handleFilteredAdmins}
          setIsVisible={setIsVisibleFilter}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleAdmin}>
        <ModalAdmin
          setIsVisible={setIsVisibleAdmin}
          // @ts-ignore
          handleAddAdmin={handleAddAdmin}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisiblePasswordAdmin}>
        <ModalPasswordAdmin
          setIsVisible={setVisiblePasswordAdmin}
          // @ts-ignore
          handleChangePassword={handleChangePassword}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleChooseAdmin}>
        <ModalChooseAdmin setIsVisible={setIsVisibleChooseAdmin} handleChoose={handleChoosedmin} />
      </ModalRenderWithCondition>
    </>
  );
};
