import React, { useEffect, useState, useCallback, ChangeEvent, useRef, FC } from 'react';

import { ModalRenderWithCondition } from '@components/hoc/ModalRenderWithCondition';
import { TableOthers } from './components/tableOthers';
import { ModalUser } from '@components/shared/modalUser';
import { ModalFilter } from '@components/shared/modalFilter';
import { ControlBlock } from '@components/shared/controlBlock';
import { titleOthers } from './constants';
import { useOtherSelector } from '@store/other/selectors';
import { useDispatch } from 'react-redux';
import {
  addOtherIntoEmployees,
  createOther,
  deleteOther,
  getMoreOthers,
  getOthers,
  updateOther,
} from '@store/other/api';
import { RenderTableWithCondition } from '@components/hoc/RenderTableWithCondition';
import { LIMIT_PAGE } from '@components/constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { deleteFromOther, setFilterOthers } from '@store/other/slice';
import { debounce } from 'lodash';
import { getFilterWithoutNull } from '../../../utils/getOptionsDepartments';
import { getSpaceId } from '@store/admin/api';
import { useAdminSelector } from '@store/admin/selectors';
import { SPACE_ID_DOGMA } from '@configs/consts';
import ModalNotification from '@components/shared/modalNotification/ModalNotification';
import { colorsVar } from '@components/constants/colorVariables';

const Others: FC = () => {
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isVisibleUser, setIsVisibleUser] = useState(false);
  const [isVisibleNotification, setVisibleNotification] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');

  const touchedSearchRef = useRef(false);
  if (search) touchedSearchRef.current = true;

  const { others, isLoading, lengthList, filter } = useOtherSelector((state) => state.other);
  const { spaceId } = useAdminSelector((state) => state.admin);

  const dispatch = useDispatch();
  const refUserId = useRef('');

  const { nextLoad, start } = usePagination(lengthList, LIMIT_PAGE);

  const debounceValue = debounce((value) => {
    setSearch(value);
  }, 2000);

  useEffect(() => {
    const usedFilter = Object.values(filter).every((value) => {
      if (value === null) {
        return true;
      }
      return false;
    });
    setIsFiltered(usedFilter);
  }, [filter]);

  useEffect(() => {
    if (!isFiltered) {
      handleFilteredOther();
    } else {
      const paramsString = new URLSearchParams({
        thirdPartyUsersOnly: 'true',
      }).toString();
      dispatch(getOthers(paramsString));
    }
  }, []);

  useEffect(() => {
    if (!spaceId) {
      dispatch(getSpaceId());
    }
  }, [spaceId]);

  useEffect(() => {
    if (search || touchedSearchRef.current) {
      const tempObj = getFilterWithoutNull(filter);
      const paramsString = new URLSearchParams({
        query: search,
        thirdPartyUsersOnly: 'true',
        ...tempObj,
      }).toString();
      dispatch(getOthers(paramsString));
    }
  }, [search]);

  const handleGetMoreOthers = () => {
    nextLoad();
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      thirdPartyUsersOnly: 'true',
      ...tempObj,
      page: `${start}`,
    }).toString();
    if (Number.isInteger(lengthList / LIMIT_PAGE)) {
      dispatch(getMoreOthers(paramsString));
    }
  };

  const handleCreateOther = (data: any) => {
    const newData = {
      ...data,
      type: 'PHONE_NUMBER',
    };
    setIsVisibleUser(false);
    dispatch(createOther(newData));
  };

  const handleSearchOthers = (event: ChangeEvent<HTMLInputElement>) => {
    debounceValue(event.target.value);
  };

  const handleFilteredOther = () => {
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      thirdPartyUsersOnly: 'true',
      ...tempObj,
    }).toString();

    setIsVisibleFilter(false);
    dispatch(getOthers(paramsString));
  };

  const handleChangeTest = useCallback((id: string, login: string, test: boolean) => {
    const newData = { id, data: { isTest: test } };
    dispatch(updateOther(newData));
  }, []);

  const handleDeleteOther = (id: string) => {
    refUserId.current = id;
    setVisibleNotification(true);
  };

  const handleAddEmployees = (id: string) => {
    dispatch(addOtherIntoEmployees({ id, spaceId: SPACE_ID_DOGMA }));
    dispatch(deleteFromOther({ id }));
  };

  const confirmAccess = () => {
    setVisibleNotification(false);
    dispatch(deleteOther(refUserId.current));
  };

  return (
    <>
      <ControlBlock
        title={titleOthers}
        openForm={setIsVisibleUser}
        openFilter={setIsVisibleFilter}
        handleSearch={handleSearchOthers}
      />
      <RenderTableWithCondition condition={!others?.length && !search && isFiltered}>
        <TableOthers
          othersList={others}
          isLoading={isLoading}
          handleDelete={handleDeleteOther}
          handleAddEmployees={handleAddEmployees}
          handleGetOthers={handleGetMoreOthers}
          handleChangeTest={handleChangeTest}
        />
      </RenderTableWithCondition>
      <ModalRenderWithCondition condition={isVisibleUser}>
        <ModalUser isEmployee={false} setIsVisible={setIsVisibleUser} handleCreate={handleCreateOther} />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleFilter}>
        <ModalFilter
          filter={filter}
          isOthers={true}
          isFiltered={isFiltered}
          setFilter={setFilterOthers}
          setIsVisible={setIsVisibleFilter}
          handleFiltered={handleFilteredOther}
        />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isVisibleNotification}>
        <ModalNotification
          confirmAccess={confirmAccess}
          setIsVisible={setVisibleNotification}
          textTitle={'wantToDeleteUser'}
          colorButton={colorsVar.redLine}
        />
      </ModalRenderWithCondition>
    </>
  );
};

export default Others;
