import dayjs from 'dayjs';
import { capitalize } from 'lodash';

export const formattedMonthTitle = (
  locale: string | undefined,
  date: Date | dayjs.Dayjs | string | null,
  year: string = '',
) => {
  const nameMonth = dayjs(date).locale('ru').format(`MMMM ${year}`);
  return capitalize(nameMonth);
};
