import { ACCESS_TOKEN, REFRESH_TOKEN } from '@components/constants/constants';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from '@configs/consts';
import cookieService from '../services/cookie.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/octet-stream, application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': true,

  // 'Access-Control-Allow-Origin': '*',
  // 'Access-Control-Allow-Headers': '*',
  // crossorigin: true,
  // 'Access-Control-Allow-Methods': '*',

  'X-Requested-With': 'XMLHttpRequest',

  'Time-Zone': dayjs.tz.guess(),
};
let requestCount = 2;
let isTokenRefreshing = false;
let delayRequests = [] as any;
const instance = axios.create({
  baseURL: `https://${window.location.host}/api/`,
});

const setTokenRefreshing = (value: boolean) => {
  isTokenRefreshing = value;
};

//Получение токена доступа
// @ts-ignore
const injectToken = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  try {
    const token = await cookieService.get(ACCESS_TOKEN);

    if (!config.headers) {
      throw new Error('method injectToken: config.headers имеет значение undefined');
    }
    if (isTokenRefreshing) {
      return new Promise((resolve) => {
        delayRequests.push((accessToken: any) => {
          // @ts-ignore
          config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(config);
        });
      });
    } else {
      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      }
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

const sendRefreshToken = async (): Promise<any> => {
  try {
    const refresh_token = await cookieService.get(REFRESH_TOKEN);

    if (refresh_token != null) {
      const body = { refresh_token, grant_type: REFRESH_TOKEN };
      const response = await axios.post(`/web/auth/token`, body);

      return response;
    }
  } catch (error: any) {
    return cookieService.remove(ACCESS_TOKEN, { path: '/' });
  }
};

class Http {
  private http: AxiosInstance = axios.create({
    headers,
    withCredentials: true,

    baseURL: BASE_URL,
  });

  constructor() {
    this.initHttp();
    // const http = axios.create({
    //   headers,
    //   withCredentials: true,
    // })
    // this.instance = http;
  }

  // private get http(): AxiosInstance {
  //   return this.instance != null ? this.instance : this.initHttp();
  // }

  initHttp() {
    // const http = axios.create({
    //   headers,
    //   withCredentials: true,
    // });
    // @ts-ignore
    this.http.interceptors.request.use(injectToken, (error) => Promise.reject(error));
    this.http.interceptors.response.use(
      (response) => response,
      (error) => this.handleError(error, this.http),
    );

    // this.instance = http;
    // return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Обработка глобальных ошибок
  private async handleError(error: any, http: AxiosInstance) {
    const { status } = error.response;
    const { config } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Обработка InternalServerError
        break;
      }
      case StatusCode.Unauthorized:
      case StatusCode.Forbidden: {
        if (config && !config._retry && !isTokenRefreshing) {
          setTokenRefreshing(true);
          config._retry = true;
          config.sent = true;

          const result = await sendRefreshToken();

          const accessToken = result?.data?.access_token;
          const refreshToken = result?.data?.refresh_token;

          const options = { path: '/' };
          cookieService.set(ACCESS_TOKEN, accessToken, options);
          cookieService.set(REFRESH_TOKEN, refreshToken, options);

          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
            delayRequests.filter((callback: any) => callback(accessToken));
            setTokenRefreshing(false);
            delayRequests = [];
          }
        }

        if (requestCount === 0) {
          setTimeout(() => (requestCount = 2), 700);
          // cookieService.remove(ACCESS_TOKEN, { path: '/' });
          // cookieService.remove(REFRESH_TOKEN, { path: '/' });
          return Promise.reject(error);
        }
        requestCount--;
        return http(config);
      }
      case StatusCode.TooManyRequests: {
        // Обработка TooManyRequests
        break;
      }
    }

    return Promise.reject(error);
  }
}

export const http = new Http();
