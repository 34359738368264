import React, { FC } from 'react';

import { ContainerTable, LineText } from '@components/commons/commonsStyled';
import { IMapBusinessGoals } from 'interfaces/stats.interfaces';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

interface ITableBusinessGoals {
  data: IMapBusinessGoals[];
}

export const TableBusinessGoals: FC<ITableBusinessGoals> = (props) => {
  return (
    <ContainerTable>
      {props.data.map((item: IMapBusinessGoals) => (
        <LineTable className="justify-between" key={uniqueId(item.title)} border={item.border}>
          <LineText fontSize={'15px'}>
            <FormattedMessage id={item.title} />
          </LineText>
          <LineText fontSize={'15px'} bold={'700'}>
            {item.value}
          </LineText>
        </LineTable>
      ))}
    </ContainerTable>
  );
};

const LineTable = styled.div<{ border?: boolean }>`
  display: flex;
  width: 100%;
  gap: 20px 10px;
  align-items: center;
  padding: 12px;
  border-bottom: ${(props) => (props.border ? '1px solid #E3E9E7' : 'none')};
`;
