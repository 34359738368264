import React, { useState } from 'react';
import cn from 'classnames';

import { ReactComponent as BinIconGreen } from '@assets/icons/bin-green.svg';
import { ReactComponent as ChevronIcon } from '@assets/icons/chevron-down.svg';
import { ReactComponent as CloseGreyIcon } from '@assets/icons/close-grey.svg';
import { IStateAdmin } from 'interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';

interface ITableAdminItem {
  user: IStateAdmin;
  i: number;
  handleDelete: (id: string, data: Record<string, string>) => void;
  handleGetIdPassword: (id: string) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableAdminItem = ({ i, user, handleDelete, setModalOpen, handleGetIdPassword }: ITableAdminItem) => {
  const [isActive, setIsActive] = useState(false);
  const [password, setPassword] = useState('');
  // @ts-ignore

  const handleChange = (id: string) => {
    setModalOpen(true);
    handleGetIdPassword(id);
    // setPassword('')
    // setIsActive(false)
  };

  const getLogin = (user: IStateAdmin) => {
    let data = { id: '', login: '' };
    user.accounts.map((account) => {
      if (account.type === 'LOGIN') {
        data.login = account.login;
        data.id = account.id;
      }
      return account;
    });
    return data;
  };

  const handleDeleteAdmin = (id: string) => {
    let data = getLogin(user);
    handleDelete(id, data);
  };

  return (
    <div className="row" style={{ backgroundColor: i % 2 === 0 ? '#F5F9F8' : '#FFFFFF' }}>
      <div>
        <span>{getLogin(user).login}</span>
      </div>
      <div>
        <span>{`${user?.lastName} ${user?.firstName} ${user?.middleName}`}</span>
      </div>
      <div className="dates">
        <span onClick={() => handleChange(getLogin(user).id)}>
          <FormattedMessage id="resetPassword" />
        </span>
        {/* <div className={cn('tooltip', { 'active': isActive })}>
          <input
            type='password'
            value={password}
            placeholder="Введите пароль"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className='icon-tap'>
            <ChevronIcon onClick={() => handleChange(getLogin(user).id)} />
          </div>
          <div onClick={() => setIsActive(false)} className='icon-tap'> <CloseGreyIcon />
          </div>
        </div> */}
      </div>
      <div onClick={() => handleDeleteAdmin(user.id)} className="admin-delete">
        <BinIconGreen />
      </div>
    </div>
  );
};
