import React from 'react';

import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

interface IButtonShowUsers {
  isActive?: boolean;
  loading?: any;
  title: string;
  padding?: string;
  handleEvents?: () => void;
}

export const ButtonShowUsers = ({ handleEvents, loading, isActive, title, padding }: IButtonShowUsers) => {
  return (
    <Container className="flex" padding={padding}>
      <ButtonGrey className="flex" onClick={handleEvents} disabled={!isActive}>
        {loading ? (
          <div className="flex">
            <ReactLoading type="spin" color={'#274335'} height={20} width={20} />
          </div>
        ) : (
          <FormattedMessage id={title} />
        )}
      </ButtonGrey>
    </Container>
  );
};

const Container = styled.div<{ border?: boolean; padding?: string }>`
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '20px 20px')};
`;
const ButtonGrey = styled.button`
  justify-content: center;
  align-items: center;
  min-height: 36px;
  min-width: 138px;
  padding: 6px 15px;
  border-radius: 4px;
  border: none;
  color: #274335;
  font-size: 14px;
  font-weight: 500;
  background-color: #e3e9e7;
  &:hover {
    background-color: #b3beb8;
  }
  &:active {
    background-color: #5f6e67;
  }
  &:disabled {
    background-color: #e3e9e7;
    color: #b3beb8;
  }
`;
