import { colorsVar } from '@components/constants/colorVariables';

export const titleHome = 'Статистика';

export const activeUsersData = [
  { dataKey: 'active', fill: colorsVar.greenLine },
  { dataKey: 'total', fill: colorsVar.blueLine },
  { dataKey: 'percentage', fill: colorsVar.blueLine },
];

export const listPersonalGoals = [
  'FAMILY_AND_HOME',
  'FINANCE_AND_CAREER',
  'PHYSICAL_CONDITION_AND_HEALTH',
  'DEVELOPMENT_AND_SELF_EDUCATION',
  'SOCIAL_AND_CULTURAL',
  'SPIRITUAL_AND_ETHICAL',
];

export const planedTasksData = [
  { dataKey: 'totalTasks', fill: colorsVar.blueLine },
  { dataKey: 'personalTasks', fill: colorsVar.violetLine },
  { dataKey: 'delegatedTasks', fill: colorsVar.yellowLine },
];

export const linesDynamicsPlaningTasks = [
  { dataKey: 'totalTasks', fill: colorsVar.blueLine },
  { dataKey: 'urgentTasks', fill: colorsVar.violetLine },
  { dataKey: 'importantTasks', fill: colorsVar.greenLine },
  { dataKey: 'contactTasks', fill: colorsVar.orangeLine },
];
export const linesDynamicsCompletionTasks = [
  { dataKey: 'unplannedTasks', fill: colorsVar.orangeLine },
  { dataKey: 'plannedTasks', fill: colorsVar.greenLine },
  { dataKey: 'externalReasonNotCompletedTasks', fill: colorsVar.violetLine },
  { dataKey: 'personalReasonNotCompletedTasks', fill: colorsVar.blueLine },
];

export const completeTasksData = [
  { dataKey: 'unplannedTasks', fill: colorsVar.yellowLine },
  { dataKey: 'plannedTasks', fill: colorsVar.greenLine },
  { dataKey: 'notCompletedTasks', fill: colorsVar.blueLine },
];
export const creationTasksData = [{ dataKey: 'totalCreation', fill: colorsVar.blueLine }];

export const closureTasksData = [{ dataKey: 'totalClosure', fill: colorsVar.violetLine }];

export const businessGoalsData = [{ dataKey: 'totalGoals', fill: colorsVar.blueLine }];

export const personalGoalsData = [
  { dataKey: 'FAMILY_AND_HOME', fill: colorsVar.greenLine },
  { dataKey: 'FINANCE_AND_CAREER', fill: colorsVar.redLine },
  { dataKey: 'SOCIAL_AND_CULTURAL', fill: colorsVar.yellowLine },
  { dataKey: 'SPIRITUAL_AND_ETHICAL', fill: colorsVar.orangeLine },
  { dataKey: 'DEVELOPMENT_AND_SELF_EDUCATION', fill: colorsVar.blueLine },
  { dataKey: 'PHYSICAL_CONDITION_AND_HEALTH', fill: colorsVar.violetLine },
];
