import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface deviceI {
  isRetina: null | boolean;
  width: number | null;
  height: number | null;
  showAllBlur: boolean;
  isLowSpeed: null | boolean;
  blurAllScreen: boolean;
  blurNews: boolean;
  isHiddenNavbar: boolean;
  [propName: string]: any;
}

const initialState: deviceI = {
  isRetina: null,
  width: null,
  height: null,
  isLowSpeed: null,
  showAllBlur: false,
  blurAllScreen: false,
  blurNews: false,
  isHiddenNavbar: false,
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setState: (state: deviceI, action: PayloadAction<Partial<deviceI>>) => {
      try {
        const valueArg = action.payload;
        for (const key in valueArg) {
          if (Object.hasOwnProperty.call(valueArg, key) && Object.hasOwnProperty.call(state, key)) {
            state[key] = valueArg[key];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export const { setState } = deviceSlice.actions;

export const selectDeviceSlice = (state: { device: deviceI }) => state.device;

export default deviceSlice.reducer;
