import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HideIcon } from '@assets/icons/hide_icon.svg';
import { LineText } from '@components/commons/commonsStyled';
import { Container } from './NavBar.Avatar';
import { IconWrap } from './NavBar.Exit';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';

interface INavBarHidden {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  hidden: boolean;
}

const NavBarHidden: FC<INavBarHidden> = (props) => {
  return (
    <ContainerHidden className="flex" onClick={props.onClick}>
      <IconWrap className="flex">
        <HideIcon />
      </IconWrap>
      <LineText fontSize={'16px'}>
        <FormattedMessage id="hide" />
      </LineText>
    </ContainerHidden>
  );
};

export default NavBarHidden;

const ContainerHidden = styled(Container)`
  padding-left: 14px;
  border-top: solid 1px ${colorsVar.grey1};
`;
