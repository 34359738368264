import React, { useState } from 'react';

import { Modal } from '../../../../../ui/modal';
import { useFormik } from 'formik';
import cn from 'classnames';
import { adminPasswordSchema, adminSchema } from '@components/schemas';
import { FormattedMessage, useIntl } from 'react-intl';

import './modalPasswordAdmin.scss';

interface IModalPasswordAdmin {
  handleChangePassword: (params: {}) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalPasswordAdmin = ({ setIsVisible, handleChangePassword }: IModalPasswordAdmin) => {
  const intl = useIntl();

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      // login: '',
      password: '',
      repassword: '',
    },
    validationSchema: adminPasswordSchema,
    onSubmit: (values) => {
      handleChangePassword(values);
    },
  });

  return (
    <Modal setIsVisible={setIsVisible}>
      <form className="modal-admin-passw " onSubmit={handleSubmit} action="">
        <h1>
          <FormattedMessage id="changePassword" />
        </h1>
        <div className="body">
          <div>
            <span>
              <FormattedMessage id="newPassword" />
            </span>
            <input
              id="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              placeholder={intl.formatMessage({ id: 'enterPassword' })}
              className={cn({ 'input-error': errors.password && touched.password })}
            />
            {errors.password && touched.password && (
              <div className="error">
                <span className="line-error">{errors.password}</span>
              </div>
            )}
          </div>
          <div>
            <span>
              <FormattedMessage id="repeatPassword" />
            </span>
            <input
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'enterPassword' })}
              value={values.repassword}
              id="repassword"
              className={cn({ 'input-error': errors.repassword && touched.repassword })}
            />
            {errors.repassword && touched.repassword && (
              <div className="error">
                <span className="line-error">{errors.repassword}</span>
              </div>
            )}
          </div>
        </div>
        <button type="submit">
          <FormattedMessage id="change" />
        </button>
      </form>
    </Modal>
  );
};
