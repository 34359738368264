import React, { useEffect, useState } from 'react';

import { useStatsSelector } from '@store/statistic/selectors';
import { IMapUserInfo } from 'interfaces/stats.interfaces';
import dayjs from 'dayjs';
import { CURRENT_USER_ID } from '@configs/consts';
import { useDispatch } from 'react-redux';
import { getUserInfo, getUserStatistic } from '@store/user/api';
import { getAssists } from '@store/assistants/api';
import { IConnectionAssist } from 'interfaces/user.interfaces';
import cookieService from '@services/cookie.service';
import { SUPERVISOR } from '@components/constants/constants';
import { isEmpty } from 'lodash';
import { useAuthSelector } from '@store/auth/selectors';

const useUserInfoData = () => {
  const [currentUserId, setCurrentUserId] = useState<string>();

  const { userInfo, userStatistic, currentUser } = useStatsSelector((state) => state.user);
  const { supervisor } = useAuthSelector((state) => state.auth);
  const dispatch = useDispatch();

  const {
    lastEntryDate,
    firstTaskDate,
    lastTaskDate,
    todayTasks = 0,
    notDoneBusinessGoals = 0,
    doneBusinessGoals = 0,
    openedDelegatedTasks = 0,
    closedDelegatedTasks = 0,
    pausedDelegatedTasks = 0,
    openedPersonalTasks = 0,
    pausedPersonalTasks = 0,
    closedPersonalTasks = 0,
  } = userStatistic || {};

  const { lastName, firstName, middleName } = userInfo || {};

  useEffect(() => {
    if (currentUser?.id) {
      setCurrentUserId(currentUser?.id);
    } else {
      const id = localStorage.getItem(CURRENT_USER_ID);
      id && setCurrentUserId(id);
    }
  }, [currentUser?.id, userInfo]);

  useEffect(() => {
    if (currentUserId) {
      const newData = { id: currentUserId };
      if (!supervisor) {
        dispatch(getUserInfo(newData));
      }
      dispatch(getUserStatistic(newData));
      if (!cookieService.get(SUPERVISOR)) {
        const paramsString = new URLSearchParams({}).toString();
        dispatch(getAssists(paramsString));
      }
    }
  }, [currentUserId]);

  const formattedDate = (value: string | null) => {
    if (value === null) {
      return '-';
    }
    return dayjs(value).format('DD.MM.YY');
  };

  const userNamesLine: string | null = React.useMemo(() => {
    if (isEmpty(userInfo)) return null;
    return `${userInfo.lastName ?? ''} ${userInfo.firstName ?? ''} ${userInfo.middleName ?? ''}`;
  }, [userInfo]);

  const userShortNamesLine: string = React.useMemo(
    () => lastName && firstName && ` ${lastName} ${firstName.slice(0, 1) + '.'} ${middleName?.slice(0, 1) + '.'}`,
    [middleName, lastName, firstName],
  );

  const mapTasksUserInfo: IMapUserInfo[] = React.useMemo(
    () => [
      {
        title: 'lastActivity',
        value: lastEntryDate ? formattedDate(lastEntryDate) : '-',
      },
      {
        title: 'tasksPlanedToday',
        value: todayTasks,
      },
      {
        title: 'createdFirstTask',
        value: firstTaskDate ? formattedDate(firstTaskDate) : '-',
      },
      {
        title: 'createdLastTask',
        value: lastTaskDate ? formattedDate(lastTaskDate) : '-',
      },
    ],
    [userStatistic, currentUser],
  );

  const mapTasksStatisticUserInfo: IMapUserInfo[][] = React.useMemo(
    () => [
      [
        {
          title: 'tasksOnUser',
          value: openedPersonalTasks + closedPersonalTasks + pausedPersonalTasks,
          boldTitle: '700',
        },
        {
          title: 'open',
          value: openedPersonalTasks,
          leftIndent: '10px',
        },
        {
          title: 'Приостановленныe',
          value: pausedPersonalTasks,
          leftIndent: '10px',
        },
        {
          title: 'closed',
          value: closedPersonalTasks,
          leftIndent: '10px',
        },
      ],
      [
        {
          title: 'tasksDelegatedToOther',
          value: openedDelegatedTasks + closedDelegatedTasks + pausedDelegatedTasks,
          boldTitle: '700',
        },
        {
          title: 'open',
          value: openedDelegatedTasks,
          leftIndent: '10px',
        },
        {
          title: 'Приостановленныe',
          value: pausedDelegatedTasks,
          leftIndent: '10px',
        },
        {
          title: 'closed',
          value: closedDelegatedTasks,
          leftIndent: '10px',
        },
      ],
      [
        {
          title: 'businessGoals',
          value: notDoneBusinessGoals + doneBusinessGoals,
          boldTitle: '700',
        },
        {
          title: 'open',
          value: notDoneBusinessGoals,
          leftIndent: '10px',
        },
        {
          title: 'achieved',
          value: doneBusinessGoals,
          leftIndent: '10px',
        },
      ],
    ],
    [userStatistic, currentUser],
  );

  const connectionAssist: IConnectionAssist | null = React.useMemo(() => {
    if (!userInfo.isAssistant && userInfo.managers) {
      return {
        isAssistant: true,
        managers: [...userInfo?.assistants],
      };
    }
    if (!userInfo.isManager && userInfo.assistants) {
      return {
        isManager: true,
        assistants: [...userInfo?.assistants],
      };
    }
    return null;
  }, [userInfo]);

  return {
    mapTasksUserInfo,
    mapTasksStatisticUserInfo,
    userNamesLine,
    userShortNamesLine,
    connectionAssist,
    urlAvatar: currentUser?.blobUrlAvatar,
  };
};

export default useUserInfoData;
