import { LineText } from '@components/commons/commonsStyled';
import { ILineRectangles } from '@components/screens/month/components/tableDynamics/charts/constants';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as RecIcon } from '@assets/icons/Rectangle 1790.svg';

import styled from 'styled-components';
import { uniqueId } from 'lodash';

interface IDoubleLineGroupRectangles {
  data?: ILineRectangles[][];
  isHidden?: boolean;
}

export const DoubleLineGroupRectangles: FC<IDoubleLineGroupRectangles> = (props) => {
  return (
    <GroupRectangles className="data-wrap planed">
      {props.data &&
        props.data.map((line) => (
          <RowRectangles key={uniqueId('line')} className={`flex ${props.isHidden ? 'justify-end' : null}`}>
            {line.map((item) => (
              <div key={uniqueId(item.name)} className="flex gap-[5px] items-center">
                <RecIcon fill={item.color} />
                <LineText className="w-[160px] leading-[18px]" fontSize={'15px'}>
                  <FormattedMessage id={item.name} />
                </LineText>
              </div>
            ))}
          </RowRectangles>
        ))}
    </GroupRectangles>
  );
};

const GroupRectangles = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: end;
  gap: 10px;
  align-items: center;
  position: relative;
  height: 48px;
  font-size: 15px;
`;
const RowRectangles = styled.div`
  width: 100%;
  gap: 15px;
  align-items: center;
  @media screen and (max-width: 1169px) {
    justify-content: end;
  }
`;
