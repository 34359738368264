import React, { FC } from 'react';

import { ContainerTable, LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface IHeaderBusinessGoals {
  data: string;
}

export const HeaderBusinessGoals: FC<IHeaderBusinessGoals> = (props) => {
  return (
    <TitleBlock className="justify-normal">
      <LineText fontSize={'20px'} bold={'700'}>
        <FormattedMessage id="businessGoals" />
      </LineText>
      <ContainerTable className="h-8 px-2 justify-center  " width="fit-content" borderRadius={'5px'}>
        <LineText fontSize={'16px'} bold={'700'}>
          {props.data}
        </LineText>
      </ContainerTable>
    </TitleBlock>
  );
};

const TitleBlock = styled.div<{ border?: boolean }>`
  display: flex;
  width: 100%;
  gap: 22px;
  align-items: center;
  border-bottom: ${(props) => (props.border ? '1px solid #E3E9E7' : 'none')};
`;
