import { forwardRef, useEffect, ReactNode, useState, useRef, TouchEvent } from 'react';
import { ReactComponent as CloseIcon } from '@assets/icons/close_white.svg';
import { handlerHide, handlerShow } from '@utils/non-scroll-flow';
import { useWidthScreens } from '../../hooks/useWidthScreens';
import { mobile, tablet } from '@components/constants/constants';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';

interface Props {
  closeEvent: (arg: boolean | null) => void;
  children?: ReactNode;
  isShown: boolean | null;
  isClose?: boolean;
  className?: string;
  classNameContent?: string;
  classNameContentMain?: string;
  classNameTitle?: string;
  title?: string;
  needUnmount?: boolean;
  fullHeight?: boolean;
  subTitle?: string;
  bigSubTitle?: string;
}

export const PopupModal = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { isWideScreen } = useWidthScreens();
  const refContent = useRef<HTMLDivElement | null>(null);
  const { closeEvent, children, isShown, isClose } = props;
  const [localIsShown, setLocalIsShown] = useState<boolean | null>(false);

  const [translateY, setTranslateY] = useState('');
  const [transitionTime, setTransitionTime] = useState('0.5');

  useEffect(() => {
    setLocalIsShown(isShown !== null);
    if (isShown === false) {
      setTranslateY('0%');
      setTimeout(() => {
        handleClose();
      }, 200);
    }
    if (isShown) {
      setTimeout(() => {
        handlerShow();
        setTranslateY('0%');
      }, 300);
      return () => {
        handlerHide();
      };
    }
  }, [isShown]);

  useEffect(() => {
    if (isClose) {
      setTimeout(() => {
        handleClose();
      }, 500);
    }
  }, [isClose]);

  useEffect(() => {
    setLocalIsShown(isShown);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isShown && refContent.current && !refContent.current.contains(event.target as Node)) {
        handleClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeEvent]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeEvent]);

  const handleTouch = (event: TouchEvent) => {
    if (!refContent.current) return;
    if (event.touches[0].clientY - refContent.current.offsetTop < 0) return;
    setTranslateY(`${event.touches[0].clientY - refContent.current.offsetTop}px`);
    setTransitionTime('0');
  };

  const handleTouchEnd = (event: TouchEvent) => {
    if (!refContent.current) return;
    if (event.changedTouches[0].clientY - refContent.current.offsetTop < 150) {
      setTranslateY('0%');
      setTransitionTime('0.5');
      return;
    }
    setTranslateY('100%');
    setTransitionTime('0.5');
    setTimeout(() => {
      closeEvent(null);
    }, 500);
  };

  const handleClose = () => {
    setTranslateY('300%');
    setTimeout(() => {
      closeEvent(null);
    }, 500);
  };

  return (
    <BlurContainer
      ref={ref}
      active={localIsShown}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <PopupContent
        ref={refContent}
        active={localIsShown}
        style={
          isWideScreen
            ? {
                transform: `translateX(${translateY})`,
                transitionDuration: `${transitionTime}s`,
              }
            : {
                transform: `translateY(${translateY})`,
                transitionDuration: `${transitionTime}s`,
              }
        }
      >
        <CloseWrap onClick={handleClose}>
          <CloseIcon fill={isWideScreen ? colorsVar.white : colorsVar.greenClose} />
        </CloseWrap>
        <DragWrap onTouchMove={handleTouch} onTouchEnd={handleTouchEnd}>
          <DragLine className="flex" />
        </DragWrap>
        {children}
      </PopupContent>
    </BlurContainer>
  );
});

PopupModal.displayName = 'Popup';

export default PopupModal;

const BlurContainer = styled.div<{ active: boolean | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 0;
  transition: background-color 0.5s;
  z-index: 10;
  ${({ active }) =>
    active &&
    `
    background: rgba(29, 46, 38, 0.78);
	  backdrop-filter: blur(1.5px);
	  top: 0;
	  height: 100vh;
  `}

  @media screen and (max-width: ${tablet}px) {
    justify-content: stretch;
  }
`;
const PopupContent = styled.div<{ active: boolean | null }>`
  position: relative;
  align-content: start;
  gap: 50px;
  padding: 32px;
  border-radius: 20px;
  background-color: ${colorsVar.white};
  left: 0;
  transition: all 0.5s;
  ${({ active }) =>
    active &&
    `
    opacity: 1;
    transition: all 0.5s;
  `}

  @media screen and (max-width: ${tablet}px) {
    align-self: end;
    grid-template-rows: auto 1fr;
    gap: 10px;
    padding: 30px 2.5% 30px;
    height: auto;
    max-height: 90vh;
    width: 100%;
    border-radius: 12px 12px 0 0;
    transform: translateX(0%) translateY(100%);
  }

  @media screen and (max-width: ${mobile}px) {
    padding: 30px 24px;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
  }
`;
const CloseWrap = styled.div`
  display: flex;
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
  z-index: 11;
  @media screen and (max-width: ${tablet}px) {
    top: 20px;
    right: 20px;
  }
  @media screen and (max-width: ${mobile}px) {
    width: 16px;
    height: 16px;
  }
`;
const DragWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  align-content: center;
  justify-items: center;
  height: 35px;
  width: 100%;

  @media screen and (max-width: ${tablet}px) {
    display: grid;
  }
`;
const DragLine = styled.div`
  height: 3px;
  width: 100px;
  background-color: ${colorsVar.grey2};
  border-radius: 5px;
`;
