import React, { FC } from 'react';

import { LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ItemTableLine } from './ItemTableLine';
import { useWidthScreens } from '../../../../../hooks/useWidthScreens';
import { ITotalPlaningBusinessTasks } from 'interfaces/stats.interfaces';
import styled from 'styled-components';

interface IPlaningBusinessGoals {
  data: ITotalPlaningBusinessTasks[];
  userName: string;
}

export const PlaningMeetings: FC<IPlaningBusinessGoals> = (props) => {
  const { width } = useWidthScreens();

  return (
    <div className="flex  flex-col gap-[60px]">
      <div className="flex  flex-col gap-[20px]">
        <LineText fontSize={width !== null && width > 1200 ? '32px' : '28px'}>
          <FormattedMessage id="Планирование встреч" />
        </LineText>
        <ItemTableLine data={props.data} />
      </div>
    </div>
  );
};
