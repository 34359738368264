import { LineText } from '@components/commons/commonsStyled';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

interface IHeaderCharts {
  textTitle?: string;
}

export const HeaderCharts: FC<IHeaderCharts> = (props) => {
  return (
    <Container className="flex">
      <LineText fontSize={'20px'} bold={'700'}>
        <FormattedMessage id={props.textTitle} />
      </LineText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  padding: 30px 0 5px 0;
`;
