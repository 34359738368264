import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import './checkInput.scss';

interface CheckInputProps {
  lable?: string;
  name: string;
  defaultChecked?: boolean;
  handleSelected?: (value: boolean, definition: string) => void;
}

export const CheckInput = ({ name, defaultChecked, handleSelected, lable }: CheckInputProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="check-wrapper">
      <input
        type="checkbox"
        className="check-input"
        name={name}
        defaultChecked={defaultChecked}
        onChange={(e) => handleSelected && handleSelected(e.target.checked, name)}
      />
      <label className="check">{lable && <FormattedMessage id={lable} />}</label>
    </div>
  );
};
