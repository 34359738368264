import React, { useEffect, useRef, useState } from 'react';
import { SelectDate } from '@components/shared/selectDate';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { RadioButtons } from '../../../../../ui/radioButtons';
import { activeUsersData } from '../../constants';
import { ReactComponent as RecIcon } from '@assets/icons/Rectangle 1790.svg';
import { colorsVar } from '@components/constants/colorVariables';
import { FormattedMessage } from 'react-intl';
import { LineStatChart } from '@components/shared/lineChart/LineStatChart';
import { formattedDateNotTotal } from '../../statsUtils';
import { useStatsSelector } from '@store/statistic/selectors';
import { getActiveUsers } from '@store/statistic/api';
import { addEndDate, addStartDate, setUsersPeriod } from '@store/statistic/slice';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';

interface LineChartUsersProps {
  tooltip: ({ active, payload, label }: any) => JSX.Element | null;
  position: CalendarPosition;
  isLoadingPage: boolean;
}

export const LineChartUsers = ({ tooltip, position, isLoadingPage }: LineChartUsersProps) => {
  const { activeUsers, activeUsersPeriod, isLoadingActiveUsers } = useStatsSelector((state) => state.stats);

  const [isPercentage, setPercentage] = useState(false);
  const dispatch = useDispatch();

  const prevActiveUsersPeriod = useRef(activeUsersPeriod);

  const handleStartData = (event: any) => {
    dispatch(addStartDate(event));
  };
  const handleEndData = (event: any) => {
    dispatch(addEndDate(event));
  };

  const renderLineActiveUsers = isPercentage ? activeUsersData.slice(-1) : activeUsersData.slice(0, 2);

  useEffect(() => {
    if (isEmpty(activeUsers) && isEmpty(activeUsersPeriod)) {
      dispatch(setUsersPeriod({ startDay: dayjs().add(-10, 'day'), endDay: dayjs() }));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(activeUsersPeriod)) {
      const paramsString = new URLSearchParams({
        startDate: dayjs(activeUsersPeriod.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(activeUsersPeriod.endDate).format('YYYY-MM-DD'),
      }).toString();
      if (!isEqual(prevActiveUsersPeriod.current, activeUsersPeriod)) {
        dispatch(getActiveUsers(paramsString));
      }
    }
    prevActiveUsersPeriod.current = activeUsersPeriod;
  }, [activeUsersPeriod]);

  return (
    <>
      <div className="graf-task">
        <div className="users-header">
          <div>
            <div className="check-wrap">
              <RadioButtons onChange={setPercentage} />
            </div>
            <div className="data-wrap">
              <SelectDate
                type="input"
                onChange={handleStartData}
                id="firstEntryDateStartRange"
                value={dayjs(activeUsersPeriod.startDate).format('DD.MM.YY')}
                position={position}
              />
              <span>-</span>
              <SelectDate
                type="input"
                onChange={handleEndData}
                id="firstEntryDateStartRange"
                value={dayjs(activeUsersPeriod.endDate).format('DD.MM.YY')}
                position={position}
              />
            </div>
          </div>
          <div className="data-wrap">
            {/* TODO: =========== Temporary Hidden ================== */}
            {/* <div className="task-wrap">
              <RecIcon fill={colorsVar.blueLine} />
              <span>
                <FormattedMessage id="total" />
              </span>
            </div> */}
            <div className="task-wrap">
              <RecIcon fill={colorsVar.greenLine} />
              <span>
                <FormattedMessage id="active" />
              </span>
            </div>
          </div>
        </div>
        <div className="graf-item">
          <span style={{ paddingLeft: 60, height: 10 }}></span>
          <RenderWithCondition condition={!isLoadingPage}>
            <LineStatChart
              dot={{ r: 3 }}
              fill={colorsVar.textColor}
              pointXaxis="date"
              data={formattedDateNotTotal(activeUsers)}
              renderLine={renderLineActiveUsers}
              externalTooltip={tooltip}
            />
          </RenderWithCondition>
          <LocalLoader condition={isLoadingActiveUsers || isLoadingPage} size={25} />
        </div>
      </div>
    </>
  );
};
