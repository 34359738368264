import { LineText } from '@components/commons/commonsStyled';
import { ILineRectangles } from '@components/screens/month/components/tableDynamics/charts/constants';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as RecIcon } from '@assets/icons/Rectangle 1790.svg';

import styled from 'styled-components';
import { uniqueId } from 'lodash';

interface ILineGroupRectangles {
  data?: ILineRectangles[];
}

export const LineGroupRectangles: FC<ILineGroupRectangles> = (props) => {
  return (
    <Container>
      {props.data &&
        props.data.map((line) => (
          <div key={uniqueId(line.name)} className="flex items-center gap-2 justify-end">
            <RecIcon fill={line.color} />
            <LineText fontSize={'15px'}>
              <FormattedMessage id={line.name} />
            </LineText>
          </div>
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 36px;
  @media screen and (max-width: 900px) {
    gap: 16px;
  }
`;
