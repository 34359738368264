import React, { useState, useEffect, ChangeEvent, useRef } from 'react';

import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface ITabsBarAssist {
  setTabActive: () => void;
  tabActive: boolean;
}

export const TabsBarAssist = ({ setTabActive, tabActive }: ITabsBarAssist) => {
  return (
    <TabsWrap className="flex">
      <TabItem className="flex" onClick={setTabActive} active={!tabActive}>
        <LineText fontSize={'16px'} color={!tabActive ? colorsVar.greenBase : ''}>
          <FormattedMessage id="assistants" />
        </LineText>
      </TabItem>
      <TabItem className="flex" onClick={setTabActive} active={tabActive}>
        <LineText fontSize={'16px'} color={tabActive ? colorsVar.greenBase : ''}>
          <FormattedMessage id="managers" />
        </LineText>
      </TabItem>
    </TabsWrap>
  );
};

export default TabsBarAssist;

const TabsWrap = styled.div`
  padding-left: 30px;
  gap: 24px;
  border-bottom: 1px solid ${colorsVar.grey1};
`;
const TabItem = styled.div<{ active?: boolean }>`
  height: 48px;
  padding: 12px;
  border-bottom: 3px solid ${colorsVar.grey1};
  border-radius: 5px 5px 0px 0px;
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid ${colorsVar.greenBase};
    transition: border-bottom 0.7s linear;
  `}

  cursor: pointer;
  &:hover {
    background-color: ${colorsVar.grey1};
  }
`;
