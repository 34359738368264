import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataCreateUser, userService } from '@services/user.service';

interface IData {
  id: string;
  params: string;
}

const getError = (error: any, thunkAPI: any) => {
  const message =
    (error.response && error.response.data.message) ||
    (error.data && error.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(message);
};

export const getUsers: any = createAsyncThunk('user/getUsers', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await userService.getUsers(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getMoreUsers: any = createAsyncThunk('user/getMoreUsers', async (params, thunkAPI) => {
  try {
    // @ts-ignore
    const response = await userService.getUsers(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getDepartments: any = createAsyncThunk('user/getDepartments', async (thunkAPI) => {
  try {
    const response = await userService.getDepartments();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const createUser = createAsyncThunk('user/createUser', async (data: DataCreateUser, thunkAPI) => {
  try {
    const response = await userService.create(data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteUser = createAsyncThunk('user/deleteUser', async ({ id, spaceId }: any, thunkAPI) => {
  try {
    await userService.deleteSpace(id, spaceId);
    return { id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUserInfo: any = createAsyncThunk('user/getUserInfo', async ({ id }: any, thunkAPI) => {
  try {
    const response = await userService.getUserInfo(id);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUserStatistic: any = createAsyncThunk('user/getUserStatistic', async ({ id }: any, thunkAPI) => {
  try {
    const response = await userService.getUserStatistic(id);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUserSessions: any = createAsyncThunk('user/getUserSessions', async (params: any, thunkAPI) => {
  try {
    const response = await userService.getUserSessions(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getMainUserSessions: any = createAsyncThunk('user/getMainUserSessions', async (thunkAPI) => {
  try {
    const response = await userService.getMainUserSessions();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const removeOwnSessions: any = createAsyncThunk('session/removeSessions', async (ids: string, thunkAPI) => {
  try {
    await userService.removeOwnSessions(ids);
    return { ids };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const removeSessions: any = createAsyncThunk('session/removeSessions', async (ids: string, thunkAPI) => {
  try {
    await userService.removeSessions(ids);
    return { ids };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUserMonthGoal: any = createAsyncThunk(
  'user/getUserMonthGoal',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await userService.getUserMonthGoal(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getUserPersonalGoals: any = createAsyncThunk(
  'user/getUserPersonalGoals',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await userService.getUserPersonalGoals(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getUserBusinessGoals: any = createAsyncThunk(
  'user/getUserBusinessGoals',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await userService.getUserBusinessGoals(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getUserBusinessTasks: any = createAsyncThunk(
  'user/getUserBusinessTasks',
  async ({ id, params }: IData, thunkAPI) => {
    try {
      const response = await userService.getUserBusinessTasks(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getUserDetailedBusinessGoals: any = createAsyncThunk(
  'user/getUserDetailedBusinessGoals',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await userService.getUserDetailedBusinessGoals(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getUserTasksOfDay: any = createAsyncThunk(
  'user/getUserTasksOfDay',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await userService.getUserTasksOfDay(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getUserTasksOfMonth: any = createAsyncThunk(
  'user/getUserTasksOfMonth',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await userService.getUserTasksOfMonth(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const updateUser = createAsyncThunk('user/updateUser', async ({ id, data }: any, thunkAPI) => {
  try {
    const response = await userService.update(id, data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getAvatarFiles: any = createAsyncThunk('user/getAvatarFiles', async ({ id, params }: any, thunkAPI) => {
  try {
    const response = await userService.getAvatarFiles(id, params);

    return response;
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
