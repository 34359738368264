import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import cn from 'classnames';

import { Modal } from '../../../ui/modal';
import { Select } from '../../../ui/select';
import { Checkbox } from '../../../ui/checkbox';
import photo from '@assets/icons/default_photo.png';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useFormik } from 'formik';
import { userSchema } from '@components/schemas';
import { FormattedMessage, useIntl } from 'react-intl';

import './modalUser.scss';

interface ModalUserProps {
  options?: string[];
  handleCreate: (data: any) => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isEmployee: boolean;
}

export const ModalUser = ({ setIsVisible, handleCreate, options, isEmployee }: ModalUserProps) => {
  const [isVisibleForm, setIsVisibleForm] = useState(false);
  const [isVisible, setisVisible] = useState(false);

  const intl = useIntl();

  const handleToogle = () => {
    setisVisible(!isVisible);
  };

  const handleCloseAllPops = () => {
    setisVisible(false);
  };

  const { handleSubmit, handleChange, values, setFieldValue, errors, touched, handleBlur } = useFormik({
    initialValues: {
      login: '',
      lastName: '',
      firstName: '',
      middleName: '',
      departmentId: null,
      // spaceLastName: '',
      // spaceFirstName: '',
      // spaceSecondName: '',
      isDogmaEmployee: false,
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      handleCreate(values);
    },
  });

  const handleSelectDepartament = (data: any) => {
    setFieldValue('departmentId', data);
  };

  return (
    <Modal setIsVisible={setIsVisible}>
      <div onClick={() => handleCloseAllPops()}>
        <form className="modal-user" onSubmit={handleSubmit} action="">
          <h1>
            <FormattedMessage id="addUser" />
          </h1>
          <div className="body">
            <div>
              <span>
                <FormattedMessage id="phoneNumber" />
              </span>
              <InputMask
                id="login"
                maskChar=" "
                onBlur={handleBlur}
                mask="+7 999 999 99 99"
                value={values.login}
                onChange={handleChange}
                placeholder="+7 (___) ___-__-__"
                className={cn({ 'input-error': errors.login && touched.login })}
              />
              {errors.login && touched.login && (
                <div className="error">
                  <span className="line-error">{errors.login}</span>
                </div>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="lastName" />
              </span>
              <input
                id="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                placeholder={intl.formatMessage({ id: 'enterLastName' })}
                className={cn({ 'input-error': errors.lastName && touched.lastName })}
              />
              {errors.lastName && touched.lastName && (
                <div className="error">
                  <span className="line-error">{errors.lastName}</span>
                </div>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="firstName" />
              </span>
              <input
                id="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                placeholder={intl.formatMessage({ id: 'enterFirstName' })}
                className={cn({ 'input-error': errors.firstName && touched.firstName })}
              />
              {errors.firstName && touched.firstName && (
                <div className="error">
                  <span className="line-error">{errors.firstName}</span>
                </div>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="middleName" />
              </span>
              <input
                id="middleName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.middleName}
                placeholder={intl.formatMessage({ id: 'enterMiddleName' })}
                className={cn({ 'input-error': errors.middleName && touched.middleName })}
              />
              {errors.middleName && touched.middleName && (
                <div className="error">
                  <span className="line-error">{errors.middleName}</span>
                </div>
              )}
            </div>
            <RenderWithCondition condition={isEmployee}>
              <div>
                <span>
                  <FormattedMessage id="worksInDogma" />
                </span>
                <Checkbox
                  name="isDogmaEmployee"
                  // @ts-ignore
                  type="checkbox"
                  value="DOGMA_USER"
                  onChange={handleChange}
                  setIsVisibleForm={setIsVisibleForm}
                />
              </div>
            </RenderWithCondition>
          </div>
          <RenderWithCondition condition={isVisibleForm}>
            <div className={cn('body', 'hidden', { active: isVisibleForm })}>
              <div className="photo">
                <span>
                  <FormattedMessage id="addPhoto" />
                </span>
                <img src={photo} alt="" />
              </div>
              <div className="modal-user-select departaments">
                <span>
                  <FormattedMessage id="subdivision" />
                </span>
                <Select type="select" id="departmentId" options={options} onChange={handleSelectDepartament} />
              </div>
            </div>
          </RenderWithCondition>
          <button type="submit">
            <FormattedMessage id="save" />
          </button>
        </form>
      </div>
    </Modal>
  );
};
