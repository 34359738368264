import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import search from '@assets/icons/search.png';
import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg';
import { FormattedMessage } from 'react-intl';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';

import './controlBlock.scss';
import { useLocation } from 'react-router-dom';

interface IControlBlock {
  openForm?: (arg: boolean) => void;
  openFilter?: (arg: boolean) => void;
  handleSearch: (arg: ChangeEvent<HTMLInputElement>) => void;
  title: string;
  isAdmin?: boolean;
  supervisor?: boolean;
  openTab?: boolean;
  textButton?: string;
}

export const ControlBlock: FC<IControlBlock> = ({
  openForm,
  openFilter,
  title,
  handleSearch,
  supervisor,
  isAdmin,
  openTab,
  textButton = 'addUser',
}) => {
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [value, setValue] = useState('');
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 760px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (!ref.current) return;
    // @ts-ignore
    ref.current.value = '';
  }, [openTab]);

  return (
    <>
      <div className="control-wrap">
        <div className="group-btn">
          <RenderWithCondition condition={!supervisor}>
            <h1>
              <span>
                <FormattedMessage id={title} />
              </span>
            </h1>
          </RenderWithCondition>
          <RenderWithCondition condition={!isWideScreen && !isAdmin}>
            <div className="filter-input">
              <input onChange={(search) => handleSearch(search)} />
              <img src={search} alt="search" />
            </div>
          </RenderWithCondition>
        </div>
        <div className="group-btn">
          <div className="btn-btn">
            <RenderWithCondition condition={!supervisor}>
              <button onClick={() => openForm && openForm(true)}>
                <FormattedMessage id={textButton} />
              </button>
            </RenderWithCondition>

            <RenderWithCondition condition={supervisor}>
              <h1>
                <span>
                  <FormattedMessage id={title} />
                </span>
              </h1>
            </RenderWithCondition>
          </div>

          <div className="group">
            <RenderWithCondition condition={isWideScreen || isAdmin}>
              <div className="filter-input">
                <input onChange={(search) => handleSearch(search)} ref={ref} />
                <img src={search} alt="search" />
              </div>
            </RenderWithCondition>
            <RenderWithCondition condition={!isAdmin}>
              <div className="filter-btn">
                <button className="filter-button" onClick={() => openFilter && openFilter(true)}>
                  <FilterIcon />
                  <span>
                    <FormattedMessage id="filter" />
                  </span>
                </button>
              </div>
            </RenderWithCondition>
          </div>
        </div>
      </div>
    </>
  );
};
