import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../src/assets/fonts/roboto/Roboto-Regular.ttf';
import '../src/assets/fonts/roboto/Roboto-Bold.ttf';

import './index.scss';
// require('dotenv').config();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
