import { IRenderLine } from 'interfaces/stats.interfaces';
import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import './lineStatChart.scss';

interface LineChartProps {
  data: any;
  externalTooltip: any;
  renderLine?: IRenderLine[];
  fill?: string;
  pointXaxis?: string;
  dot?: any;
}

export const LineStatChart = ({ data, externalTooltip, renderLine, fill, dot, pointXaxis }: LineChartProps) => {
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ right: 37, left: 0, bottom: 10, top: 7 }} width={1000} height={350}>
          <CartesianGrid vertical={false} height={350} />
          <XAxis
            angle={-25}
            tick={fill ? { fill: `${fill}` } : false}
            tickMargin={0}
            textAnchor="end"
            // textLength={65}
            // minTickGap={47}
            fontSize={11.5}
            fontFamily={'Roboto'}
            dataKey={pointXaxis}
            tickLine={false}
            axisLine={false}
            // interval={50}
            padding={{ left: 3 }}
          />
          <YAxis
            tick={{ fill: '#274335' }}
            tickMargin={10}
            fontFamily={'Roboto'}
            fontSize={14}
            fontWeight={400}
            // tickCount={7}
            tickFormatter={(value) => (Number.isInteger(value) ? value : '')}
            tickLine={false}
            orientation="left"
            axisLine={false}
            padding={{ top: 0, bottom: 0 }}
          ></YAxis>
          <Tooltip content={externalTooltip} />
          {renderLine &&
            renderLine.map((line) => (
              <Line
                key={line.dataKey}
                dataKey={line.dataKey}
                strokeWidth={2}
                stroke={line.fill}
                fill={line.fill}
                dot={dot}
                activeDot={{ r: 9 }}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
