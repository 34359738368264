import React, { ReactNode, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';

import styled from 'styled-components';
import { mobile, tablet } from '@components/constants/constants';

interface IButtonMain {
  buttonText: string;
  colorType?: 'primary_black' | 'secondary_white' | 'primary_white' | 'secondary_black';
  buttonSize?: 'large' | 'normal' | 'medium' | 'small';
  className?: string;
  clickEvent?: () => void;
  type?: 'submit' | 'reset' | 'button';
  svgIconLeft?: ReactNode;
  svgIconRight?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSquare?: boolean;
}

export default function ButtonMain({ buttonText, clickEvent, isDisabled }: IButtonMain) {
  return (
    <ButtonWrap onClick={!isDisabled ? clickEvent : undefined} isDisabled={isDisabled}>
      <LineText className="justify-center" fontSize="18px" color={colorsVar.white} bold="700" lineHeight="24px">
        <FormattedMessage id={buttonText} />
      </LineText>
    </ButtonWrap>
  );
}

const ButtonWrap = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  width: 100%;
  height: 56px;
  padding: 15px 12px 15px 12px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${colorsVar.greenBase};
  color: ${colorsVar.white};
  cursor: pointer;
  &:hover {
    background-color: ${colorsVar.greenHover};
  }
  &:active {
    background-color: ${colorsVar.greenActive};
  }
  ${({ isDisabled }) =>
    isDisabled &&
    `
    background-color: ${colorsVar.grey4};
    cursor: not-allowed;
    transition: all 0.5s linear;
    &:active {
    background-color: ${colorsVar.grey4};
    }
    &:hover {
    background-color: ${colorsVar.grey4};
   }
  `}

  @media screen and (max-width: ${tablet}px) {
    height: 48px;
  }
  @media screen and (max-width: ${mobile}px) {
    height: 46px;
  }
`;
