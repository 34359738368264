import { http } from '../utils/http';

export interface DataCreateUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  type: string | null;
  login: string | null;
}

class UserService {
  constructor() {}

  getUsers = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/users/page?${params}`);
  };

  getDepartments = async () => {
    return await http.get(`/api/admin-service/v1/departments`);
  };

  create = async (data: any): Promise<any> => {
    return await http.post(`/api/admin-service/v1/accounts`, data);
  };

  deleteSpace = async (id: string, spaceId: string): Promise<any> => {
    return await http.post(`/api/admin-service/v1/spaces/${spaceId}/users/${id}`);
  };

  update = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.patch(`/api/admin-service/v1/users/${id}`, data);
  };

  getUserInfo = async (id: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/users/${id}`);
  };

  getUserStatistic = async (id: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}`);
  };

  getUserSessions = async (params: string): Promise<any> => {
    return await http.get(`/api/uaa/v1/sessions/active?${params}`);
  };

  getMainUserSessions = async (): Promise<any> => {
    return await http.get(`/api/uaa/v1/sessions/active`);
  };

  removeOwnSessions = async (params: string): Promise<any> => {
    return await http.delete(`/api/uaa/v1/sessions/logout?ids=${params}`);
  };

  removeSessions = async (params: string): Promise<any> => {
    return await http.delete(`/api/uaa/v1/admin/sessions/logout?ids=${params}`);
  };

  getUserMonthGoal = async (id: string, params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/month-goals/user/${id}?${params}`);
  };
  getUserPersonalGoals = async (id: string, params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/personal-goals?${params}`);
  };

  getUserBusinessGoals = async (id: string, params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/business-goals?${params}`);
  };

  getUserBusinessTasks = async (id: string, params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/business-tasks?${params}`);
  };

  getUserDetailedBusinessGoals = async (id: string, params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/business-goals/user/${id}?${params}`);
  };

  getUserTasksOfDay = async (id: string, params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/tasks-count?${params}`);
  };

  getUserTasksOfMonth = async (id: string, params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/month-tasks-count?${params}`);
  };

  getAvatarFiles = async (id: string, params: string): Promise<any> => {
    const response = await http.get(`api/s3-storage/v1/file/download/${id}?${params}`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    return URL.createObjectURL(blob);
  };
}

export const userService = new UserService();
