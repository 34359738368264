import React, { useState, useEffect, useRef, useMemo, ChangeEvent } from 'react';

import { ControlBlock } from '@components/shared/controlBlock';
import { useDispatch } from 'react-redux';
import { RenderTableWithCondition } from '@components/hoc/RenderTableWithCondition';
import { LIMIT_PAGE } from '@components/constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { selectAdminSlice } from '@store/admin/slice';
import { debounce } from 'lodash';
import { textDeleteNotificationAssist, textDeleteNotificationManager, titleAssistants } from './constants';
import TableAssist from './components/TableAssist';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from '@store/hooks';
import TabsBarAssist from './components/TabsBarAssist';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { selectAssistSlice, setState } from '@store/assistants/assistants.slice';
import ModalAssist from '../month/components/modalAssist/ModalAssist';
import { addAssists, deleteAssists, getAssists, getManagers, updateAssist, updateManager } from '@store/assistants/api';
import { getUsers } from '@store/user/api';
import EmptyPage from './components/EmptyPage';
import PopupModal from '@ui/modal/PopupModal';
import Notification from '@ui/notification/Notification';
import { truncateName } from '@utils/formattedText';

export const Assistants = () => {
  const [tabActive, setTabActive] = useState(false);
  const [openCreateAssist, setCreateAssist] = useState<boolean | null>(false);
  const [openUpdateAssist, setUpdateAssist] = useState<boolean | null>(false);
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);

  const {
    fullManagersList,
    fullAssistsList,
    currentDataForUpdate,
    currentDataForUpdateManager,
    isLoadingAssist,
    checkedDataForUpdate,
  } = useAppSelector(selectAssistSlice);

  const [search, setSearch] = useState('');
  const [searchAssist, setSearchAssist] = useState('');
  const [searchManager, setSearchManager] = useState('');
  const [valueDebonced] = useDebounce(search, 2500);

  const touchedSearchRef = useRef(false);
  if (search) touchedSearchRef.current = true;

  const touchedSearchRefAssist = useRef(false);
  if (searchAssist || searchManager) touchedSearchRefAssist.current = true;

  const touchedSearchRefManager = useRef(false);
  if (searchManager) touchedSearchRefManager.current = true;

  const { admins, lengthList, filter } = useAppSelector(selectAdminSlice);

  const { nextLoad, start } = usePagination(lengthList, LIMIT_PAGE);
  const dispatch = useDispatch();

  const debounceValue = debounce((value) => {
    setSearch(value);
  }, 2000);

  const debounceValueAssist = debounce((value) => {
    setSearchAssist(value);
  }, 2000);

  const debounceValueManager = debounce((value) => {
    setSearchManager(value);
  }, 2000);

  useEffect(() => {
    const paramsString = new URLSearchParams({
      thirdPartyUsersOnly: 'false',
    }).toString();
    dispatch(getUsers(paramsString));
  }, []);

  useEffect(() => {
    const paramsString = new URLSearchParams({}).toString();
    dispatch(getAssists(paramsString));
    dispatch(getManagers(paramsString));
  }, []);

  useEffect(() => {
    if (search || touchedSearchRef.current) {
      const paramsString = new URLSearchParams({
        query: search,
        thirdPartyUsersOnly: 'false',
      }).toString();
      dispatch(getUsers(paramsString));
    }
  }, [search]);

  useEffect(() => {
    if (searchAssist || touchedSearchRefAssist.current) {
      const paramsString = new URLSearchParams({
        query: searchAssist,
      }).toString();
      dispatch(getAssists(paramsString));
    }
  }, [searchAssist]);

  useEffect(() => {
    if (searchManager || touchedSearchRefManager.current) {
      const paramsString = new URLSearchParams({
        query: searchManager,
      }).toString();
      dispatch(getManagers(paramsString));
    }
  }, [searchManager]);

  const handleAddAssist = async (managerIds: string[], assistIds: string[]) => {
    const newData = {
      data: [
        {
          assistantId: assistIds[0],
          managerIds: managerIds,
        },
      ],
    };
    dispatch(addAssists(newData));
    setCreateAssist(false);
    setTimeout(() => {
      const paramsString = new URLSearchParams({}).toString();
      dispatch(getAssists(paramsString));
      dispatch(getManagers(paramsString));

      const params = new URLSearchParams({
        thirdPartyUsersOnly: 'false',
      }).toString();
      dispatch(getUsers(params));
    }, 1200);
  };

  const handleSetTabActive = () => {
    setTabActive(!tabActive);
    setSearch('');
    setSearchAssist('');
    setSearchManager('');
  };
  const handleSearchUsers = (value: string) => {
    debounceValue(value);
  };
  const handleSearchAssist = (event: ChangeEvent<HTMLInputElement>) => {
    debounceValueAssist(event.target.value);
  };
  const handleSearchManager = (event: ChangeEvent<HTMLInputElement>) => {
    debounceValueManager(event.target.value);
  };

  const handleDeleteAssist = () => {
    const assistantId = currentDataForUpdate?.assistant.id;
    const managerId = currentDataForUpdateManager?.manager.id;
    const managerIds = currentDataForUpdate?.managers.map((manager) => manager.id);
    const assistantIds = currentDataForUpdateManager?.assistants.map((assist) => assist.id);
    const newData = currentDataForUpdate
      ? {
          data: [
            {
              assistantId,
              managerIds,
            },
          ],
        }
      : {
          data: assistantIds?.map((assistantId) => ({ assistantId: assistantId, managerIds: [managerId] })),
        };
    dispatch(deleteAssists(newData));
    dispatch(
      setState({
        currentDataForUpdate: null,
      }),
    );
    setOpenNotification(false);
    setTimeout(() => {
      const paramsString = new URLSearchParams({}).toString();

      dispatch(getAssists(paramsString));
      dispatch(getManagers(paramsString));
      const params = new URLSearchParams({
        thirdPartyUsersOnly: 'false',
      }).toString();
      dispatch(getUsers(params));
    }, 1200);
  };

  const handleUpdateAssist = () => {
    const assistantId = currentDataForUpdate?.assistant.id;
    const managerId = currentDataForUpdateManager?.manager.id;
    const checkedIds = checkedDataForUpdate.map((user) => user.id);
    const newData = {
      data: [
        {
          assistantId,
          managerIds: checkedIds,
        },
      ],
    };
    const newDataManager = {
      data: [
        {
          managerId,
          assistantIds: checkedIds,
        },
      ],
    };
    if (!tabActive) {
      dispatch(updateAssist(newData));
    }
    if (tabActive) {
      dispatch(updateManager(newDataManager));
    }
    dispatch(
      setState({
        currentDataForUpdate: null,
        currentDataForUpdateManager: null,
      }),
    );
    setTimeout(() => {
      const paramsString = new URLSearchParams({}).toString();

      dispatch(getAssists(paramsString));
      dispatch(getManagers(paramsString));
      const params = new URLSearchParams({
        thirdPartyUsersOnly: 'false',
      }).toString();
      dispatch(getUsers(params));
    }, 1200);
    setUpdateAssist(false);
  };

  const currentName = useMemo(() => {
    const nameAssist = `${currentDataForUpdate?.assistant?.lastName} ${currentDataForUpdate?.assistant?.firstName} ${currentDataForUpdate?.assistant?.middleName}`;
    const nameManager = `${currentDataForUpdateManager?.manager?.lastName} ${currentDataForUpdateManager?.manager?.firstName} ${currentDataForUpdateManager?.manager?.middleName}`;
    return currentDataForUpdate ? nameAssist : nameManager;
  }, [currentDataForUpdate, currentDataForUpdateManager]);

  const currentNameShort = useMemo(() => {
    const nameAssist = `${currentDataForUpdate?.assistant?.lastName} ${truncateName(
      currentDataForUpdate?.assistant.firstName,
    )} ${truncateName(currentDataForUpdate?.assistant.middleName)}`;

    const nameManager = `${currentDataForUpdateManager?.manager?.lastName} ${truncateName(
      currentDataForUpdateManager?.manager?.firstName,
    )} ${truncateName(currentDataForUpdateManager?.manager?.middleName)}`;
    return currentDataForUpdate ? nameAssist : nameManager;
  }, [currentDataForUpdate, currentDataForUpdateManager]);

  const currentCheckedData = useMemo(() => {
    return currentDataForUpdate ? currentDataForUpdate?.managers : currentDataForUpdateManager?.assistants;
  }, [currentDataForUpdate, currentDataForUpdateManager]);

  return (
    <>
      <ControlBlock
        title={titleAssistants}
        isAdmin={true}
        openForm={setCreateAssist}
        handleSearch={tabActive ? handleSearchManager : handleSearchAssist}
        textButton="addAssist"
        openTab={tabActive}
      />
      <TabsBarAssist setTabActive={handleSetTabActive} tabActive={tabActive} />
      <RenderTableWithCondition condition={isLoadingAssist && !search}>
        <TableAssist
          assistList={tabActive ? null : fullAssistsList}
          managersList={tabActive ? fullManagersList : null}
          isLoading={isLoadingAssist}
          handleGetAdmins={() => undefined}
          handleDeleteAssist={handleDeleteAssist}
          setModalOpen={setUpdateAssist}
          setModalOpenDown={setOpenNotification}
          handleGetIdPassword={() => undefined}
          openTab={tabActive}
        />
      </RenderTableWithCondition>
      <RenderWithCondition condition={!fullAssistsList?.length && !touchedSearchRefAssist}>
        <EmptyPage openForm={setCreateAssist} />
      </RenderWithCondition>

      <ModalAssist
        isOpenPopup={openCreateAssist}
        setOpenPopup={setCreateAssist}
        textTitle="addAssist"
        onSave={handleAddAssist}
        handleSearch={handleSearchUsers}
        searchValue={search}
      />
      <ModalAssist
        isOpenPopup={openUpdateAssist}
        setOpenPopup={setUpdateAssist}
        textTitle="changeList"
        onSave={handleUpdateAssist}
        handleSearch={handleSearchUsers}
        searchValue={search}
        assistantName={currentName}
        checkedData={currentCheckedData}
        tabActive={tabActive}
      />
      <PopupModal isShown={openNotification} closeEvent={(v) => setOpenNotification(v)}>
        <Notification
          textTitle={tabActive ? textDeleteNotificationManager : textDeleteNotificationAssist}
          textName={`${currentNameShort}`}
          onDone={handleDeleteAssist}
          onCancel={() => setOpenNotification(false)}
        />
      </PopupModal>
    </>
  );
};

export default Assistants;
