import React, { FC } from 'react';
import { ReactComponent as DefaultAvatarIcon } from '@assets/icons/default-avatar.svg';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { LineText } from '@components/commons/commonsStyled';
import { Icon, ImgAvatar } from '@components/screens/employees/components/TableItem';
import { LocalLoader } from '@ui/loader/LocalLoader';
import styled from 'styled-components';
import { colorsVar } from '@components/constants/colorVariables';

interface INavBarAvatar {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  urlAvatar?: string;
  hidden: boolean;
  isLoading: boolean;
  text: string;
}
const NavBarAvatar: FC<INavBarAvatar> = (props) => {
  return (
    <Container className="flex" onClick={props.onClick}>
      <LocalLoader condition={props.isLoading} withoutBlur size={17} />
      <div className="flex justify-center">
        <RenderWithCondition condition={!props.urlAvatar}>
          <Icon className="flex" size={32}>
            <DefaultAvatarIcon width="32" height="32" />
          </Icon>
        </RenderWithCondition>
        <RenderWithCondition condition={!!props.urlAvatar}>
          <Icon className="flex" size={32}>
            <ImgAvatar src={props.urlAvatar} alt="avatar" />
          </Icon>
        </RenderWithCondition>
      </div>
      <LineText className="min-w-[147px]" fontSize="12px">
        {props.text}
      </LineText>
    </Container>
  );
};

export default NavBarAvatar;

export const Container = styled.div`
  align-items: center;
  min-width: 48px;
  height: 44px;
  box-sizing: border-box;
  padding: 10px 5px 10px 8px;
  gap: 14px;
  text-decoration: none;
  color: ${colorsVar.textColor};
  cursor: pointer;
`;
