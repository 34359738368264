import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as LineIcon } from '@assets/icons/line-dot.svg';
import select from '@assets/icons/select.png';
import { CheckInput } from '../../ui/checkInput';
import { useStatsSelector } from '@store/statistic/selectors';
import { useDispatch } from 'react-redux';
import { addSelectedGoals, currentOptionPersonalGoals } from '@store/statistic/slice';
import { getPosition } from '@components/screens/home/statsUtils';
import { useClickAway } from 'react-use';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import cn from 'classnames';
import './selectLine.scss';
import { uniqueId } from 'lodash';

interface SelectLineProps {
  onChange?: (value: string | boolean | null, id: string | undefined) => void;
  value?: boolean | null;
  id?: string;
}

export const SelectLine = ({ onChange, id, value }: SelectLineProps) => {
  const { optionsPersonalGoals } = useStatsSelector((state) => state.stats);

  const [isVisible, setisVisible] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(getPosition(optionsPersonalGoals));

  const dispatch = useDispatch();

  const ref = useRef(null);
  useClickAway(ref, () => {
    setisVisible(false);
  });

  const handleSelected = (value: boolean, definition: string) => {
    const newOptions = [...optionsPersonalGoals].map((line) => {
      return line.definition === definition ? { ...line, active: value } : line;
    });
    const newSelectGoals: any[] = [];
    newOptions.forEach((line) => {
      if (line.active) {
        newSelectGoals.push(line.nameLine);
      }
    });
    dispatch(currentOptionPersonalGoals(newOptions));
    dispatch(addSelectedGoals(newSelectGoals));
  };

  useEffect(() => {
    if (value === null) {
      setCurrentPosition(0);
    }
    if (value) {
      setCurrentPosition(1);
    }
    if (value === false) {
      setCurrentPosition(2);
    }
  }, []);

  const handleClick = (index: number) => {
    const data = optionsPersonalGoals[index].title;
    if (onChange) {
      onChange(data, id);
    }
    setCurrentPosition(index);
    setisVisible(false);
  };

  return (
    <div ref={ref} className="select-line ">
      <div className={`select-body  ${isVisible ? 'active' : ''}`}>
        <div
          className={cn('select line', { active: isVisible })}
          onClick={() => (!isVisible ? setisVisible(!isVisible) : undefined)}
        >
          <RenderWithCondition condition={!isVisible}>
            <>
              <LineIcon fill={optionsPersonalGoals[currentPosition].fill} />{' '}
              <span style={{ alignItems: 'center' }}>{optionsPersonalGoals[currentPosition].title} </span>
              <div className="select-item">
                <img src={select} alt="" />
              </div>
            </>
          </RenderWithCondition>
          <RenderWithCondition condition={isVisible}>
            <>
              <span style={{ alignItems: 'center' }}>{'Выберите линию'} </span>
            </>
          </RenderWithCondition>
        </div>
        {isVisible ? (
          <div className="items">
            {optionsPersonalGoals.map((pos, i) => (
              <div className="row-line" key={uniqueId(pos.title)}>
                <CheckInput name={pos.definition} defaultChecked={pos.active} handleSelected={handleSelected} />
                <LineIcon fill={pos.fill} />
                <span key={uniqueId(pos.fill)} onClick={() => handleClick(i)}>
                  {pos.title}
                </span>
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
