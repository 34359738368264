import React, { FC, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LineStatChart } from '@components/shared/lineChart/LineStatChart';
import { formattedDate } from '../../statsUtils';
import { useStatsSelector } from '@store/statistic/selectors';
import { getAllTasks } from '@store/statistic/api';
import { colorsVar } from '@components/constants/colorVariables';
import { IActiveUsersPeriod, ICompleteTasks, IPlanedTasks, IRenderLine } from 'interfaces/stats.interfaces';
import { IActionCreator } from 'interfaces/interfaces';
import { addFlagGetAllTasks } from '@store/statistic/slice';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { LabelChart } from '../сhartTask/ChartTask';
import { IDynamicsCompletionTasks, IDynamicsPlaningTasks } from 'interfaces/dynamics.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { ILineRectangles } from '@components/screens/month/components/tableDynamics/charts/constants';
import { HeaderCharts } from './HeaderCharts';
import { ControlBlockCharts } from './ControlBlockCharts';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import styled from 'styled-components';

interface IChartPlanComplete {
  tooltip: ({ active, payload, label }: any) => JSX.Element | null;
  planed?: boolean;
  currentUser?: string | null;
  isLoading: boolean;
  data: IPlanedTasks[] | ICompleteTasks[] | IDynamicsPlaningTasks[] | IDynamicsCompletionTasks[];
  period: IActiveUsersPeriod;
  setStartDate: (payload: any) => IActionCreator;
  setEndDate: (payload: any) => IActionCreator;
  getData: (payload: any) => IActionCreator;
  titleChart?: string;
  renderLine: IRenderLine[];
  lineRectanglesData?: ILineRectangles[][];
  lineData?: ILineRectangles[];
  textTitle?: string;
  wrap?: boolean;
  position: CalendarPosition;
  isLoadingPage?: boolean;
}

const ChartPlanComplete: FC<IChartPlanComplete> = (props) => {
  const { allTasks, isGetPeriod, isFavorites, isGetAllTasks } = useStatsSelector((state) => state.stats);

  const prevPeriod = useRef(props.period);

  const dispatch = useDispatch();

  const handleStartData = (event: dayjs.Dayjs) => {
    dispatch(props.setStartDate(event));
  };
  const handleEndData = (event: dayjs.Dayjs) => {
    dispatch(props.setEndDate(event));
  };

  useEffect(() => {
    const paramsString = new URLSearchParams({}).toString();
    if (props.planed) {
      if (isEmpty(allTasks) && !isGetAllTasks) {
        dispatch(addFlagGetAllTasks(true));
        dispatch(getAllTasks(paramsString));
      } else if (isEmpty(props.period) && allTasks.length) {
        dispatch(props.setStartDate(dayjs(allTasks[0].date)));
        dispatch(props.setEndDate(dayjs(allTasks.slice(-1)[0].date)));
      }
    } else {
      if (!isGetPeriod && allTasks.length) {
        dispatch(props.setStartDate(dayjs(dayjs(allTasks[0].date))));
        dispatch(props.setEndDate(dayjs()));
      }
    }
  }, [allTasks, isGetPeriod]);

  useEffect(() => {
    if (!isEmpty(props.period)) {
      const value = isFavorites ? 'true' : 'false';
      const paramsString = new URLSearchParams({
        favoritesOnly: value,
        startDate: dayjs(props.period.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(props.period.endDate).format('YYYY-MM-DD'),
      }).toString();
      if (!isEqual(prevPeriod.current, props.period)) {
        if (props.currentUser) {
          dispatch(props.getData({ params: paramsString, id: props.currentUser }));
        } else {
          dispatch(props.getData(paramsString));
        }
      }
      prevPeriod.current = props.period;
    }
  }, [props.period, isFavorites]);

  return (
    <ContainerChart className="flex">
      <RenderWithCondition condition={props.currentUser}>
        <HeaderCharts textTitle={props.textTitle} />
      </RenderWithCondition>
      <ControlBlockCharts
        period={props.period}
        onStartDate={handleStartData}
        onEndDate={handleEndData}
        isHidden={!!props.currentUser}
        doubleLine={props.lineRectanglesData}
        line={props.lineData}
        wrap={props.wrap}
        position={props.position}
      />
      <ChartWrap className="flex">
        <LabelChart className="flex">
          <RenderWithCondition condition={props.titleChart}>
            <FormattedMessage id={props.titleChart} />
          </RenderWithCondition>
        </LabelChart>
        <RenderWithCondition condition={!props.isLoadingPage}>
          <LineStatChart
            fill={colorsVar.textColor}
            dot={{ r: 3 }}
            data={formattedDate(props.data)}
            pointXaxis="date"
            renderLine={props.renderLine}
            externalTooltip={props.tooltip}
          />
        </RenderWithCondition>
        <LocalLoader condition={props.isLoading || (props.isLoadingPage ?? false)} size={25} />
      </ChartWrap>
    </ContainerChart>
  );
};

export default ChartPlanComplete;

export const ContainerChart = styled.div`
  flex-direction: column;
  width: 100%;
`;

const ChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 357px;
  background-color: ${colorsVar.white};
  padding: 3px 5px 5px 0;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
`;
