import { uniqueId } from 'lodash';
import React from 'react';

import styled from 'styled-components';

interface TooltipChartProps {
  formattedTooltip: (str: string) => void;
  payload: any[];
  label: any[];
}

export const TooltipChart = ({ payload, label, formattedTooltip }: TooltipChartProps) => {
  return (
    <Container className="flex flex-col">
      <div className="flex">{label}</div>
      {payload.map((line: any) => (
        <Line key={uniqueId(line.name)} className="flex" color={line.color}>
          {`${formattedTooltip(line.name)}: ${line.value} `}
        </Line>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 15px;
  background-color: #f5f9f8;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.02);
  border-radius: 7px;
  font-size: 14px;
  color: #274335;
`;
const Line = styled.div<{ color: string }>`
  color: ${(props) => props.color};
`;
