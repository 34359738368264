import React, { FC } from 'react';

import { ContainerTable, LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'lodash';
import { IMapPersonalGoals } from '../../hooks/useTableUserStatistics';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { colorsVar } from '@components/constants/colorVariables';
import { mobile } from '@components/constants/constants';
import styled from 'styled-components';

interface IPersonalGoals {
  data: IMapPersonalGoals[];
}

export const PersonalGoals: FC<IPersonalGoals> = (props) => {
  const { isMobile } = useWidthScreens();

  return (
    <>
      <Container className="flex flex-col gap-5">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="personalGoals" />
        </LineText>
        <ContainerTable className="flex">
          {props.data.map((goal: any) => (
            <ItemGoals className="flex" key={uniqueId(goal.name)}>
              <div className="flex gap-4 items-center">
                <IconWrap className="flex">
                  <IconGoals className="flex">{goal.icon}</IconGoals>
                </IconWrap>
                <LineText fontSize={'16px'}>
                  <FormattedMessage id={goal.name} />
                </LineText>
                <ItemValue className="flex">{`${goal.doneGoals ?? ''}/${goal.totalGoals ?? ''}`}</ItemValue>
              </div>
            </ItemGoals>
          ))}
        </ContainerTable>
      </Container>
    </>
  );
};

const Container = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;
const ItemGoals = styled.div`
  width: 100%;
  align-items: center;
  padding: 10px;
  gap: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colorsVar.grey1};
  }
  box-sizing: border-box;
`;
const ItemValue = styled.div`
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 34px;
  background-color: ${colorsVar.grey3};
  font-weight: 700;
`;
const IconWrap = styled.div`
  justify-content: center;
  align-items: center;
`;
const IconGoals = styled.div`
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  border: 1px solid ${colorsVar.grey1};
  border-radius: 100%;
  @media screen and (max-width: ${mobile}px) {
    width: 37px;
    height: 37px;
  }
`;
