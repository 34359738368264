import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';
import { ReactComponent as DownArrowIcon } from '@assets/icons/chevron_down_grey.svg';
import Search from '@ui/search/Search';
import CheckInputMod from '@ui/checkInput/CheckInputMod';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { mobile, tablet } from '@components/constants/constants';
import { uniqueId } from 'lodash';
import { useAppSelector } from '@store/hooks';
import { selectAssistSlice, setState } from '@store/assistants/assistants.slice';
import { useDispatch } from 'react-redux';
import { IStateUser } from 'interfaces/user.interfaces';
import { IPartialAssist } from '@components/screens/assistants/components/TableAssistItem';
import { useClickAway } from 'react-use';
import styled, { keyframes } from 'styled-components';

interface IDropDown {
  handleCheck: (value: IPartialAssist) => void;
  textLabel: string;
  dropList: IStateUser[];
  checkedList: IPartialAssist[] | null;
  assist?: boolean;
  textHeader: string | undefined;
  isOpenPopup?: boolean | null;
  handleSearch: (value: string) => void;
  isLoading: boolean;
  searchValue?: string;
}

const DropDown = ({
  handleCheck,
  textLabel,
  dropList,
  checkedList,
  assist,
  textHeader,
  isOpenPopup,
  handleSearch,
  isLoading,
  searchValue,
}: IDropDown) => {
  const [openDrop, setOpenDrop] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const {
    assistList,
    currentUserListForAssist,
    currentUserListForManager,
    currentUserListForCreating,
    currentDataForUpdate,
  } = useAppSelector(selectAssistSlice);

  const dispatch = useDispatch();

  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpenDrop(false);
  });

  useEffect(() => {
    setOpenDrop(false);
  }, [isOpenPopup]);

  useEffect(() => {
    if (!openDrop) handleSearch('');
  }, [openDrop]);

  return (
    <DropList className="flex">
      <Label className="flex  justify-center">
        <LineText className="justify-center" fontSize="20px">
          <FormattedMessage id={textLabel} />
        </LineText>
      </Label>
      <DropBase ref={ref}>
        <DropHeader
          onClick={(e) => {
            setOpenDrop(!openDrop);
          }}
          active={openDrop}
        >
          <LineText className="justify-center" fontSize="18px" color={textHeader ? '' : colorsVar.grey4}>
            {textHeader ? textHeader : <FormattedMessage id="selectFromList" />}
          </LineText>
          <DownArrowIcon />
        </DropHeader>

        <DropFieldWrap active={openDrop}>
          <DropField ref={ref}>
            <div className="flex h-11 px-2 py-1 w-full">
              <Search handleSearch={handleSearch} isLoading={isLoading} searchValue={searchValue} />
            </div>
            <div className="flex flex-col w-full">
              {!isLoading &&
                checkedList &&
                checkedList.map((item) => (
                  <ItemCheckAssist key={uniqueId(item.id)}>
                    <CheckInputMod
                      changeEvent={() => {
                        handleCheck(item);
                      }}
                      isActive={item?.isActive ?? false}
                      isDisabled={false}
                    />
                    <LineText className="justify-center" fontSize="14px" lineHeight="16px">
                      {item.lastName} {item.firstName} {item.middleName}
                    </LineText>
                  </ItemCheckAssist>
                ))}
            </div>
            <ScrolledField>
              {!isLoading &&
                currentUserListForCreating &&
                currentUserListForCreating.map((item) => (
                  <ItemCheckAssist key={uniqueId(item.id)}>
                    <CheckInputMod
                      changeEvent={() => {
                        handleCheck(item);
                        setChecked(!checked);
                      }}
                      isActive={false}
                      isDisabled={assist ? checkedList?.length === 3 : checkedList?.length === 1}
                    />
                    <LineText className="justify-center" fontSize="14px" lineHeight="16px">
                      {item.lastName} {item.firstName} {item.middleName}
                    </LineText>
                  </ItemCheckAssist>
                ))}
            </ScrolledField>
          </DropField>
        </DropFieldWrap>
      </DropBase>
    </DropList>
  );
};

export default DropDown;

const DropList = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  @media screen and (max-width: ${mobile}px) {
    flex-direction: column;
    gap: 8px;
  }
`;
const Label = styled.div`
  height: 56px;
  @media screen and (max-width: ${mobile}px) {
    height: 30px;
  }
`;

const rotateArrowAnimationUp = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;
const rotateArrowAnimationDown = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
const DropHeader = styled.div<{ active: boolean }>`
  display: flex;
  width: 400px;
  height: 56px;
  padding: 15px 12px 15px 12px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colorsVar.grey1};
  cursor: pointer;
  svg {
    transform: rotate(0deg);
    animation: ${({ active }) => {
        return active ? rotateArrowAnimationUp : rotateArrowAnimationDown;
      }}
      500ms linear forwards;
  }
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
  }

  @media screen and (max-width: ${mobile}px) {
    height: 46px;
    flex: 1;
    width: 100%;
  }
`;
const DropBase = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: ${tablet}px) {
    width: 70%;
  }
  @media screen and (max-width: ${mobile}px) {
    height: 46px;
    flex: 1;
    width: 100%;
  }
`;
const DropField = styled.div`
  display: flex;
  gap: 7px;
  max-height: 230px;
  min-height: 230px;
  flex-direction: column;
  padding: 6px;
  border-radius: 10px;
  align-items: center;
  border: 1px solid ${colorsVar.grey1};
`;
const ScrolledField = styled.div`
  display: flex;
  width: 100%;
  max-height: 300px;
  flex-direction: column;

  align-items: center;
  overflow: auto;
`;
const ItemCheckAssist = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  padding: 8px 12px;
  gap: 8px;
`;
const DropFieldWrap = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;

  transition: max-height 0.7s ease 0.5s;

  ${({ active }) =>
    active &&
    `
    max-height: 300px;
    transition: max-height 0.7s ease ;
  `}
`;
