import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useClickAway } from 'react-use';
import select from '@assets/icons/select.png';
import { truncate } from '../../utils/formattedText';
import './select.scss';
import { uniqueId } from 'lodash';

interface ISelect {
  onChange?: (value: string | boolean | null, id: string) => void;
  options?: string[];
  value?: boolean | null;
  isVisible?: boolean | null;
  valueStr?: string | null;
  type: string;
  id: string;
}

export const Select = ({ onChange, id, value, valueStr, options = ['---', 'default'] }: ISelect) => {
  const [isVisible, setisVisible] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setisVisible(false);
  });

  if (options === null) {
    options = ['---', 'default'];
  }

  useEffect(() => {
    if (value === null) {
      setCurrentPosition(0);
    }
    if (value) {
      setCurrentPosition(1);
    }
    if (value === false) {
      setCurrentPosition(2);
    }
    if (valueStr) {
      const indexValueStr = options.indexOf(valueStr);
      if (indexValueStr > -1) setCurrentPosition(indexValueStr);
    }
  }, []);

  const handleClick = (index: number) => {
    const data = options[index];
    if (onChange) {
      onChange(data, id);
    }
    setCurrentPosition(index);
    setisVisible(false);
  };

  return (
    <div className="select-wrapper" ref={ref}>
      <div className={`select-body  ${isVisible ? 'active' : ''}`}>
        <div className="select" onClick={() => setisVisible(!isVisible)}>
          <span>{truncate(options[currentPosition], 31)} </span>
          <div style={{ width: 26, height: 22.4 }}>
            <img src={select} alt="" />
          </div>
        </div>
        {isVisible ? (
          <div className="items">
            {options.map((pos, i) => (
              <span key={uniqueId('pos')} onClick={() => handleClick(i)}>
                {pos}
              </span>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
