export const handlerShow = (): void => {
  try {
    document.body.style.overflow = 'hidden';
  } catch (e) {
    console.error(e);
  }
};

export const handlerHide = (): void => {
  try {
    document.body.style.overflow = 'auto';
  } catch (e) {
    console.error(e);
  }
};
