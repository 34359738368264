import React, { useState, useEffect, FC, useMemo } from 'react';
import { isFullVersion } from '@components/constants/constants';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { ModalRenderWithCondition } from '@components/hoc/ModalRenderWithCondition';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '@store/auth/slice';
import { useAuthSelector } from '@store/auth/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '@store/store';
import { deleteCurrentSession } from '@store/auth/api';
import notify from '@utils/notify';
import { CURRENT_USER_ID } from '@configs/consts';
import { addCurrentUser } from '@store/user/slice';
import { resetDynamicsState } from '@store/dynamics/slice';
import ModalNotification from '@components/shared/modalNotification/ModalNotification';
import NavBarAvatar from './NavBar.Avatar';
import NavBarExit from './NavBar.Exit';
import NavBarHidden from './NavBar.Hidden';
import NavBarLink from './NavBar.Link';
import NavBarLogo from './NavBar.Logo';
import useGetUrlAvatar from '../../../hooks/useGetUrlAvatar';
import { selectDeviceSlice, setState } from '@store/device/device.slice';
import { colorsVar } from '@components/constants/colorVariables';
import { useWidthScreens } from '@hooks/useWidthScreens';
import { useAppSelector } from '@store/hooks';
import PopupModal from '@ui/modal/PopupModal';
import Notification from '@ui/notification/Notification';
import styled from 'styled-components';

const NavBar: FC = () => {
  const { width } = useWidthScreens();

  const { supervisor, mainUser, haveEmployees, isLoading } = useAuthSelector((state) => state.auth);
  const lineNames = useMemo(() => {
    return `${mainUser?.lastName ?? ''} ${mainUser?.firstName ?? ''} ${mainUser?.middleName ?? ''}`.trim();
  }, [mainUser]);

  console.log();

  const [hidden, setHidden] = useState(false);
  const { isHiddenNavbar } = useAppSelector(selectDeviceSlice);
  const [openExitPopup, setExitPopup] = useState<boolean | null>(false);

  const { isLoginFromDTApp } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlAvatar = useGetUrlAvatar({ fileId: mainUser?.avatarFileId });
  const location = useLocation();

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1400px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setHidden(event.matches);
      dispatch(
        setState({
          isHiddenNavbar: event.matches,
        }),
      );
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [location.pathname]);

  const handleToGoUserInfo = () => {
    if (isFullVersion) {
      dispatch(addCurrentUser({ ...mainUser, blobUrlAvatar: urlAvatar }));
      localStorage.setItem(CURRENT_USER_ID, mainUser.id);
      dispatch(resetDynamicsState(mainUser));
      navigate('/employees/month');
    }
  };

  const handleOpenNotification = () => {
    setExitPopup(true);
  };
  const handleLogOut = async () => {
    const response = await dispatch(deleteCurrentSession());
    if (response.error) {
      notify.error(response.payload);
    } else {
      setExitPopup(null);
      dispatch(logOut());
    }
  };

  return (
    <ContainerWrap className="flex" hidden={isHiddenNavbar}>
      <ContainerNavBar className="flex" hidden={isHiddenNavbar}>
        <div className="flex flex-col">
          <RenderWithCondition condition={!isLoginFromDTApp}>
            <NavBarLogo hidden={isHiddenNavbar} />
          </RenderWithCondition>
          <NavBarLink hidden={isHiddenNavbar} haveEmployees={haveEmployees} supervisor={supervisor} />
          <NavBarHidden
            onClick={() => {
              dispatch(
                setState({
                  isHiddenNavbar: !isHiddenNavbar,
                }),
              );
            }}
            hidden={isHiddenNavbar}
          />
        </div>
        <RenderWithCondition condition={!isLoginFromDTApp}>
          <BottomBlock className="flex flex-col">
            <NavBarExit onClick={handleOpenNotification} hidden={hidden} />
            <NavBarAvatar
              isLoading={isLoading}
              urlAvatar={urlAvatar}
              onClick={handleToGoUserInfo}
              hidden={isHiddenNavbar}
              text={lineNames}
            />
          </BottomBlock>
        </RenderWithCondition>
      </ContainerNavBar>

      <PopupModal isShown={openExitPopup} closeEvent={(v) => setExitPopup(v)}>
        <Notification textTitle={'exitconfirm'} onDone={handleLogOut} onCancel={() => setExitPopup(false)} />
      </PopupModal>

      <BlurField className="flex" hidden={isHiddenNavbar && width !== null && width < 800} />
    </ContainerWrap>
  );
};

export default NavBar;

const ContainerWrap = styled.div<{ hidden: boolean }>`
  width: 48px;
  transition: width 0.7s 0.1s ease-in-out;
  ${({ hidden }) =>
    hidden &&
    `
    width: 206px;
    transition: width 0.7s ease-in-out;
  `}
`;
const ContainerNavBar = styled.div<{ hidden: boolean }>`
  position: sticky;
  overflow: hidden;
  top: 0;
  left: 0;
  flex-shrink: 0;
  height: 100vh;
  -webkit-box-shadow: 3px 1px 21px 0px rgba(227, 233, 231, 0.74);
  box-shadow: 3px 1px 21px 0px rgba(227, 233, 231, 0.74);
  z-index: 2;
  background-color: ${colorsVar.white};
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;

  width: 48px;
  transition: width 0.7s 0.1s ease-in-out;

  ${({ hidden }) =>
    hidden &&
    `
    width: 206px;
    transition: width 0.7s ease-in-out;
  `}
`;
const BlurField = styled.div<{ hidden: boolean }>`
  position: fixed;
  width: 0;
  height: 100vh;
  top: 0;
  left: 0px;
  background-color: rgba(29, 46, 38, 0.538);
  backdrop-filter: blur(10.5px);
  transition: all 0.7s ease;

  ${({ hidden }) =>
    hidden &&
    `
    transition: 
    width 0.5s  ease;
    top: 0;
    width: 100vw; 

  `}

  z-index: -1;
`;
const BottomBlock = styled.div`
  position: sticky;
  bottom: 0;
`;
