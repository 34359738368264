import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import searcIcon from '@assets/icons/search.png';

import { Modal } from '../../../../../ui/modal';
import './modalChooseAdmin.scss';
import { useDispatch } from 'react-redux';
import { useAdminSelector } from '@store/admin/selectors';
import { getAllUsers } from '@store/admin/api';
import { RenderTableWithCondition } from '@components/hoc/RenderTableWithCondition';
import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { IStateUser } from 'interfaces/user.interfaces';
import './modalChooseAdmin.scss';

interface IModalChooseAdmin {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleChoose: (user: IStateUser) => void;
}

export const ModalChooseAdmin = ({ setIsVisible, handleChoose }: IModalChooseAdmin) => {
  const [search, setSearch] = useState('');

  const touchedSearchRef = useRef(false);
  if (search) touchedSearchRef.current = true;

  const { allUsers, isLoading } = useAdminSelector((state) => state.admin);
  const dispatch = useDispatch();

  const debounceValue = debounce((value) => {
    setSearch(value);
  }, 2000);

  useEffect(() => {
    if (allUsers.length === 0) {
      const paramsString = new URLSearchParams({
        adminOnly: 'false',
      }).toString();
      dispatch(getAllUsers(paramsString));
    }
  }, []);

  useEffect(() => {
    if (search || touchedSearchRef.current) {
      const paramsString = new URLSearchParams({
        query: search,
      }).toString();
      dispatch(getAllUsers(paramsString));
    }
  }, [search]);

  const handleSearchUsers = (event: ChangeEvent<HTMLInputElement>) => {
    debounceValue(event.target.value);
  };

  return (
    <Modal setIsVisible={setIsVisible}>
      <div className="choose-admin">
        <h1>
          <FormattedMessage id="selectFromList" />
        </h1>
        <div className="filter-input">
          <input onChange={(search) => handleSearchUsers(search)} />
          <img src={searcIcon} alt="search" />
        </div>
        <div className="list-wrap">
          <RenderTableWithCondition condition={isLoading}>
            {allUsers.map((user) => (
              <div
                key={user.id}
                onClick={() => handleChoose(user)}
                className="list-item"
              >{`${user?.lastName} ${user?.firstName} ${user?.middleName} `}</div>
            ))}
          </RenderTableWithCondition>
        </div>
      </div>
    </Modal>
  );
};
