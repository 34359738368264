import React, { FC } from 'react';

import { PersonalGoals } from './PersonalGoals';
import { BusinessGoals } from './BusinessGoals';
import { ContainerTable, LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { PlaningBusinessGoals } from './PlaningBusinessGoals';
import { DelegatedBusinessGoals } from './DelegatedBusinessGoals';
import { IDataUserMonth } from '../../hooks/useTableUserStatistics';
import { PlaningMeetings } from './PlaningMeetings';
import { mobile, tablet } from '@components/constants/constants';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';

interface ITableMonth {
  data: IDataUserMonth;
  userName: string;
}

export const TableMonth: FC<ITableMonth> = (props) => {
  const { isMobile } = useWidthScreens();

  return (
    <Container>
      <div className="flex flex-col gap-5">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="monthGoal" />
        </LineText>
        <ContainerTable className="py-2 px-5">
          <div className="flex w-full">
            <LineText fontSize={'15px'}>{props.data.textMonthGoal}</LineText>
          </div>
        </ContainerTable>
      </div>
      <GroupTasks className="flex flex-nowrap gap-8">
        <GoalsWrap className="flex-1">
          <BusinessGoals data={props.data} />
          <RenderWithCondition condition={isMobile}>
            <PersonalGoals data={props.data.mapPersonalGoals} />
          </RenderWithCondition>
          <PlaningBusinessGoals data={props.data.planingBusinessTasks} userName={props.userName} />
          <DelegatedBusinessGoals data={props.data.mapDelegatedBusinessTasks} userName={props.userName} />
          <PlaningMeetings data={props.data.mapPlaningMeeting} userName={props.userName} />
        </GoalsWrap>
        <RenderWithCondition condition={!isMobile}>
          <PersonalGoals data={props.data.mapPersonalGoals} />
        </RenderWithCondition>
      </GroupTasks>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 30px 30px 190px 30px;

  @media screen and (max-width: ${tablet}px) {
    padding: 24px 24px 190px 24px;
  }
  @media screen and (max-width: ${mobile}px) {
    padding: 16px 16px 190px 12px;
    gap: 24px;
  }
`;
export const GroupTasks = styled.div`
  flex: 1;
  @media screen and (max-width: ${mobile}px) {
    flex-direction: column;
  }
`;
const GoalsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  @media screen and (max-width: ${mobile}px) {
    gap: 32px;
  }
`;
