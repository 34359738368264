import React, { FC } from 'react';

import { LineText } from '@components/commons/commonsStyled';
import { BlockItem } from './UserInfo';
import { uniqueId } from 'lodash';
import { IMapUserInfo } from 'interfaces/stats.interfaces';
import { FormattedMessage } from 'react-intl';

interface IItemUserInfo {
  mapData: IMapUserInfo[];
}

const ItemUserInfo: FC<IItemUserInfo> = (props) => {
  return (
    <>
      {Array.isArray(props.mapData) &&
        props.mapData.map((line) => (
          <BlockItem key={uniqueId(line.title)}>
            <LineText
              fontSize={line.boldTitle ? '16px' : '15px'}
              leftIndent={line.leftIndent}
              bold={line.boldTitle ? line.boldTitle : ''}
            >
              <FormattedMessage id={line.title} />
            </LineText>
            <LineText fontSize="16px" bold="700">
              {line.value}
            </LineText>
          </BlockItem>
        ))}
    </>
  );
};

export default ItemUserInfo;
