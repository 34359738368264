import { http } from '../utils/http';

export interface DataCreateUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  type: string | null;
  login: string | null;
}

class StatsService {
  constructor() {}

  getActiveUsers = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/users-count?${params}`);
  };

  getUsersTasks = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/user-tasks-count?${params}`);
  };

  getUsersGoals = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/user-goals-count?${params}`);
  };

  getOpenTasks = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/tasks-planning?${params}`);
  };

  getCompletionTasks = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/tasks-completion?${params}`);
  };

  getCreationTasks = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/tasks-creation?${params}`);
  };

  getClosureTasks = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/tasks-closure?${params}`);
  };

  getBusinessGoals = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/business-goals-count?${params}`);
  };

  getPersonalGoals = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/dashboards/personal-goals-count?${params}`);
  };
}

export const statsService = new StatsService();
