import React, { FC } from 'react';

import { HeaderBusinessGoals } from './Header.BusinessGoals';
import { TableBusinessGoals } from './Table.BusinessGoals';
import { TasksBusinessGoals } from './Tasks.BusinessGoals';
import { IDataUserMonth } from '../../hooks/useTableUserStatistics';
import { styled } from 'styled-components';

interface IBusinessGoals {
  data: IDataUserMonth;
}

export const BusinessGoals: FC<IBusinessGoals> = (props) => {
  return (
    <>
      <Container>
        <HeaderBusinessGoals data={props.data.numbersOpenClosedBusinessGoals} />
        <TableBusinessGoals data={props.data.mapBusinessGoals} />
        <TasksBusinessGoals data={props.data.mapDetailedBusinessGoals} />
      </Container>
    </>
  );
};

const Container = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
