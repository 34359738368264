import React, { useState } from 'react';

import './checkbox.scss';

export const Checkbox = ({ setIsVisibleForm, onChange, value, name }: any) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = () => {
    // onChange()
    setChecked(!checked);
    if (setIsVisibleForm) {
      setIsVisibleForm((prev: boolean) => !prev);
    }
  };
  return (
    <div className="checkbox-wrapper">
      <label className="checkbox-ios">
        <input name={name} type="checkbox" onChange={onChange} onClick={handleCheck} value={value} />
        <span className="checkbox-ios-switch"></span>
      </label>
    </div>
  );
};
