import React, { FC, useEffect, useRef } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useStatsSelector } from '@store/statistic/selectors';
import { SelectDateMonth } from '@components/shared/selectDate/SelectDateMonth';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { BarStatChart } from '@components/shared/barChart/BarStatChart';
import { addEndMonthBusinessDate, addStartMonthBusinessDate } from '@store/dynamics/slice';
import { useDynamicsSelector } from '@store/dynamics/selectors';
import { getMonthBusinessGoals } from '@store/dynamics/api';
import { formattedDateMonth } from '@components/screens/home/statsUtils';
import { LineText } from '@components/commons/commonsStyled';
import { CURRENT_USER_ID } from '@configs/consts';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { ChartWrap } from './RadarChartPersonCompleteness';
import { ContainerChart } from '@components/screens/home/components/chartPlanComplete/ChartPlanComplete';
import styled from 'styled-components';

interface IBarChartMonthBusinessGoals {
  tooltip?: ({ active, payload, label }: any) => JSX.Element | null;
  position: CalendarPosition;
}

const BarChartMonthBusinessGoals: FC<IBarChartMonthBusinessGoals> = (props) => {
  const { monthBusinessGoals, monthBusinessGoalsPeriod, isLoadingBusinessGoals } = useDynamicsSelector(
    (state) => state.dynamics,
  );
  const { currentUser } = useStatsSelector((state) => state.user);
  const currentUserId = currentUser?.id ? currentUser.id : localStorage.getItem(CURRENT_USER_ID);
  const prevPeriod = useRef(monthBusinessGoalsPeriod);

  const dispatch = useDispatch();

  const handleStartData = (event: Dayjs) => {
    dispatch(addStartMonthBusinessDate(event));
  };
  const handleEndData = (event: Dayjs) => {
    dispatch(addEndMonthBusinessDate(event));
  };

  useEffect(() => {
    if (isEmpty(monthBusinessGoals) && isEmpty(monthBusinessGoalsPeriod)) {
      dispatch(addStartMonthBusinessDate(dayjs().add(-6, 'month')));
      dispatch(addEndMonthBusinessDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(monthBusinessGoalsPeriod)) {
      const paramsString = new URLSearchParams({
        startYearMonth: dayjs(monthBusinessGoalsPeriod.startDate).format('YYYY-MM'),
        endYearMonth: dayjs(monthBusinessGoalsPeriod.endDate).format('YYYY-MM'),
      }).toString();
      if (!isEqual(prevPeriod.current, monthBusinessGoalsPeriod) && currentUserId) {
        dispatch(getMonthBusinessGoals({ params: paramsString, id: currentUserId }));
      }
      prevPeriod.current = monthBusinessGoalsPeriod;
    }
  }, [monthBusinessGoalsPeriod]);

  return (
    <ContainerChart className="flex">
      <HeaderChart className="flex">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="businessGoals" />
        </LineText>
        <GroupSelect className="flex">
          <SelectDateMonth
            type="input"
            onChange={handleStartData}
            id="firstEntryDateStartRange"
            value={monthBusinessGoalsPeriod.startDate}
            position={props.position}
          />
          <span>-</span>
          <SelectDateMonth
            type="input"
            id="firstEntryDateStartRange"
            onChange={handleEndData}
            value={monthBusinessGoalsPeriod.endDate}
            position={props.position}
          />
        </GroupSelect>
      </HeaderChart>
      <ChartWrap className="flex relative">
        <BarStatChart data={formattedDateMonth(monthBusinessGoals)} externalTooltip={props.tooltip} />
        <LocalLoader condition={isLoadingBusinessGoals} />
      </ChartWrap>
    </ContainerChart>
  );
};

export default BarChartMonthBusinessGoals;

const HeaderChart = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  padding: 5px 0 20px 0;
`;
const GroupSelect = styled.div`
  gap: 15px;
  align-items: center;
  position: relative;
`;
