import React, { useState } from 'react';

import { Modal } from '../../../ui/modal';
import { Select } from '../../../ui/select';
import { SelectCalendar } from '../../../ui/selectCalendar';
import { optionsAccess, optionsTest } from './constants';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { IFilter } from 'interfaces/filter.interfaces';
import { getIdDepartment } from '../../../utils/getOptionsDepartments';
import { useUsers } from '@store/user/selectors';
import { currentDep } from '@store/user/slice';
import cn from 'classnames';
import './modalFilter.scss';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import dayjs from 'dayjs';

interface IModalFilter {
  filter: IFilter;
  options?: string[];
  isOthers?: boolean;
  isFiltered: boolean;
  supervisor?: boolean;
  handleFiltered: () => void;
  setFilter: ActionCreatorWithPayload<Partial<IFilter>>;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalFilter = ({
  filter,
  options,
  isOthers,
  setFilter,
  isFiltered,
  supervisor,
  setIsVisible,
  handleFiltered,
}: IModalFilter) => {
  const [isEdit, setIsEdit] = useState(false);

  // @ts-ignore
  const { departments, currentNameDep } = useUsers();

  const dispatch = useDispatch();

  const onSubmit = () => {
    handleFiltered();
    setIsVisible(false);
  };
  const handleSetFilter = (value: string | boolean | null, definition: string) => {
    setIsEdit(true);

    if (definition === 'departmentId') {
      if (value === '---') {
        dispatch(currentDep({ departmentName: null }));
        return dispatch(setFilter({ [definition]: null }));
      } else {
        const idDep = getIdDepartment(departments, String(value));
        dispatch(currentDep({ departmentName: String(value) }));
        return dispatch(setFilter({ [definition]: idDep }));
      }
    }
    if (definition === 'testOnly') {
      if (value === 'Тест') {
        return dispatch(setFilter({ [definition]: true }));
      } else if (value === 'no-Test') {
        return dispatch(setFilter({ [definition]: false }));
      } else {
        return dispatch(setFilter({ [definition]: null }));
      }
    }
    if (definition === 'isEnabled') {
      if (value === 'Права') {
        return dispatch(setFilter({ [definition]: true }));
      } else if (value === 'Нет прав') {
        return dispatch(setFilter({ [definition]: false }));
      } else {
        return dispatch(setFilter({ [definition]: null }));
      }
    }
    if (definition === 'notLoggedIn' || definition === 'loggedIn') {
      if (value === true) {
        return dispatch(setFilter({ [definition]: true }));
      } else {
        return dispatch(setFilter({ [definition]: null }));
      }
    }
    if (
      definition === 'firstEntryDateStartRange' ||
      definition === 'firstEntryDateEndRange' ||
      definition === 'lastEntryDateStartRange' ||
      definition === 'lastEntryDateEndRange'
    ) {
      if (value === null) {
        return dispatch(setFilter({ [definition]: null }));
      } else {
        dispatch(setFilter({ loggedIn: true }));
        return dispatch(setFilter({ [definition]: value }));
      }
    }
  };

  return (
    <Modal setIsVisible={setIsVisible}>
      <div className="modal-filter">
        <h1>
          <FormattedMessage id="filter" />
        </h1>
        <div className={cn('body', { supervisor: supervisor || isOthers })}>
          <RenderWithCondition condition={!isOthers}>
            <div>
              <span>
                <FormattedMessage id="subdivision" />
              </span>
              <div className="modal-filter-select departaments">
                <Select
                  type="select"
                  id="departmentId"
                  valueStr={currentNameDep}
                  onChange={handleSetFilter}
                  options={options}
                />
              </div>
            </div>
          </RenderWithCondition>
          <RenderWithCondition condition={!supervisor}>
            <>
              <div>
                <span>
                  <FormattedMessage id="test" />
                </span>
                <div className="modal-filter-select">
                  <Select
                    id="testOnly"
                    type="select"
                    value={filter.testOnly}
                    onChange={handleSetFilter}
                    options={optionsTest}
                  />
                </div>
              </div>
              <RenderWithCondition condition={!isOthers}>
                <div>
                  <span>
                    <FormattedMessage id="rights" />
                  </span>
                  <div className="modal-filter-select isEnabled">
                    <Select
                      type="select"
                      id="isEnabled"
                      value={filter.isEnabled}
                      options={optionsAccess}
                      onChange={handleSetFilter}
                    />
                  </div>
                </div>
              </RenderWithCondition>
            </>
          </RenderWithCondition>
        </div>

        <div className="line"></div>

        <div className="calendar-wrap">
          <div className="cal-item">
            <div className="left-label">
              <FormattedMessage id="appUsage" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 18 }}>
              <div>
                <input
                  type="checkbox"
                  name="notLoggedIn"
                  // @ts-ignore
                  defaultChecked={filter.notLoggedIn}
                  onChange={(value) => handleSetFilter(value.target.checked, 'notLoggedIn')}
                  className="check-input"
                />
                <label className="check"></label>
                <span>
                  <FormattedMessage id="notLoggedIn" />
                </span>
              </div>

              <div>
                <input
                  type="checkbox"
                  name="loggedIn"
                  // @ts-ignore
                  defaultChecked={filter.loggedIn}
                  onChange={(value) => handleSetFilter(value.target.checked, 'loggedIn')}
                  className="check-input"
                />
                <label className="check"></label>
                <span>
                  <FormattedMessage id="loggedIn" />
                </span>
              </div>
            </div>
          </div>

          <div className="cal-item">
            <div className="left-label"></div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingLeft: 36, gap: 20 }}>
              <div className="data-picker">
                <span>
                  <FormattedMessage id="firstActivity" />
                </span>
                <div style={{ display: 'flex', gap: 27 }}>
                  <div className="celect-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="firstEntryDateStartRange"
                      // @ts-ignore
                      value={
                        // @ts-ignore
                        filter.firstEntryDateStartRange ? dayjs(filter.firstEntryDateStartRange).format('DD.MM.YY') : ''
                      }
                    />
                  </div>
                  <div className="celect-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="firstEntryDateEndRange"
                      // @ts-ignore
                      value={
                        filter.firstEntryDateEndRange ? dayjs(filter.firstEntryDateEndRange).format('DD.MM.YY') : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="data-picker">
                <span>
                  <FormattedMessage id="lastActivity" />
                </span>
                <div style={{ display: 'flex', gap: 27 }}>
                  <div className="celect-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="lastEntryDateStartRange"
                      // @ts-ignore
                      value={
                        filter.lastEntryDateStartRange ? dayjs(filter.lastEntryDateStartRange).format('DD.MM.YY') : ''
                      }
                    />
                  </div>
                  <div className="celect-cal-body">
                    <SelectCalendar
                      type="input"
                      onChange={handleSetFilter}
                      id="lastEntryDateEndRange"
                      // @ts-ignore
                      value={filter.lastEntryDateEndRange ? dayjs(filter.lastEntryDateEndRange).format('DD.MM.YY') : ''}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button disabled={isFiltered && !isEdit} onClick={onSubmit}>
          <FormattedMessage id="showIt" />
        </button>
      </div>
    </Modal>
  );
};
