import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { AppRouter } from './components/navigation/AppRouter';
import { LOCALES, messages } from './utils/translate';
import 'dayjs/locale/ru';
import { store } from '@store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [locale, setLocale] = React.useState(LOCALES.RUSSIAN);
  return (
    <Provider store={store}>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.RUSSIAN}>
        <Router>
          <div className="App">
            <AppRouter />
          </div>
          <ToastContainer />
        </Router>
      </IntlProvider>
    </Provider>
  );
}

export default App;
