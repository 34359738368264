interface Props {
  condition: any;
  children: JSX.Element;
}

export const RenderWithCondition = ({ condition, children }: Props) => {
  if (!condition) {
    return null;
  }

  return children;
};
