import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { formattedDataGoalsPie } from '../../statsUtils';
import { useStatsSelector } from '@store/statistic/selectors';
import { getUsersGoals } from '@store/statistic/api';
import { addPieBusinessDate } from '@store/statistic/slice';
import { PieStatChart } from '@components/shared/pieChart/PieStatChart';
import { SelectDateMonth } from '@components/shared/selectDate/SelectDateMonth';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { isEmpty, isEqual } from 'lodash';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';

interface PieChartBusinessProps {
  position: CalendarPosition;
  isLoadingPage: boolean;
}

export const PieChartBusiness = ({ position, isLoadingPage }: PieChartBusinessProps) => {
  const { usersWithGoals, tasksBusinessPeriod, isLoadingUsersGoals } = useStatsSelector((state) => state.stats);

  const prevTasksBusinessPeriod = useRef(tasksBusinessPeriod.businessDate);

  const dispatch = useDispatch();

  const handleStartData = (event: any) => {
    dispatch(addPieBusinessDate(event));
  };

  useEffect(() => {
    if (isEmpty(usersWithGoals) && isEmpty(tasksBusinessPeriod.businessDate)) {
      dispatch(addPieBusinessDate(dayjs()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(tasksBusinessPeriod)) {
      const paramsString = new URLSearchParams({
        yearMonth: dayjs(tasksBusinessPeriod.businessDate).format('YYYY-MM'),
      }).toString();
      if (!isEqual(prevTasksBusinessPeriod.current, tasksBusinessPeriod.businessDate)) {
        dispatch(getUsersGoals(paramsString));
      }
    }
    prevTasksBusinessPeriod.current = tasksBusinessPeriod.businessDate;
  }, [tasksBusinessPeriod.businessDate]);

  return (
    <>
      <div className="graf-task">
        <div className="users-header goals">
          <h2>
            <FormattedMessage id="usersWithBusinessGoals" />
          </h2>
          <div className="data-wrap">
            <SelectDateMonth
              type="input"
              onChange={handleStartData}
              id="firstEntryDateStartRange"
              value={tasksBusinessPeriod.businessDate}
              position={position}
            />
          </div>
        </div>
        <div className="graf-item">
          <div className="pie-wrap">
            <div className="pie-stats">
              <div className="pie-info">
                <FormattedMessage id="total" />
                <span>{usersWithGoals.total}</span>
              </div>
              <div className="pie-info blue">
                <FormattedMessage id="withBusinessGoals" />
                <span>{usersWithGoals.withGoals}</span>
              </div>
              <div className="pie-info red">
                <FormattedMessage id="withoutBusinessGoals" />
                <span>{usersWithGoals.withoutGoals}</span>
              </div>
            </div>
            <RenderWithCondition condition={!isLoadingPage}>
              <PieStatChart total={usersWithGoals.total} data={formattedDataGoalsPie(usersWithGoals)} />
            </RenderWithCondition>
            <LocalLoader condition={isLoadingUsersGoals || isLoadingPage} size={25} />
          </div>
        </div>
      </div>
    </>
  );
};
