import React, { useState, useEffect, useRef, MouseEvent } from 'react';

import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { ReactComponent as CalendarIconColored } from '@assets/icons/calendar-icon.svg';

import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import { ReactComponent as LeftIcon } from '@assets/icons/chevron-left.svg';
import { ReactComponent as RightIcon } from '@assets/icons/chevron-right.svg';

import Calendar from 'react-calendar';
import { formattedMonthTitle } from '../../../utils/formattedMonthTitle';
import { useClickAway } from 'react-use';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import './selectDate.scss';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';

const customPrev2Label = <div className="prev2Label"></div>;

export type ValuePiece = Date | null;

interface ISelectDate {
  id: string;
  type: string;
  value: any;
  onChange: (event: dayjs.Dayjs) => void;
  simpleVew?: boolean;
  position: CalendarPosition;
}

export const SelectDateMonth = ({ onChange, value, id, simpleVew, position }: ISelectDate) => {
  const [isVisible, setVisible] = useState(false);
  const startDateProps = simpleVew ? formattedMonthTitle('ru', value, 'YYYY') : formattedMonthTitle('ru', value, 'YY');
  const [currentDate, setCurrentDate] = useState(startDateProps);
  const [startDate, setStartDate] = useState(new Date());

  const ref = useRef(null);
  useClickAway(ref, () => {
    setVisible(false);
  });

  useEffect(() => {
    setCurrentDate(startDateProps);
  }, [value]);

  const handlePickDate = (event: Value | ValuePiece) => {
    const currDate = simpleVew
      ? formattedMonthTitle('ru', event as Date | null, 'YYYY')
      : formattedMonthTitle('ru', event as Date | null, 'YY');

    setCurrentDate(currDate);
    onChange(dayjs(event as Date | null));
    setVisible(false);
  };

  return (
    <div className="select-date" ref={ref}>
      <div className={`select-body ${isVisible ? 'active' : ''}`}>
        <div className={`select ${simpleVew ? 'simple' : ''}`} onClick={() => setVisible(!isVisible)}>
          <RenderWithCondition condition={simpleVew}>
            <CalendarIconColored />
          </RenderWithCondition>
          <span>{currentDate}</span>
          <RenderWithCondition condition={!simpleVew}>
            <CalendarIcon />
          </RenderWithCondition>
        </div>
        {isVisible ? (
          <div className={`calendar-wrapper ${position}`}>
            <>
              <Calendar
                formatMonthYear={formattedMonthTitle}
                navigationAriaLabel={'none'}
                tileClassName="content"
                prev2Label={customPrev2Label}
                next2Label={''}
                prevLabel={<LeftIcon fill="#297952" />}
                nextLabel={<RightIcon fill="#297952" />}
                onChange={handlePickDate}
                formatLongDate={(locale, date) => dayjs(locale).format('DD-MM-YYYY')}
                defaultView="year"
                value={startDate}
                onClickMonth={handlePickDate}
              />
            </>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
