import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCodeApi, getStatusUser } from '@store/auth/api';
import { getAdminLog } from '@store/auth/slice';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import cookieService from '../../../services/cookie.service';
import { HAVE_EMPLOYEES, SUPERVISOR } from '@components/constants/constants';
import { regexPhone } from './constants';
import styles from './auth.module.scss';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask';

export const Auth = () => {
  const navigate = useNavigate();

  const [login, setLogin] = useState('');
  const [isValid, setIsValid] = useState(true);

  const isDisabledButton = login.length < 3;
  const dispatch = useDispatch();

  const getKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAuth();
    }
  };

  useEffect(() => {
    if (isDisabledButton) {
      setIsValid(true);
    }
  }, [isDisabledButton]);

  const handleAuth = async () => {
    setIsValid(true);
    try {
      const response = await dispatch(getStatusUser(login));
      const isAdmin = response.payload.response.isAdmin;
      const isHaveEmployees = response.payload.response.haveEmployees;
      const isError = response.payload.response.error;

      const options = { path: '/' };
      cookieService.set(HAVE_EMPLOYEES, isHaveEmployees && !isError ? HAVE_EMPLOYEES : '', options);

      if (isAdmin && !isError) {
        const options = { path: '/' };
        cookieService.set(SUPERVISOR, '', options);

        if (regexPhone.test(login)) {
          const response = await dispatch(getCodeApi(login));
          const isRegistered = response.payload.response.data.registered;
          if (isRegistered) {
            navigate('/check');
          }
        } else {
          dispatch(getAdminLog(login));
          navigate('/auth-admin');
        }
      } else if (!isAdmin) {
        const options = { path: '/' };
        cookieService.set(SUPERVISOR, SUPERVISOR, options);

        const response = await dispatch(getCodeApi(login));
        const isRegistered = response.payload.response.data.registered;
        if (isRegistered) {
          navigate('/check');
        }
      }
    } catch (err) {
      console.log('handleAuth err', err);
    }
    setIsValid(false);
  };

  return (
    <div className={styles.auth_wrapper}>
      <div className={styles.auth_content}>
        <div className={styles.modal_user}>
          <h1>
            <FormattedMessage id="loginToPersonalAccount" />
          </h1>
          <div className={styles.body}>
            <div>
              <span>
                <FormattedMessage id="phoneLogin" />
              </span>
              <div className="input-login">
                <input
                  value={login}
                  id="login"
                  placeholder="+7 "
                  onChange={(e) => setLogin(e.target.value)}
                  className={cn({ [styles.input_error]: !isValid })}
                  onKeyDown={getKeyDown}
                />
                {!isValid && (
                  <div className={styles.error}>
                    <span className={styles.line_error}>
                      <FormattedMessage id="invalidLogin" />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <button disabled={isDisabledButton} onClick={handleAuth}>
            <FormattedMessage id="next" />
          </button>
        </div>
      </div>
    </div>
  );
};
