import React from 'react';

import { titlesAdmin } from './constants';
import { ButtonShowUsers } from '@components/shared/buttonShowUsers';
import { TableAdminItem } from './TableAdminItem';
import { useAdmins } from '@store/admin/selectors';
import { LIMIT_PAGE } from '@components/constants/constants';
import { IStateAdmin } from 'interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';
import { LocalLoader } from '@ui/loader/LocalLoader';

import './tableAdmin.scss';

interface ITableAdmin {
  adminsList: IStateAdmin[];
  isLoading: boolean;
  handleGetAdmins: () => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleGetIdPassword: (id: string) => void;
  handleDeleteAdmin: (id: string, data: Record<string, string>) => void;
}

export const TableAdmin = ({
  isLoading,
  adminsList,
  setModalOpen,
  handleGetAdmins,
  handleDeleteAdmin,
  handleGetIdPassword,
}: ITableAdmin) => {
  // @ts-ignore
  const { isLoadingExpand, lengthList } = useAdmins();
  const isNext = lengthList ? Number.isInteger(lengthList / LIMIT_PAGE) : false;

  return (
    <div className="admin-wrapper">
      <div className="admin">
        <div className="admin-header">
          {titlesAdmin.map((title) => (
            <div className="admin-header-title" key={title}>
              <FormattedMessage id={title} />
            </div>
          ))}
        </div>
        <div className="admin-body">
          {adminsList &&
            adminsList.map((user, index: number) => (
              <TableAdminItem
                i={index}
                user={user}
                key={user.id}
                setModalOpen={setModalOpen}
                handleDelete={handleDeleteAdmin}
                handleGetIdPassword={handleGetIdPassword}
              />
            ))}
          <ButtonShowUsers
            isActive={isNext}
            title={'showMore'}
            loading={isLoadingExpand}
            handleEvents={handleGetAdmins}
          />
        </div>
        <LocalLoader condition={isLoading} />
      </div>
    </div>
  );
};
