export const titlesTableAdmin = [
  'phoneNumber',
  'fullNameEmployee',
  'subdivision',
  'firstActivity',
  'lastActivity',
  'rights',
  'test',
];
export const titlesTable = ['phoneNumber', 'fullNameEmployee', 'subdivision', 'firstActivity', 'lastActivity'];
