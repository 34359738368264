export const DOGMA_USER = 'DOGMA_USER';
export const SUPERVISOR = 'supervisor';
export const HAVE_EMPLOYEES = 'haveEmployees';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

export const LIMIT_PAGE = 20;

// export const supervisor = true; //TODO ============= SUPERVISOR ======
export const isFullVersion = true;

export const tablet = 1200;
export const mobile = 700;
