import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultFilter } from '@store/user/constants';
import { IFilter } from 'interfaces/filter.interfaces';
import { ISpaceId, IStateAdmin, IStateUser } from 'interfaces/user.interfaces';
import { getAdmins, deleteAdmin, updateAdmin, getMoreAdmins, getAllUsers, createAdmin, getSpaceId } from './api';

interface IState {
  currentUser: IStateAdmin | null;
  admins: IStateAdmin[];
  allUsers: IStateUser[];
  isLoading: boolean;
  isLoadingExpand: boolean;
  errorMsg: null | string;
  lengthList: number;
  filter: IFilter;
  spaceId: ISpaceId[] | null;
}

const initialState: IState = {
  currentUser: null,
  admins: [],
  allUsers: [],
  isLoading: false,
  errorMsg: null,
  lengthList: 0,
  isLoadingExpand: false,
  filter: defaultFilter,
  spaceId: null,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setFilterAdmins: (state: IState, action: PayloadAction<Partial<IFilter>>) => {
      try {
        const valueArg = action.payload;
        for (const key in valueArg) {
          if (Object.hasOwnProperty.call(valueArg, key) && Object.hasOwnProperty.call(state.filter, key)) {
            state.filter[key] = valueArg[key];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    addNextAdmin: (state: IState, { payload }) => {
      state.currentUser = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdmins.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getAdmins.fulfilled, (state, { payload }) => {
        state.admins = payload?.response?.data.content;
        state.lengthList = payload?.response?.data.content.length;
        state.isLoading = false;
      })
      .addCase(getAdmins.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        state.allUsers = payload?.response?.data.content;
        state.lengthList = payload?.response?.data.content.length;
        state.isLoading = false;
      })
      .addCase(getAllUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getMoreAdmins.pending, (state) => {
        state.isLoadingExpand = true;
        state.errorMsg = null;
      })
      .addCase(getMoreAdmins.fulfilled, (state, { payload }) => {
        state.admins = [...state.admins, ...payload?.response?.data.content];
        state.lengthList = payload?.response?.data.content.length;
        state.isLoadingExpand = false;
      })
      .addCase(getMoreAdmins.rejected, (state, { payload }) => {
        state.isLoadingExpand = false;
        state.errorMsg = payload;
      })

      .addCase(deleteAdmin.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(deleteAdmin.fulfilled, (state, { payload }) => {
        state.admins = [...state.admins].filter((item) => item.id !== payload.id);
        state.isLoading = false;
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      })

      .addCase(updateAdmin.pending, (state) => {
        // state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(updateAdmin.fulfilled, (state, { payload }) => {
        state.currentUser = payload?.response?.data;
        state.admins = state.admins.map((user) =>
          user.id === payload.response.data.id ? payload.response.data : user,
        );
        state.isLoading = false;
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      })

      .addCase(createAdmin.pending, (state) => {
        // state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(createAdmin.fulfilled, (state, { payload }) => {
        if (state.currentUser) {
          state?.currentUser?.accounts.push(payload.response.data);
          state.admins = [state.currentUser, ...state.admins];
        }
        state.isLoading = false;
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      })

      .addCase(getSpaceId.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getSpaceId.fulfilled, (state, { payload }) => {
        state.spaceId = payload?.response?.data;
        state.isLoading = false;
      })
      .addCase(getSpaceId.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});
export const { setFilterAdmins, addNextAdmin } = adminSlice.actions;
export const selectAdminSlice = (state: { admin: IState }) => state.admin;
export default adminSlice.reducer;
