import React, { FC, useState } from 'react';

import { ReactComponent as StopIcon } from '@assets/icons/stop-sign.svg';
import { IUserSessions } from 'interfaces/user.interfaces';
import { ReactComponent as DefaultAvatarIcon } from '@assets/icons/default-avatar.svg';
import { ReactComponent as IosPhoneIcon } from '@assets/icons/ios-phone.svg';
import { ReactComponent as LaptopIcon } from '@assets/icons/laptop.svg';
import { ReactComponent as WindowsIcon } from '@assets/icons/windows.svg';
import { ReactComponent as AndroidIcon } from '@assets/icons/android.svg';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';
import { uniqueId } from 'lodash';

interface IItemSession {
  data: IUserSessions[];
  buttonHidden?: boolean;
  onClick?: () => void;
}

export const ItemSession: FC<IItemSession> = (props) => {
  const getIconSession = (value: string) => {
    switch (value) {
      case 'Android':
        return <AndroidIcon />;
      case 'x64':
        return <WindowsIcon />;
      case 'Mac OS':
        return <LaptopIcon />;
      case 'iOS':
        return <IosPhoneIcon />;
      default:
        return '';
    }
  };

  return (
    <>
      {props.data.map((session, i) => (
        <Container key={uniqueId('session')}>
          <LineText fontSize="16px" bold="700">
            <Icon>{getIconSession(session.operatingSystem)}</Icon>
            {session.model ?? 'Девайс не определен'}
          </LineText>
          <LineText>
            <Icon />
            {`DogmaTime ${session.appVersion} / ${session.operatingSystem ?? 'Девайс не определен'}`}
          </LineText>
          {/* //TODO: ====== Temporary hiding ========== */}
          {/* <LineText color="#A1B6AD">
            <Icon />
            'Krasnodar, Russia / В сети'
          </LineText> */}
          <RenderWithCondition condition={props.buttonHidden}>
            <>
              <Line />
              <ButtonWrap onClick={props.onClick}>
                <LineText color="#FF3B30" fontSize="16px">
                  <Icon>
                    <StopIcon />
                  </Icon>
                  <FormattedMessage id="endOtherSessions" />
                </LineText>
              </ButtonWrap>
              <LineText className="absolute bottom-[-30px]" color="#5F6E67" fontSize="16px">
                <FormattedMessage id="endOtherSessionsHelp" />
              </LineText>
            </>
          </RenderWithCondition>
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 603px;
  gap: 5px;
  padding: 12px;
  background-color: #ffff;
  box-sizing: border-box;
  position: relative;
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
`;
const Line = styled.div`
  display: flex;
  margin: 5px 0 15px;
  height: 1px;
  background-color: ${(props) => (props.color ? props.color : '#E3E9E7')};
`;
const ButtonWrap = styled.button`
  display: flex;
  width: 100%;
  min-height: inherit !important;
  background-color: transparent;
  margin-top: -14px;
  margin-bottom: -8px;
  padding: 16px;
  border-radius: 10px;
  &:hover {
    background-color: #e9606014;
  }
  cursor: pointer;
`;
