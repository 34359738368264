import { styled } from 'styled-components';

interface IProps {
  fontSize?: string;
  bold?: string;
  color?: string;
  leftIndent?: string;
  lineHeight?: string;
  nowrap?: boolean;
  alignText?: 'center' | 'start' | 'end';
}
interface IPropsContainerTable {
  borderRadius?: string;
  width?: string;
  background?: string;
}

export const LineText = styled.div<IProps>`
  display: flex;
  align-items: ${(props) => (props.alignText ? props.alignText : 'center')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-weight: ${(props) => (props.bold ? props.bold : 'normal')};
  color: ${(props) => (props.color ? props.color : '#274335')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : props.fontSize)};
  gap: 8px;
  padding-left: ${(props) => (props.leftIndent ? props.leftIndent : '0px')};
  white-space: ${(props) => (props.nowrap ? 'nowrap' : '')};
`;

export const ContainerTable = styled.div<IPropsContainerTable>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.background ? props.background : '#ffff')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '10px')};
  width: ${(props) => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.02);
`;

export const Line = styled.div`
  display: flex;
  margin: 5px 0;
  height: 1px;
  background-color: ${(props) => (props.color ? props.color : '#E3E9E7')};
`;

export const LineFlex = styled.div<{ border?: boolean }>`
  display: flex;
  width: 100%;
  gap: 20px 10px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: ${(props) => (props.border ? '1px solid #E3E9E7' : 'none')};
`;

export const Spacer = styled.div<{ px: number; horizontal?: boolean }>`
  margin-top: ${(props) => (!props.horizontal ? `${props.px}px` : undefined)};
  margin-right: ${(props) => (props.horizontal ? `${props.px}px` : undefined)};
`;
