import React, { FC } from 'react';

import { ContainerTable, LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'lodash';
import { ITotalPlaningBusinessTasks } from 'interfaces/stats.interfaces';
import { useWidthScreens } from '@hooks/useWidthScreens';
import styled from 'styled-components';

interface IDelegatedBusinessGoals {
  data: ITotalPlaningBusinessTasks[];
  userName: string;
}

export const DelegatedBusinessGoals: FC<IDelegatedBusinessGoals> = (props) => {
  const { width } = useWidthScreens();

  return (
    <div className="flex  flex-col gap-[60px]">
      <div className="flex  flex-col gap-[20px]">
        <LineText fontSize={width !== null && width > 1200 ? '32px' : '28px'}>
          <FormattedMessage id="Делегирование задач" />
        </LineText>
        <ContainerTable>
          {props.data.map((item: ITotalPlaningBusinessTasks) => (
            <LineTable className="justify-between" key={uniqueId(item.title)}>
              <LineText fontSize={'15px'} leftIndent={item.leftIndent} bold={item.bold}>
                <FormattedMessage id={item.title} />
                {props.userName}
              </LineText>
              <LineText fontSize={'15px'} bold={'700'}>
                {item.value}
              </LineText>
            </LineTable>
          ))}
        </ContainerTable>
      </div>
    </div>
  );
};

const LineTable = styled.div<{ border?: boolean }>`
  display: flex;
  width: 100%;
  gap: 20px 10px;
  align-items: center;
  padding: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid #e3e9e7;
  }
`;
