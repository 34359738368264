import { http } from '../utils/http';

export interface DataCreateUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  type: string | null;
  login: string | null;
}

class OtherService {
  constructor() {}

  getOthers = async (params: any): Promise<any> => {
    return await http.get(`/api/admin-service/v1/users/page?${params}`);
  };

  create = async (data: any): Promise<any> => {
    return await http.post(`/api/admin-service/v1/accounts`, data);
  };

  delete = async (id: string): Promise<any> => {
    return await http.delete(`/api/admin-service/v1/users/${id}`);
  };

  update = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.patch(`/api/admin-service/v1/users/${id}`, data);
  };

  addOtherIntoEmployees = async (id: string, spaceId: string): Promise<any> => {
    return await http.post(`/api/uaa/v1/admin/spaces/${spaceId}/include/users/${id}`);
  };
}

export const otherService = new OtherService();
