class ColorVariables {
  redLine = '#FF3B30';
  blueLine = '#007AFF';
  greenLine = '#219653';
  yellowLine = '#FFB800';
  violetLine = '#8264FB';
  orangeLine = '#FB9231';
  violetHover = '#dfd8ff';

  white = '#FFFFFF';
  white80 = '#ffffff82';

  greyBase = '#f5f5f5';
  grey1 = '#E3E9E7';
  grey2 = '#bba5a5';
  grey3 = '#f5f9f8';
  grey4 = '#b3beb8';
  grey5 = '#ecf0ef';

  greenBase = '#297952';
  greenHover = '#33c37d';
  greenActive = '#185035';
  greenClose = '#5F6E67';
  greenScroll = '#297952b7';

  textPrimary = '#121212E5';
  textColor = '#274335';

  shadowsTooltip = '#0f0f0f46';

  constructor() {}
}

export const colorsVar = new ColorVariables();
