import { colorsVar } from '@components/constants/colorVariables';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

interface Props {
  condition: boolean;
  withoutBlur?: boolean;
  posY?: string;
  size?: number;
  color?: string;
}

export const LocalLoader = ({ condition, withoutBlur, posY, color, size = 35 }: Props) => {
  if (condition) {
    return (
      <RenderWithCondition condition={condition}>
        <LoaderContainer condition={condition} posY={posY ?? '0px'} withoutBlur={withoutBlur}>
          <ReactLoading type="spin" color={'#33c37d'} height={size} width={size} />
        </LoaderContainer>
      </RenderWithCondition>
    );
  }
  return null;
};

const LoaderContainer = styled.section<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(props) => props.posY};
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.withoutBlur ? 'transparent' : `${colorsVar.white}`)};
  opacity: ${(props) => (props.withoutBlur ? 1 : 0.6)};
`;
