import React, { FC } from 'react';

import styled from 'styled-components';
import { IMapTasksDay } from 'interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';

interface ILabelsPolar {
  data?: IMapTasksDay;
}

export const LabelsPolar: FC<ILabelsPolar> = (props) => {
  return (
    <Container className="flex items-center flex-col gap-8">
      <LineText className="flex pr-3 top-[2%]">
        <FormattedMessage id="family" />
      </LineText>
      <LineText className="right-[50%] mr-[30%] top-[22%] w-[80px] justify-end flex-wrap">
        <div className="flex justify-end">
          <FormattedMessage id="finance1" />
        </div>
        <div className="flex justify-end">
          <FormattedMessage id="finance2" />
        </div>
      </LineText>
      <LineText className="left-[50%] ml-[26%] top-[22%] w-[80px] flex-wrap">
        <div className="flex whitespace-nowrap">
          <FormattedMessage id="health1" />
        </div>
        <div className="flex">
          <FormattedMessage id="health2" />
        </div>
      </LineText>
      <LineText className="right-[50%] mr-[30%] bottom-[22%] w-[80px] justify-end flex-wrap">
        <div className="flex justify-end">
          <FormattedMessage id="ethics1" />
        </div>
        <div className="flex justify-end">
          <FormattedMessage id="ethics2" />
        </div>
      </LineText>
      <LineText className="left-[50%] ml-[27%] bottom-[22%] w-[80px] flex-wrap">
        <div className="flex">
          <FormattedMessage id="social1" />
        </div>
        <div className="flex">
          <FormattedMessage id="social2" />
        </div>
      </LineText>

      <LineText className="flex bottom-[2%] ">
        <FormattedMessage id="education" />
      </LineText>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #03103c;
  opacity: 0.6;
`;
const LineText = styled.div`
  display: flex;
  position: absolute;
`;
