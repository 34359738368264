import { IDynamicsPlaningTasks, IMonthBusinessGoals } from 'interfaces/dynamics.interfaces';
import { http } from '../utils/http';

class DynamicsService {
  constructor() {}

  getMonthBusinessGoals = async (id: string, params: string): Promise<IMonthBusinessGoals[]> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/business-goals-count?${params}`);
  };

  getDynamicsPlaningTasks = async (id: string, params: string): Promise<IDynamicsPlaningTasks[]> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/tasks-priority?${params}`);
  };

  getDynamicsCompletionTasks = async (id: string, params: string): Promise<IDynamicsPlaningTasks[]> => {
    return await http.get(`/api/admin-service/v1/dashboards/users/${id}/tasks-completion?${params}`);
  };
}

export const dynamicsService = new DynamicsService();
