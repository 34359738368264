import React, { FC } from 'react';

import Notification from '@ui/notification/Notification';
import styled from 'styled-components';

interface IModalNotification {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  textTitle: string;
  confirmAccess: () => void;
  colorButton?: string;
}

const ModalNotification: FC<IModalNotification> = (props) => {
  const handleOnCancel = (event: React.MouseEvent<HTMLElement>) => {
    props.setIsVisible(false);
  };
  return (
    <Container onClick={() => props.setIsVisible(false)} className="flex">
      <div onClick={(e) => e.stopPropagation()} className="flex">
        <Notification
          textTitle={props.textTitle}
          onDone={props.confirmAccess}
          onCancel={handleOnCancel}
          colorButton={props.colorButton}
        />
      </div>
    </Container>
  );
};

export default ModalNotification;

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: rgba(29, 46, 38, 0.28);
  backdrop-filter: blur(0px);
  overflow-y: scroll;
`;
