import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '@services/auth.service';
import cookieService from '@services/cookie.service';

export const loginApi: any = createAsyncThunk(
  'auth/login',
  // @ts-ignore
  async (data: DataGetAuthToken, thunkAPI) => {
    try {
      const response = await authService.getAuthToken(data);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);
export const getMainUser: any = createAsyncThunk('auth/getMainUser', async (thunkAPI) => {
  try {
    const response = await authService.getMainUser();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getStatusUser: any = createAsyncThunk(
  'auth/getStatusUser',
  // @ts-ignore
  async (data: DataGetAuthToken, thunkAPI) => {
    try {
      const response = await authService.getStatusUser(data);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getCodeApi: any = createAsyncThunk('auth/getCode', async (phoneNumber: string, thunkAPI) => {
  try {
    const response = await authService.getAuthSmsCode(phoneNumber);

    return { response, phoneNumber };
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return thunkAPI.rejectWithValue('404');
    }
    return getError(error, thunkAPI);
  }
});

export const codeApi: any = createAsyncThunk(
  'auth/code',
  // @ts-ignore
  async (data: DataGetAuthToken, thunkAPI) => {
    try {
      const response = await authService.getCodeAuthToken(data);
      // const response = await axios.post(`${await globalState.getBaseUrlApi()}/mobile/auth/token`, data);

      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const checkAuth: any = createAsyncThunk('auth/checkAuth', async (thunkAPI) => {
  try {
    const accessToken = await cookieService.get('access_token');
    const supervisor = await cookieService.get('supervisor');
    const haveEmployees = await cookieService.get('haveEmployees');

    return { accessToken, supervisor, haveEmployees };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const registerApi = createAsyncThunk(
  'auth/registerApi',
  // @ts-ignore
  async (data: DataRegister, thunkAPI) => {
    try {
      // @ts-ignore
      const response = await authService.register(data);

      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

const getError = (error: any, thunkAPI: any) => {
  const message = (error.data && error.data.message) || error.message || error.toString();
  return thunkAPI.rejectWithValue(message);
};

export const deleteCurrentSession: any = createAsyncThunk('auth/deleteCurrentSession', async (thunkAPI) => {
  try {
    const response = await authService.deleteCurrentSession();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
