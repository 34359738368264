import { http } from '../utils/http';

class AdminService {
  constructor() {}

  getAdmins = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/users/page?${params}`);
  };

  getAllUsers = async (params: string): Promise<any> => {
    return await http.get(`/api/admin-service/v1/users/page?${params}`);
  };

  getSpaceId = async (): Promise<any> => {
    return await http.get('/api/uaa/v1/admin/spaces');
  };

  updatePassword = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.post(`/api/admin-service/v1/accounts/${id}/change-password`, data);
  };

  create = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.post(`/api/admin-service/v1/accounts/by-user/${id}`, data);
  };

  delete = async (id: string, data: Record<string, string>): Promise<any> => {
    return await http.delete(`/api/admin-service/v1/accounts/by-user/${id}`, { data: data });
  };
}

export const adminService = new AdminService();
