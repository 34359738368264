import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { loginApi } from '@store/auth/api';
import cn from 'classnames';
import { useFormik } from 'formik';
import { adminAuthSchema } from '@components/schemas';
import { useAuthSelector } from '@store/auth/selectors';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { APP_VERSION, DEVICE_ID } from '@configs/consts';
import styles from './auth.module.scss';
import { useDeviceData } from 'react-device-detect';

interface IAdminLogin {
  login: string | undefined;
  password: string;
}

export const AuthAdmin = () => {
  const [deviceId, setDeviceId] = useState('');
  const deviceInfo = useDeviceData(window.navigator.userAgent);

  const { adminLog, errorMsg } = useAuthSelector((state) => state.auth);
  const dispatch = useDispatch();
  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    if (!adminLog) {
      navigate('/auth');
    }
  }, []);

  useEffect(() => {
    const id = localStorage.getItem(DEVICE_ID);
    if (id) {
      setDeviceId(id);
    } else {
      const newId = uuidv4();
      setDeviceId(newId);
      localStorage.setItem(DEVICE_ID, newId);
    }
  }, []);

  const handleAuth = async (data: IAdminLogin) => {
    const dataAuth = {
      ...data,
      username: data.login,
      grant_type: 'password',
      device: {
        device_id: deviceId,
        brand: deviceInfo.device.vendor,
        model: deviceInfo.device.model
          ? `${deviceInfo.device.model} ${deviceInfo.os.version}`
          : `${deviceInfo.browser.name} ${deviceInfo.browser.major}`,
        operating_system: deviceInfo.os.name,
        app_version: `Dashboard ${APP_VERSION}`,
      },
    };
    try {
      await dispatch(loginApi(dataAuth));
    } catch (err) {
      console.log('handleAuth err', err);
    }
  };

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      login: adminLog,
      password: '',
    },
    validationSchema: adminAuthSchema,
    onSubmit: (values) => {
      handleAuth(values);
    },
  });

  return (
    <div className={styles.auth_wrapper}>
      <div className={styles.auth_content}>
        <form className="auth_admin" onSubmit={handleSubmit} action="">
          {/* <div className="modal-user"> */}
          <div className={styles.modal_user}>
            <h1>
              <FormattedMessage id="loginToPersonalAccount" />
            </h1>
            <div className={styles.body}>
              <div>
                <span>
                  <FormattedMessage id="login" />
                </span>
                <input
                  id="login"
                  onBlur={handleBlur}
                  disabled={!!adminLog}
                  value={adminLog}
                  onChange={handleChange}
                  className={cn({ [styles.input_error]: errors.login && touched.login })}
                />
                {errors.login && touched.login && (
                  <div className={styles.error}>
                    <span className={styles.line_error}>{(errors.password && touched.password) || !!errorMsg}</span>
                  </div>
                )}
              </div>
              <div>
                <span>
                  <FormattedMessage id="password" />
                </span>
                <input
                  id="password"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  placeholder={intl.formatMessage({ id: 'enterPassword' })}
                  className={cn({ [styles.input_error]: (errors.password && touched.password) || !!errorMsg })}
                />
                {errors.password && touched.password && (
                  <div className={styles.error}>
                    <span className={styles.line_error}>{errors.password}</span>
                  </div>
                )}
                {!!errorMsg && values.password && (
                  // <div className='error'>
                  <div className={styles.error}>
                    {/* <span className='line-error'> */}
                    <span className={styles.line_error}>
                      <FormattedMessage id="invalidPassword" />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <button type="submit">
              <FormattedMessage id="logInAccount" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
