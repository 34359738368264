import styled from 'styled-components';
import { ReactComponent as CheckedIcon } from '@assets/icons/checked__white.svg';

import { colorsVar } from '@components/constants/colorVariables';

interface Props {
  isActive: boolean;
  changeEvent: () => void;
  activeColor?: string;
  offColor?: string;
  borderColor?: string;
  borderColorHover?: string;
  size?: string;
  fillColor?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  className?: string;
  classNameLabel?: string;
}

const CheckInputMod = ({
  isActive,
  changeEvent,
  size = 'medium',
  fillColor = colorsVar.white,
  isDisabled = false,
  isRequired = false,
  label,
  className,
  classNameLabel,
}: Props) => {
  let fillColorValue = fillColor;
  if (isDisabled) {
    isActive ? (fillColorValue = colorsVar.white) : (fillColorValue = 'none');
  }
  if (!isDisabled) {
    isActive ? (fillColorValue = colorsVar.grey1) : (fillColorValue = 'none');
  }

  const checked = isActive ? 'square__active' : '';
  return (
    <InputContainer>
      <Square
        className={isDisabled ? 'square__disabled' : checked}
        tabIndex={0}
        onClick={(event) => {
          event.stopPropagation();
          if (isDisabled) return;
          changeEvent?.();
        }}
      >
        <CheckedIcon fill={fillColorValue} />
      </Square>
      {label && (
        <div className={`${'label'} `} onClick={() => !isDisabled && changeEvent?.()}>
          <p className={`${'label__element'} ${'classNameLabel'}`}>
            {label} {isRequired && <span className={`${'span__element'}`}>*</span>}
          </p>
        </div>
      )}
    </InputContainer>
  );
};

export default CheckInputMod;

const InputContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  align-items: center;
  gap: 16px;

  .label__element {
    font-size: 16px;
    cursor: pointer;
    .span__element {
      color: ${colorsVar.greenBase};
    }
  }
  &.open {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
    left: 45px;
  }
  &.open_assist {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
  }
  &.open_delete {
    scale: 1;
    opacity: 1;
    transition: all 0.5s ease;
    bottom: -5px;
    left: inherit;
    right: 30px;
    justify-content: end;
  }
`;

const Square = styled.div.attrs((props) => ({
  className: props.className,
}))`
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  border-width: 1.5px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-color: ${colorsVar.greenHover};

  &:hover {
    border-color: ${colorsVar.grey1};
  }
  svg {
    cursor: pointer;
    path {
      cursor: pointer;
      transition: fill 0.5s;
    }
  }
  &.square__active {
    background-color: ${colorsVar.greenBase};
    border: none;
  }
  &.square__disabled {
    background-color: ${colorsVar.grey1};
    border: none;
    cursor: no-drop;
    svg {
      cursor: no-drop;
      path {
        cursor: no-drop;
        transition: fill 0.5s;
      }
    }
  }

  .large {
    width: 28px;
    height: 28px;
  }

  .medium {
    width: 20px;
    height: 20px;
  }

  .small {
    width: 18px;
    height: 18px;
  }
`;
