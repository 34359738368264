import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { ReactComponent as DownArrowIcon } from '@assets/icons/chevron-down-arrow.svg';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { LineText } from '@components/commons/commonsStyled';
import AssistantItems from './Assistant.Items';
import { useWidthScreens } from '../../../../../hooks/useWidthScreens';
import { mobile, tablet } from '@components/constants/constants';
import { colorsVar } from '@components/constants/colorVariables';
import AvatarAssist from '@ui/avatarAssist/AvatarAssist';
import { IConnectionAssist } from 'interfaces/user.interfaces';
import { useStatsSelector } from '@store/statistic/selectors';
import { LocalLoader } from '@ui/loader/LocalLoader';
import styled, { keyframes } from 'styled-components';

interface IHeaderUserInfo {
  userNamesLine: string | null;
  urlAvatar?: string;
  isWideScreen: boolean;
  isOpen: boolean;
  onOpen: Dispatch<SetStateAction<boolean>>;
  setIsVisibleFilter: (arg: boolean | null) => void;
  connectionAssist: IConnectionAssist | null;
}

const HeaderUserInfo: FC<IHeaderUserInfo> = (props) => {
  const { isWideScreen, isTablet, isMobile } = useWidthScreens();
  const { userInfo, isLoadingUserInfo } = useStatsSelector((state) => state.user);

  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
    props.onOpen(!props.isOpen);
  };

  function getTextTooltip(rev?: string | undefined) {
    if (props.connectionAssist === null) return '';
    if (props.connectionAssist?.isManager) {
      return rev ? 'supervisor' : 'assistant';
    }
    if (props.connectionAssist?.isAssistant) {
      return rev ? 'assistant' : 'supervisor';
    }
  }

  const textTooltipAvatar = useMemo(() => {
    if (!userInfo?.isAssistant && !userInfo?.isManager) {
      return '';
    }
    return userInfo?.isAssistant ? 'assistant' : 'supervisor';
  }, [userInfo]);

  const currentDataAssist = useMemo(() => {
    if (!userInfo?.assistants?.length && !userInfo?.managers?.length) {
      return [];
    }
    return userInfo?.assistants?.length ? [...userInfo?.assistants] : [...userInfo?.managers];
  }, [userInfo]);

  return (
    <ContainerBlock>
      <LineHeader>
        <AvatarAssist
          textTooltip={textTooltipAvatar}
          isAssistant={userInfo?.isAssistant}
          isManager={userInfo?.isManager}
          size={isMobile ? 'medium' : 'large'}
        />
        <div className="flex flex-col gap-1 justify-center">
          <RenderWithCondition condition={isLoadingUserInfo || !props.userNamesLine}>
            <SkeletonUserName>
              <LocalLoader condition={isLoadingUserInfo || !props.userNamesLine} size={16} withoutBlur />
            </SkeletonUserName>
          </RenderWithCondition>
          <RenderWithCondition condition={!isLoadingUserInfo}>
            <LineText fontSize={isMobile ? '16px' : '20px'} bold="700">
              {props.userNamesLine}
            </LineText>
          </RenderWithCondition>
          <RenderWithCondition condition={isTablet && (userInfo.isManager || userInfo.isAssistant)}>
            <AssistantItems
              setIsVisibleFilter={props.setIsVisibleFilter}
              textTooltip={getTextTooltip('rev')}
              connectionAssist={props.connectionAssist}
              dataAssist={currentDataAssist}
            />
          </RenderWithCondition>
        </div>
        <RenderWithCondition condition={!isWideScreen}>
          <ArrowWrap onClick={handleToggle} className="flex flex-1" active={isVisible}>
            <DownArrowIcon />
          </ArrowWrap>
        </RenderWithCondition>
      </LineHeader>
      <AssistantItems
        setIsVisibleFilter={props.setIsVisibleFilter}
        textTooltip={getTextTooltip('rev')}
        connectionAssist={props.connectionAssist}
        dataAssist={currentDataAssist}
        isLoading={isLoadingUserInfo}
      />
    </ContainerBlock>
  );
};
export default HeaderUserInfo;

const ContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-bottom: 1px solid ${colorsVar.grey1};
  gap: 12px;
  @media screen and (max-width: ${tablet}px) {
    padding: 12px 20px;
  }
  @media screen and (max-width: ${mobile}px) {
    padding: 12px;
  }
`;

export const LineHeader = styled.div`
  display: flex;
  max-height: 65px;
  gap: 15px;
  padding: 0px 3px 0px 0px;
`;

export const AssistIcon = styled.div`
  position: absolute;
  background-color: ${colorsVar.white};
  right: 0px;
  bottom: 0px;
  border: 1px solid ${colorsVar.grey4};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 4%;
  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${mobile}px) {
    width: 14px;
    height: 14px;
  }
`;

const rotateArrowAnimationUp = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;
const rotateArrowAnimationDown = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
export const ArrowWrap = styled.div<{ active?: boolean }>`
  width: 100%;
  justify-content: end;
  align-items: center;
  padding: 10px 30px;
  cursor: pointer;

  @media screen and (max-width: ${tablet}px) {
    padding: 12px 0px;
  }
  svg {
    transform: rotate(0deg);
    animation: ${({ active }) => {
        return active ? rotateArrowAnimationUp : rotateArrowAnimationDown;
      }}
      500ms linear forwards;
  }
`;

const SkeletonUserName = styled.div`
  background-color: ${colorsVar.grey1};
  position: relative;
  height: 28px;
  width: 150px;
  border-radius: 8px;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
