import { getAvatarFiles } from '@store/user/api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

type THook = (args: { fileId: string | undefined }) => string | undefined;

const useGetUrlAvatar: THook = (args) => {
  const [urlAvatar, setUrlAvatar] = useState<string>();
  const dispatch = useDispatch();

  const getUrlAvatar = async () => {
    try {
      const paramsString = new URLSearchParams({
        directDownload: 'true',
      }).toString();
      if (args.fileId) {
        const url = await dispatch(getAvatarFiles({ id: args.fileId, params: paramsString }));
        const urlAvatar = url.error ? undefined : url.payload;
        setUrlAvatar(urlAvatar);
      }
    } catch (error) {
      console.log('handleAuth err', error);
      setUrlAvatar(undefined);
    }
  };

  useEffect(() => {
    if (args.fileId) {
      getUrlAvatar();
    }
  }, [args.fileId]);

  return urlAvatar;
};

export default useGetUrlAvatar;
