import React, { FC, useEffect, useState } from 'react';

import { commonTooltip } from '@components/screens/home/Home';
import { ChartPlanComplete } from '@components/screens/home/components/chartPlanComplete';
import { useDynamicsSelector } from '@store/dynamics/selectors';
import { getDynamicsCompletionTasks, getDynamicsPlaningTasks } from '@store/dynamics/api';
import { linesDynamicsCompletionTasks, linesDynamicsPlaningTasks } from '@components/screens/home/constants';
import {
  addEndDynamicsCompletionDate,
  addEndDynamicsPlaningDate,
  addStartDynamicsCompletionDate,
  addStartDynamicsPlaningDate,
} from '@store/dynamics/slice';
import { useStatsSelector } from '@store/statistic/selectors';
import { mapCompleteTasksOfUser, mapTitleLinesPlaningTasks } from './charts/constants';
import { CURRENT_USER_ID } from '@configs/consts';
import RadarChartPersonCompleteness from './charts/RadarChartPersonCompleteness';
import BarChartMonthBusinessGoals from './charts/BarChartMonthBusinessGoals';
import styled from 'styled-components';

const TableDynamics: FC = () => {
  const { currentUser } = useStatsSelector((state) => state.user);
  const [isWideScreen, setIsWideScreen] = useState(true);

  const currentUserId = currentUser?.id ? currentUser.id : localStorage.getItem(CURRENT_USER_ID);

  const {
    dynamicsPlaningTasks,
    dynamicsPlaningTasksPeriod,
    isLoadingPlaningTasks,
    dynamicsCompletionTasks,
    dynamicsCompletionTasksPeriod,
    isLoadingCompletionTasks,
  } = useDynamicsSelector((state) => state.dynamics);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1051px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <ContainerCharts className="flex">
      <BlockChart className="flex">
        <BarChartMonthBusinessGoals tooltip={commonTooltip} position={isWideScreen ? 'right' : 'left'} />
        <RadarChartPersonCompleteness position="left" />
      </BlockChart>
      <BlockChart className="flex">
        <ChartPlanComplete
          planed={true}
          data={dynamicsPlaningTasks}
          tooltip={commonTooltip}
          getData={getDynamicsPlaningTasks}
          period={dynamicsPlaningTasksPeriod}
          renderLine={linesDynamicsPlaningTasks}
          setEndDate={addEndDynamicsPlaningDate}
          isLoading={isLoadingPlaningTasks}
          setStartDate={addStartDynamicsPlaningDate}
          currentUser={currentUserId}
          textTitle="dynamicsPlaningTasks"
          lineData={mapTitleLinesPlaningTasks}
          position="right"
        />
      </BlockChart>
      <BlockChart className="flex">
        <ChartPlanComplete
          planed={true}
          data={dynamicsCompletionTasks}
          tooltip={commonTooltip}
          getData={getDynamicsCompletionTasks}
          period={dynamicsCompletionTasksPeriod}
          renderLine={linesDynamicsCompletionTasks}
          setEndDate={addEndDynamicsCompletionDate}
          isLoading={isLoadingCompletionTasks}
          setStartDate={addStartDynamicsCompletionDate}
          currentUser={currentUserId}
          textTitle="closePlansDynamics"
          lineRectanglesData={mapCompleteTasksOfUser}
          position="right"
        />
      </BlockChart>
    </ContainerCharts>
  );
};

export default TableDynamics;

export const ContainerCharts = styled.div`
  flex-direction: column;
  gap: 30px;
  padding: 30px;
`;

const BlockChart = styled.div`
  flex-grow: 1;
  gap: 60px 4%;
  position: relative;
  @media screen and (max-width: 1049px) {
    flex-direction: column;
  }
`;
