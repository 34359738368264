import { createSlice } from '@reduxjs/toolkit';
import {
  getDynamicsCompletionTasks,
  getDynamicsPersonalGoals,
  getDynamicsPlaningTasks,
  getMonthBusinessGoals,
} from './api';
import { IActiveUsersPeriod } from 'interfaces/stats.interfaces';
import { IDynamicsCompletionTasks, IDynamicsPlaningTasks, IMonthBusinessGoals } from 'interfaces/dynamics.interfaces';
import { IUserPersonalGoals } from 'interfaces/user.interfaces';

interface IState {
  monthBusinessGoalsPeriod: IActiveUsersPeriod;
  monthBusinessGoals: IMonthBusinessGoals[];
  isLoadingBusinessGoals: boolean;

  dynamicsPersonalGoalsPeriod: IActiveUsersPeriod;
  dynamicsPersonalGoals: IUserPersonalGoals;
  isLoadingPersonalGoals: boolean;

  dynamicsPlaningTasksPeriod: IActiveUsersPeriod;
  dynamicsPlaningTasks: IDynamicsPlaningTasks[];
  isLoadingPlaningTasks: boolean;

  dynamicsCompletionTasksPeriod: IActiveUsersPeriod;
  dynamicsCompletionTasks: IDynamicsCompletionTasks[];
  isLoadingCompletionTasks: boolean;

  isLoading: boolean;
  isFavorites: boolean;
  isLoadingExpand: boolean;
  errorMsg: null | string;
}

const initialState: IState = {
  monthBusinessGoalsPeriod: {} as IActiveUsersPeriod,
  monthBusinessGoals: [],
  isLoadingBusinessGoals: false,

  dynamicsPersonalGoalsPeriod: {} as IActiveUsersPeriod,
  dynamicsPersonalGoals: {} as IUserPersonalGoals,
  isLoadingPersonalGoals: false,

  dynamicsPlaningTasksPeriod: {} as IActiveUsersPeriod,
  dynamicsPlaningTasks: [],
  isLoadingPlaningTasks: false,

  dynamicsCompletionTasksPeriod: {} as IActiveUsersPeriod,
  dynamicsCompletionTasks: [],
  isLoadingCompletionTasks: false,

  isLoading: false,
  isFavorites: false,
  errorMsg: null,
  isLoadingExpand: false,
};

const dynamicsSlice = createSlice({
  name: 'dynamics',
  initialState,
  reducers: {
    addStartMonthBusinessDate: (state: IState, { payload }) => {
      state.monthBusinessGoalsPeriod.startDate = payload;
    },
    addEndMonthBusinessDate: (state: IState, { payload }) => {
      state.monthBusinessGoalsPeriod.endDate = payload;
    },

    addPersonalGoalsDate: (state: IState, { payload }) => {
      state.dynamicsPersonalGoalsPeriod.startDate = payload;
    },

    addStartDynamicsPlaningDate: (state: IState, { payload }) => {
      state.dynamicsPlaningTasksPeriod.startDate = payload;
    },
    addEndDynamicsPlaningDate: (state: IState, { payload }) => {
      state.dynamicsPlaningTasksPeriod.endDate = payload;
    },

    addStartDynamicsCompletionDate: (state: IState, { payload }) => {
      state.dynamicsCompletionTasksPeriod.startDate = payload;
    },
    addEndDynamicsCompletionDate: (state: IState, { payload }) => {
      state.dynamicsCompletionTasksPeriod.endDate = payload;
    },
    resetDynamicsState: (state: IState, { payload }) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getMonthBusinessGoals.pending, (state) => {
        state.isLoadingBusinessGoals = true;
        state.errorMsg = null;
      })
      .addCase(getMonthBusinessGoals.fulfilled, (state, { payload }) => {
        state.monthBusinessGoals = payload.response.data;
        state.isLoadingBusinessGoals = false;
      })
      .addCase(getMonthBusinessGoals.rejected, (state, { payload }) => {
        state.isLoadingBusinessGoals = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getDynamicsPlaningTasks.pending, (state) => {
        state.isLoadingPlaningTasks = true;
        state.errorMsg = null;
      })
      .addCase(getDynamicsPlaningTasks.fulfilled, (state, { payload }) => {
        state.dynamicsPlaningTasks = payload.response.data;
        state.isLoadingPlaningTasks = false;
      })
      .addCase(getDynamicsPlaningTasks.rejected, (state, { payload }) => {
        state.isLoadingPlaningTasks = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getDynamicsCompletionTasks.pending, (state) => {
        state.isLoadingCompletionTasks = true;
        state.errorMsg = null;
      })
      .addCase(getDynamicsCompletionTasks.fulfilled, (state, { payload }) => {
        state.dynamicsCompletionTasks = payload.response.data;
        state.isLoadingCompletionTasks = false;
      })
      .addCase(getDynamicsCompletionTasks.rejected, (state, { payload }) => {
        state.isLoadingCompletionTasks = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getDynamicsPersonalGoals.pending, (state) => {
        state.isLoadingPersonalGoals = true;
        state.errorMsg = null;
      })
      .addCase(getDynamicsPersonalGoals.fulfilled, (state, { payload }) => {
        state.dynamicsPersonalGoals = payload.response.data;
        state.isLoadingPersonalGoals = false;
      })
      .addCase(getDynamicsPersonalGoals.rejected, (state, { payload }) => {
        state.isLoadingPersonalGoals = false;
        // @ts-ignore
        state.errorMsg = payload;
      });
  },
});
export const {
  addStartMonthBusinessDate,
  addEndMonthBusinessDate,

  addPersonalGoalsDate,

  addStartDynamicsPlaningDate,
  addEndDynamicsPlaningDate,

  addStartDynamicsCompletionDate,
  addEndDynamicsCompletionDate,

  resetDynamicsState,
} = dynamicsSlice.actions;
export default dynamicsSlice.reducer;
