import React, { useMemo, useRef, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import { ReactComponent as UserDeleteIcon } from '@assets/icons/user_delete.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { LineText } from '@components/commons/commonsStyled';
import { useDispatch } from 'react-redux';
import { IManagersFull, setState } from '@store/assistants/assistants.slice';
import { IAssistFull } from './TableAssistItem';
import styled from 'styled-components';

interface ITooltipLine {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  setModalOpenDown: React.Dispatch<React.SetStateAction<boolean | null>>;
  isActive: boolean;
  openTab: boolean;
  data: IAssistFull | IManagersFull | null;
  assistData: IAssistFull | null;
  managerData: IManagersFull | null;
}

const TooltipLine = ({
  setModalOpen,
  setModalOpenDown,
  data,
  isActive,
  openTab,
  assistData,
  managerData,
}: ITooltipLine) => {
  const dispatch = useDispatch();

  function handleChange() {
    if (assistData) {
      dispatch(
        setState({
          currentDataForUpdate: assistData,
          currentDataForUpdateManager: null,
        }),
      );
    }
    if (managerData) {
      dispatch(
        setState({
          currentDataForUpdateManager: managerData,
          currentDataForUpdate: null,
        }),
      );
    }
  }

  return (
    <Container active={isActive}>
      <div className="flex flex-col gap-[10px]">
        <ItemHover
          className="rounded-t-[10px]"
          onClick={() => {
            setModalOpen(true);
            handleChange();
          }}
        >
          <div className="flex w-6 h-6">
            <EditIcon />
          </div>
          <LineText fontSize={'18px'}>Изменить {openTab ? 'ассистентов' : 'руководителей'}</LineText>
        </ItemHover>
        <ItemHover
          className="rounded-b-[10px]"
          onClick={() => {
            setModalOpenDown(true);
            handleChange();
          }}
        >
          <UserDeleteIcon />
          <LineText fontSize={'18px'}>Исключить {openTab ? 'руководителя' : 'ассистента'}</LineText>
        </ItemHover>
      </div>
    </Container>
  );
};

export default TooltipLine;

const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 60px;
  top: 15px;
  width: 300px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${colorsVar.white};
  scale: 0;
  transition: all 0.5s;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  ${({ active }) =>
    active &&
    `
  scale: 1;
  transition: all 0.5s;
  z-index: 2;
  `}
`;
const ItemHover = styled.div`
  display: flex;
  gap: 10px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${colorsVar.grey1};
  }
`;
