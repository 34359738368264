import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { ReactComponent as PlusEddIcon } from '@assets/icons/plus_add.svg';
import { LineText } from '@components/commons/commonsStyled';
import ItemAssist from './Item.Assist';
import { uniqueId } from 'lodash';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { colorsVar } from '@components/constants/colorVariables';
import { useAuthSelector } from '@store/auth/selectors';
import { IConnectionAssist } from 'interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';
import { truncateName } from '@utils/formattedText';
import { IAssistData, selectAssistSlice, setState } from '@store/assistants/assistants.slice';
import { useStatsSelector } from '@store/statistic/selectors';
import { useDispatch } from 'react-redux';
import ModalAssist from '../modalAssist/ModalAssist';
import { useAppSelector } from '@store/hooks';
import { updateManager } from '@store/assistants/api';
import { getUserInfo, getUsers } from '@store/user/api';
import { LocalLoader } from '@ui/loader/LocalLoader';
import styled from 'styled-components';

interface IHeaderUserInfo {
  urlAvatar?: string;
  setIsVisibleFilter: (arg: boolean | null) => void;
  textTooltip?: string;
  connectionAssist: IConnectionAssist | null;
  dataAssist: IAssistData[];
  isLoading?: boolean;
}

const AssistantItems: FC<IHeaderUserInfo> = (props) => {
  const [openUpdateAssist, setUpdateAssist] = useState<boolean | null>(false);
  const [search, setSearch] = useState('');

  const {
    fullManagersList,
    fullAssistsList,
    currentDataForUpdate,
    currentDataForUpdateManager,
    isLoadingAssist,
    checkedDataForUpdate,
  } = useAppSelector(selectAssistSlice);

  const { supervisor } = useAuthSelector((state) => state.auth);

  const { userInfo } = useStatsSelector((state) => state.user);
  const dispatch = useDispatch();

  function getShortName(data: IAssistData) {
    return `${data.lastName} ${truncateName(data.firstName)} ${truncateName(data.middleName)}`;
  }

  function handleChange() {
    dispatch(
      setState({
        currentDataForUpdate: null,
        currentDataForUpdateManager: {
          manager: {
            id: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            middleName: userInfo.middleName,
            avatarFileId: userInfo.avatarFileId,
          },
          assistants: userInfo.assistants,
        },
      }),
    );
    setUpdateAssist(true);
  }

  const handleUpdateAssist = () => {
    const managerId = currentDataForUpdateManager?.manager.id;
    const checkedIds = checkedDataForUpdate.map((user) => user.id);

    const newDataManager = {
      data: [
        {
          managerId,
          assistantIds: checkedIds,
        },
      ],
    };
    dispatch(updateManager(newDataManager));
    dispatch(
      setState({
        currentDataForUpdate: null,
        currentDataForUpdateManager: null,
      }),
    );
    setTimeout(() => {
      const params = new URLSearchParams({
        thirdPartyUsersOnly: 'false',
      }).toString();
      dispatch(getUsers(params));
      const newData = { id: userInfo.id };
      dispatch(getUserInfo(newData));
    }, 1200);
    setUpdateAssist(false);
  };

  const currentName = useMemo(() => {
    const nameAssist = `${userInfo?.lastName} ${userInfo?.firstName} ${userInfo?.middleName}`;
    return nameAssist;
  }, [userInfo]);

  const currentCheckedData = useMemo(() => {
    return currentDataForUpdate ? currentDataForUpdate?.managers : currentDataForUpdateManager?.assistants;
  }, [currentDataForUpdate, currentDataForUpdateManager]);

  return (
    <AccordWrap className="flex " open={!props?.isLoading && props.dataAssist?.length > 0}>
      <AssistContainer className="flex ">
        <LocalLoader condition={!!props.isLoading} size={16} />
        {props.dataAssist.map((item) => (
          <ItemAssist
            textName={getShortName(item)}
            key={uniqueId(item.id)}
            textTooltip={props.textTooltip}
            connectionAssist={props.connectionAssist}
            avatarId={item.avatarFileId ?? undefined}
            onHandleDelete={handleChange}
          />
        ))}
        <RenderWithCondition
          condition={!supervisor && props.dataAssist.length < 3 && props.connectionAssist?.isAssistant}
        >
          <AddButton className="flex" onClick={handleChange}>
            <CloseWrap className="flex ">
              <PlusEddIcon />
            </CloseWrap>
            <LineText fontSize="15px">
              <FormattedMessage id={'assistant'} />
            </LineText>
          </AddButton>
        </RenderWithCondition>
      </AssistContainer>

      <ModalAssist
        isOpenPopup={openUpdateAssist}
        setOpenPopup={setUpdateAssist}
        textTitle="changeList"
        onSave={handleUpdateAssist}
        handleSearch={() => undefined}
        searchValue={search}
        assistantName={currentName}
        checkedData={currentCheckedData}
        tabActive={true}
      />
    </AccordWrap>
  );
};
export default AssistantItems;

const AccordWrap = styled.div<{ open: boolean }>`
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s linear;
  ${({ open }) =>
    open &&
    `
    max-height: 70px;
    transition: max-height 0.7s linear;
  `}
`;
const AssistContainer = styled.div`
  gap: 8px;
  flex-wrap: wrap;
  position: relative;
`;
export const AvatarWrap = styled.div`
  border: 1px solid ${colorsVar.grey4};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;
const CloseWrap = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${colorsVar.white80};
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const AddButton = styled.div`
  padding: 2px 8px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid ${colorsVar.grey4};
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
