import * as yup from 'yup';

export const adminSchema = yup.object().shape({
  login: yup
    .string()
    // .number()
    // .typeError('Неверный формат')
    // .integer('Неверный формат')
    // .positive('Неверный формат ')
    .required('Заполните поле'),
  password: yup.string().min(5, 'Неверный формат').required('Заполните поле'),
  repassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Пароли не совпадают')
    .required('Заполните поле'),
});
export const adminPasswordSchema = yup.object().shape({
  password: yup.string().min(5, 'Неверный формат').required('Заполните поле'),
  repassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Пароли не совпадают')
    .required('Заполните поле'),
});

export const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

export const userSchema = yup.object().shape({
  login: yup
    .string()
    .matches(phoneRegExp, {
      message: 'Неверный формат',
      excludeEmptyString: false,
    })
    // .min(, 'Неверный формат ')
    .required('Заполните поле'),
  lastName: yup.string().required('Заполните поле'),
  firstName: yup.string().required('Заполните поле'),
  middleName: yup.string().required('Заполните поле'),
});
export const adminAuthSchema = yup.object().shape({
  // login: yup
  //   .string()
  //   // .email('Неверный формат ')
  //   .required('Заполните поле'),
  password: yup
    .string()
    // .min(5, 'Неверный формат')
    .required('Заполните поле'),
});
