import React, { useEffect, useMemo, useRef, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import { ReactComponent as StarIcon } from '@assets/icons/star.svg';
import { ReactComponent as SetMenuIcon } from '@assets/icons/menu-set.svg';
import AvatarAssist from '@ui/avatarAssist/AvatarAssist';
import { LineText } from '@components/commons/commonsStyled';
import useGetUrlAvatar from '@hooks/useGetUrlAvatar';
import { useClickAway } from 'react-use';
import TooltipLine from './TooltipLine';
import { useDispatch } from 'react-redux';
import { IManagersFull } from '@store/assistants/assistants.slice';
import styled from 'styled-components';

export type IPartialAssist = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  avatarFileId: string | null;
  isActive?: boolean;
};
export type IAssistFull = {
  assistant: IPartialAssist;
  managers: IPartialAssist[];
};

interface ITableAssistItem {
  assistData: IAssistFull | null;
  managerData: IManagersFull | null;
  currentIndex: number;
  handleDelete: (id: string) => void;
  handleGetIdPassword: (id: string) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  setModalOpenDown: React.Dispatch<React.SetStateAction<boolean | null>>;
  openTab: boolean;
}

const TableAssistItem = ({
  currentIndex,
  assistData,
  managerData,
  handleDelete,
  setModalOpen,
  setModalOpenDown,
  handleGetIdPassword,
  openTab,
}: ITableAssistItem) => {
  const currentBackgroundColor = useMemo(() => {
    return currentIndex % 2 === 0 ? colorsVar.grey3 : colorsVar.white;
  }, [currentIndex]);

  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();

  const currentValueFileId = useMemo(() => {
    return assistData ? assistData?.assistant?.avatarFileId : managerData?.manager?.avatarFileId;
  }, [assistData, managerData]);

  const urlAvatar = useGetUrlAvatar({ fileId: currentValueFileId ?? undefined });
  const ref = useRef(null);

  useClickAway(ref, () => {
    setTimeout(() => {
      setIsActive(false);
    }, 200);
  });

  const textTooltip: string = useMemo(() => {
    return openTab ? 'supervisor' : 'assistant';
  }, [openTab]);
  const baseText: string = useMemo(() => {
    return assistData
      ? `${assistData.assistant.lastName} ${assistData.assistant.firstName} ${assistData.assistant.middleName}`
      : `${managerData?.manager?.lastName} ${managerData?.manager?.firstName} ${managerData?.manager?.middleName}`;
  }, []);
  const subText: string = useMemo(() => {
    if (assistData) {
      if (!assistData.managers.length) return '';
      return assistData.managers
        .map((manager) => `${manager?.lastName ?? ''} ${manager?.firstName ?? ''} ${manager?.middleName ?? ''}`)
        .filter((value) => value !== 'undefined')
        .join(', ');
    } else {
      if (managerData) {
        if (!managerData.assistants.length) return '';
        return managerData.assistants
          .map((assist) => `${assist?.lastName ?? ''} ${assist?.firstName ?? ''} ${assist?.middleName ?? ''}`)
          .filter((value) => value !== 'undefined')
          .join(', ');
      }
      return '';
    }
  }, []);

  return (
    <Container background={currentBackgroundColor}>
      <IconGroup className="flex">
        <IconWrapper size={20}>
          <StarIcon fill={colorsVar.grey4} />
        </IconWrapper>
        <AvatarAssist
          size="medium"
          textTooltip={textTooltip}
          isAssistant={!openTab}
          isManager={openTab}
          urlAvatar={urlAvatar}
        />
      </IconGroup>
      <div className="flex">
        <LineText fontSize={'16px'}>{baseText}</LineText>
      </div>
      <div className="flex">
        <LineText fontSize={'16px'}>{subText}</LineText>
      </div>
      <div
        onClick={() => setIsActive(!isActive)}
        className="flex justify-center items-center cursor-pointer h-full"
        ref={ref}
      >
        <SetMenuIcon fill={colorsVar.greenClose} />
      </div>

      <TooltipLine
        isActive={isActive}
        openTab={openTab}
        setModalOpen={setModalOpen}
        setModalOpenDown={setModalOpenDown}
        data={assistData ? assistData : managerData}
        assistData={assistData}
        managerData={managerData}
      />
    </Container>
  );
};

export default TableAssistItem;

const Container = styled.div<{ background: string }>`
  display: grid;
  position: relative;
  gap: 10px;
  min-height: 77px;
  grid-template-columns: 154px 1.2fr 2fr 70px;
  align-items: center;
  font-size: 16px;
  background-color: ${(props) => props && props.background};
`;

const IconGroup = styled.div`
  align-items: center;
  padding-left: 30px;
  gap: 14px;
`;

export const IconWrapper = styled.div<{ size: number }>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
