import React, { FC } from 'react';
import { ILineRectangles } from '@components/screens/month/components/tableDynamics/charts/constants';

import styled from 'styled-components';
import { SelectDate } from '@components/shared/selectDate';
import dayjs from 'dayjs';
import { IActiveUsersPeriod } from 'interfaces/stats.interfaces';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { LineGroupRectangles } from './LineGroupRectangles';
import { DoubleLineGroupRectangles } from './DoubleLineGroupRectangles';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';

interface IControlBlockCharts {
  data?: ILineRectangles[][];
  period: IActiveUsersPeriod;
  onStartDate: (value: dayjs.Dayjs) => void;
  onEndDate: (value: dayjs.Dayjs) => void;
  isHidden?: boolean;
  doubleLine?: ILineRectangles[][];
  line?: ILineRectangles[];
  wrap?: boolean;
  position: CalendarPosition;
}

export const ControlBlockCharts: FC<IControlBlockCharts> = (props) => {
  return (
    <Container className={`flex ${props.wrap ? 'flex-wrap' : null}`}>
      <SelectLine className="flex">
        <SelectDate
          type="input"
          onChange={props.onStartDate}
          id="firstEntryDateStartRange"
          value={dayjs(props.period.startDate).format('DD.MM.YY')}
          position={props.position}
        />
        <span>-</span>
        <SelectDate
          type="input"
          onChange={props.onEndDate}
          id="firstEntryDateStartRange"
          value={dayjs(props.period.endDate).format('DD.MM.YY')}
          position={props.position}
        />
      </SelectLine>
      <RenderWithCondition condition={props.line}>
        <LineGroupRectangles data={props.line} />
      </RenderWithCondition>
      <RenderWithCondition condition={!props.line}>
        <DoubleLineGroupRectangles data={props.doubleLine} isHidden={props.isHidden} />
      </RenderWithCondition>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px 20px;
  padding: 0px 0 20px 0;
`;
const SelectLine = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
  gap: 10px;
  align-items: center;
  position: relative;
  justify-content: start;
  flex-wrap: nowrap;
`;
