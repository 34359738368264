import React, { FC } from 'react';
import { ReactComponent as LogoDogmaIcon } from '@assets/icons/logo-dogma.svg';
import { Container } from './NavBar.Avatar';
import { IconWrap } from './NavBar.Exit';
import { LineText } from '@components/commons/commonsStyled';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';

interface INavBarLogo {
  hidden: boolean;
}

const NavBarLogo: FC<INavBarLogo> = (props) => {
  return (
    <>
      <ContainerLogo className="flex">
        <IconWrap className="flex">
          <LogoDogmaIcon />
        </IconWrap>
        <LineText fontSize={'16px'} nowrap>
          Dogma Time
        </LineText>
      </ContainerLogo>
    </>
  );
};

export default NavBarLogo;

const ContainerLogo = styled(Container)`
  padding-left: 14px;
  border-bottom: solid 1px ${colorsVar.grey1};
  cursor: default;
`;
