import { selectDeviceSlice } from '@store/device/device.slice';
import { useAppSelector } from '@store/hooks';

export const useWidthScreens = () => {
  const { width, height } = useAppSelector(selectDeviceSlice);

  const isWideScreen = width !== null && width > 1200;
  const isTablet = width !== null && width > 700 && width <= 1200;
  const isLaptop = width !== null && width > 1200 && width <= 1440;
  const isMobile = width !== null && width < 700;

  return {
    width,
    height,
    isWideScreen,
    isTablet,
    isLaptop,
    isMobile,
  };
};
