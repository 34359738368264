import React, { useState, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import './radioButtons.scss';

interface IRadioButtons {
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RadioButtons = ({ onChange }: IRadioButtons) => {
  const [isAmount, setAmount] = useState('amount');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
    onChange((prev) => !prev);
  };

  return (
    <>
      <form>
        <div className="radio-line">
          <span className="custom-radio" />
          <input
            className="radio-input"
            defaultChecked
            type="radio"
            value={isAmount}
            id="amount"
            onChange={handleChange}
            name="chartView"
          />
          <label className="radio-label" htmlFor="amount">
            <FormattedMessage id="amount" />
          </label>
        </div>
        <div className="radio-line">
          <span className="custom-radio" />
          <input
            className="radio-input"
            type="radio"
            value="percentage"
            id="percentage"
            onChange={handleChange}
            name="chartView"
          />
          <label className="radio-label" htmlFor="percentage">
            <FormattedMessage id="percentage" />
          </label>
        </div>
      </form>
    </>
  );
};
