import React, { useRef, MouseEvent, useEffect, useState } from 'react';

import './modal.scss';

import close from '@assets/icons/close.png';
import { handlerHide, handlerShow } from '@utils/non-scroll-flow';

export const Modal = ({ children, setIsVisible }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [translateY, setTranslateY] = useState<string>('');

  useEffect(() => {
    if (true) {
      setTimeout(() => {
        handlerShow();
        setTranslateY('0');
      }, 200);
      return () => {
        handlerHide();
      };
    }
  }, []);

  const onClickHandler = (event: MouseEvent<HTMLDivElement>) => {
    if (!ref?.current?.contains(event.target as any)) {
      setIsVisible(false);
    }
  };

  return (
    <article onClick={onClickHandler} className="modal-wrapper">
      <div className="modal-body" ref={ref} style={{ transform: `translateX(${translateY})` }}>
        <div className="close" onClick={() => setIsVisible(false)}>
          <img src={close} alt="" />
        </div>
        <div className="modal">{children}</div>
      </div>
    </article>
  );
};
