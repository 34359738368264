import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultFilter } from '@store/user/constants';
import { IFilter } from 'interfaces/filter.interfaces';
import { IStateAdmin, IStateUser } from 'interfaces/user.interfaces';
import { deleteAdmin, updateAdmin, getAllUsers, createAdmin, getFavorites, getMoreFavorites } from './api';

interface IState {
  currentUser: IStateAdmin | null;
  favorites: IStateUser[];
  allUsers: IStateUser[];
  isLoading: boolean;
  isLoadingExpand: boolean;
  errorMsg: null | string;
  lengthList: number;
  filter: IFilter;
}

const initialState: IState = {
  currentUser: null,
  favorites: [],
  allUsers: [],
  isLoading: false,
  errorMsg: null,
  lengthList: 0,
  isLoadingExpand: false,
  filter: defaultFilter,
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    setFilterFavorite: (state: IState, action: PayloadAction<Partial<IFilter>>) => {
      try {
        const valueArg = action.payload;
        for (const key in valueArg) {
          if (Object.hasOwnProperty.call(valueArg, key) && Object.hasOwnProperty.call(state.filter, key)) {
            state.filter[key] = valueArg[key];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    addNextFavorite: (state: IState, { payload }) => {
      state.currentUser = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFavorites.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getFavorites.fulfilled, (state, { payload }) => {
        state.favorites = payload?.response?.data.content;
        state.lengthList = payload?.response?.data.content.length;
        state.isLoading = false;
      })
      .addCase(getFavorites.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        state.allUsers = payload?.response?.data.content;
        state.lengthList = payload?.response?.data.content.length;
        state.isLoading = false;
      })
      .addCase(getAllUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getMoreFavorites.pending, (state) => {
        state.isLoadingExpand = true;
        state.errorMsg = null;
      })
      .addCase(getMoreFavorites.fulfilled, (state, { payload }) => {
        state.favorites = [...state.favorites, ...payload?.response?.data.content];
        state.lengthList = payload?.response?.data.content.length;
        state.isLoadingExpand = false;
      })
      .addCase(getMoreFavorites.rejected, (state, { payload }) => {
        state.isLoadingExpand = false;
        state.errorMsg = payload;
      })

      .addCase(deleteAdmin.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(deleteAdmin.fulfilled, (state, { payload }) => {
        state.favorites = [...state.favorites].filter((item) => item.id !== payload.id);
        state.isLoading = false;
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      })

      .addCase(updateAdmin.pending, (state) => {
        // state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(updateAdmin.fulfilled, (state, { payload }) => {
        state.currentUser = payload?.response?.data;
        state.favorites = state.favorites.map((user) =>
          user.id === payload.response.data.id ? payload.response.data : user,
        );
        state.isLoading = false;
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      })

      .addCase(createAdmin.pending, (state) => {
        // state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(createAdmin.fulfilled, (state, { payload }) => {
        if (state.currentUser) {
          state?.currentUser?.accounts.push(payload.response.data);
          // state.favorites = [state.currentUser, ...state.favorites];
        }
        state.isLoading = false;
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      });
  },
});
export const { setFilterFavorite, addNextFavorite } = favoriteSlice.actions;
export default favoriteSlice.reducer;
