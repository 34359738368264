import { IFilter } from 'interfaces/filter.interfaces';

export const defaultFilter: IFilter = {
  testOnly: null,
  loggedIn: null, // request only true
  isEnabled: null,
  notLoggedIn: null, // request only true
  departmentId: null,
  firstEntryDateStartRange: null,
  firstEntryDateEndRange: null,
  lastEntryDateStartRange: null,
  lastEntryDateEndRange: null,
};
