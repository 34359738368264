export const titleHome = 'Избранное';
export const infoMap = [
  { title: 'subdivision', key: 'departmentName' },
  { title: 'jobTitle', key: 'workPosition' },
  { title: 'birthday', key: 'birthDay' },
  { title: 'sex', key: 'gender' },
  { title: 'workPhone', key: 'workPhone' },
  { title: 'interofficeTelephone', key: 'workPhoneInner' },
  { title: 'contactEmail', key: 'email' },
];

export const mapNavBarMonth = [
  {
    titleLink: 'month',
    goTo: '/employees/month/month',
  },
  {
    titleLink: 'day',
    goTo: '/employees/month/day',
  },
  {
    titleLink: 'dynamics',
    goTo: '/employees/month/dynamics',
  },
  {
    titleLink: 'information',
    goTo: '/employees/month/info',
  },
  {
    titleLink: 'sessions',
    goTo: '/employees/month/sessions',
  },
];
