import React, { FC } from 'react';
import { ReactComponent as ExitIcon } from '@assets/icons/exit.svg';
import { FormattedMessage } from 'react-intl';
import { APP_VERSION } from '@configs/consts';
import { LineText } from '@components/commons/commonsStyled';
import { Container } from './NavBar.Avatar';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';

interface INavBarExit {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  hidden: boolean;
}

const NavBarExit: FC<INavBarExit> = (props) => {
  return (
    <ContainerExit className="flex" onClick={props.onClick}>
      <IconWrap className="flex">
        <ExitIcon fill={colorsVar.grey4} />
      </IconWrap>
      <div className="flex flex-col pl-[17px]">
        <LineText fontSize={'14px'} color={colorsVar.grey4}>
          <FormattedMessage id="exit" />
        </LineText>
        <LineText fontSize={'10px'} color={colorsVar.grey4}>
          {`v. ${APP_VERSION}`}
        </LineText>
      </div>
    </ContainerExit>
  );
};

export default NavBarExit;

const ContainerExit = styled(Container)`
  padding-left: 14px;
`;

export const IconWrap = styled.div`
  min-width: 20px;
  justify-content: center;
  align-items: center;
`;
