import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addAssists, deleteAssists, getAssists, getManagers } from './api';
import { IStateUser } from 'interfaces/user.interfaces';
// import { IAssistFull, IPartialAssist } from '@components/screens/assistants/components/TableAssistItem';

const mapAssistList = [
  {
    name: ' Иванов Иван Иванович',
    active: true,
  },
  {
    name: ' Иванов Петр Иванович',
    active: false,
  },
  {
    name: ' Иванов Сидор Иванович',
    active: true,
  },
  {
    name: ' Иванов Федор Иванович',
    active: false,
  },
  {
    name: ' Иванов Сергей Иванович',
    active: false,
  },
  {
    name: ' Иванов Ким Иванович',
    active: false,
  },
  {
    name: ' Иванов Лука Иванович',
    active: false,
  },
];

export type IPartialAssist = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  avatarFileId: string | null;
  isActive?: boolean;
};
export type IAssistFull = {
  assistant: IPartialAssist;
  managers: IPartialAssist[];
};
export type IManagersFull = {
  manager: IPartialAssist;
  assistants: IPartialAssist[];
};

export interface IAssistData {
  avatarFileId: string | null;
  firstName: string;
  lastName: string;
  middleName: string;
  id: string;
}
interface IAssist {
  assistList: typeof mapAssistList;
  currentUserListForCreating: IStateUser[];
  currentUserListForAssist: IStateUser[];
  currentUserListForManager: IStateUser[];
  currentDataForUpdate: IAssistFull | null;
  currentDataForUpdateManager: IManagersFull | null;
  checkedDataForUpdate: IPartialAssist[];
  fullAssistsList: IAssistFull[] | null;
  fullManagersList: IManagersFull[] | null;
  isLoadingAssist: boolean;
  lengthList: number;
  isLoadingExpand: boolean;
  [propName: string]: any;
}

const initialState: IAssist = {
  assistList: mapAssistList,
  fullAssistsList: null,
  fullManagersList: null,
  currentUserListForCreating: [],
  currentUserListForAssist: [],
  currentUserListForManager: [],
  currentDataForUpdate: null,
  currentDataForUpdateManager: null,
  checkedDataForUpdate: [],
  isLoadingAssist: false,
  lengthList: 0,
  isLoadingExpand: false,
};

export const assistSlice = createSlice({
  name: 'assist',
  initialState,
  reducers: {
    setState: (state: IAssist, action: PayloadAction<Partial<IAssist>>) => {
      try {
        const valueArg = action.payload;
        for (const key in valueArg) {
          if (Object.hasOwnProperty.call(valueArg, key) && Object.hasOwnProperty.call(state, key)) {
            state[key] = valueArg[key];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAssists.pending, (state) => {
        state.isLoadingAssist = true;
        state.errorMsg = null;
      })
      .addCase(getAssists.fulfilled, (state, { payload }) => {
        state.fullAssistsList = payload?.response?.data.content;
        state.lengthList = payload?.response?.data.content.length;
        state.isLoadingAssist = false;
      })
      .addCase(getAssists.rejected, (state, { payload }) => {
        state.isLoadingAssist = false;
        state.errorMsg = payload;
      })

      .addCase(getManagers.pending, (state) => {
        state.isLoadingAssist = true;
        state.errorMsg = null;
      })
      .addCase(getManagers.fulfilled, (state, { payload }) => {
        state.fullManagersList = payload?.response?.data.content;
        state.lengthListManagers = payload?.response?.data.content.length;
        state.isLoadingAssist = false;
      })
      .addCase(getManagers.rejected, (state, { payload }) => {
        state.isLoadingAssist = false;
        state.errorMsg = payload;
      })

      .addCase(addAssists.pending, (state) => {
        state.isLoadingAssist = true;
        state.errorMsg = null;
      })
      .addCase(addAssists.fulfilled, (state, { payload }) => {
        state.isLoadingAssist = false;
      })
      .addCase(addAssists.rejected, (state, { payload }) => {
        state.isLoadingAssist = false;
        state.errorMsg = payload;
      })

      .addCase(deleteAssists.pending, (state) => {
        state.isLoadingAssist = true;
        state.errorMsg = null;
      })
      .addCase(deleteAssists.fulfilled, (state, { payload }) => {
        state.isLoadingAssist = false;
      })
      .addCase(deleteAssists.rejected, (state, { payload }) => {
        state.isLoadingAssist = false;
        state.errorMsg = payload;
      });
  },
});

export const { setState } = assistSlice.actions;

export const selectAssistSlice = (state: { assist: IAssist }) => state.assist;

export default assistSlice.reducer;
