import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilter } from 'interfaces/filter.interfaces';
import {
  IDepartment,
  IStateUser,
  IUserBusinessGoals,
  IUserBusinessTasks,
  IUserDetailedBusinessGoals,
  IUserMonthGoal,
  IUserPersonalGoals,
  IUserSessions,
  IUserStatistic,
  IUserTasksOfDay,
} from 'interfaces/user.interfaces';
import {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
  getMoreUsers,
  getDepartments,
  getUserInfo,
  getUserStatistic,
  getUserMonthGoal,
  getUserPersonalGoals,
  getUserBusinessGoals,
  getUserTasksOfDay,
  getUserTasksOfMonth,
  getUserDetailedBusinessGoals,
  getUserSessions,
  removeSessions,
  getMainUserSessions,
  getUserBusinessTasks,
} from './api';
import { defaultFilter } from './constants';
import dayjs, { Dayjs } from 'dayjs';

interface IState {
  users: IStateUser[];
  currentUser: IStateUser | null;
  currentDate: Dayjs;
  userInfo: IStateUser;
  userStatistic: IUserStatistic;
  userMonthGoal: IUserMonthGoal;
  userPersonalGoals: IUserPersonalGoals;
  userBusinessGoals: IUserBusinessGoals;
  userBusinessTasks: IUserBusinessTasks;
  userDetailedBusinessGoals: IUserDetailedBusinessGoals[];
  userTasksOfDay: IUserTasksOfDay;
  userTasksOfMonth: IUserTasksOfDay;
  userSessions: IUserSessions[];
  errorMsgUserMonthGoal: null | string;
  isLoading: boolean;
  isLoadingExpand: boolean;
  isLoadingUsers: boolean;
  isLoadingUserInfo: boolean;
  errorMsg: null | string;
  errorMsgCreateUser: null | string;
  currentNameDep: null | string;
  lengthList: number;
  filter: IFilter;
  departments: IDepartment[] | null;
}

const initialState: IState = {
  users: [],
  currentUser: null,
  currentDate: dayjs(),
  userInfo: {} as IStateUser,
  userStatistic: {} as IUserStatistic,
  userMonthGoal: {} as IUserMonthGoal,
  userPersonalGoals: {} as IUserPersonalGoals,
  userBusinessGoals: {} as IUserBusinessGoals,
  userBusinessTasks: {} as IUserBusinessTasks,
  userDetailedBusinessGoals: [],
  userTasksOfDay: {} as IUserTasksOfDay,
  userTasksOfMonth: {} as IUserTasksOfDay,
  userSessions: [],
  errorMsgUserMonthGoal: null,
  isLoading: false,
  errorMsg: null,
  errorMsgCreateUser: null,
  currentNameDep: null,
  lengthList: 0,
  isLoadingExpand: false,
  isLoadingUsers: false,
  isLoadingUserInfo: false,
  filter: defaultFilter,
  departments: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFilter: (state: IState, action: PayloadAction<Partial<IFilter>>) => {
      try {
        const valueArg = action.payload;
        for (const key in valueArg) {
          if (Object.hasOwnProperty.call(valueArg, key) && Object.hasOwnProperty.call(state.filter, key)) {
            state.filter[key] = valueArg[key];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    addCurrentUser: (state: IState, { payload }) => {
      state.currentUser = payload;
    },
    changeCurrentMonthDay: (state: IState, { payload }) => {
      state.currentDate = payload;
    },
    currentDep: (state: IState, { payload }) => {
      state.currentNameDep = payload.departmentName;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.isLoadingUsers = true;
        state.errorMsg = null;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.users = payload?.response?.data.content;
        state.lengthList = payload?.response?.data.content.length;
        state.isLoading = false;
        state.isLoadingUsers = false;
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isLoadingUsers = false;
        state.errorMsg = payload;
      })

      .addCase(getUserSessions.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserSessions.fulfilled, (state, { payload }) => {
        state.userSessions = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserSessions.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getMainUserSessions.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getMainUserSessions.fulfilled, (state, { payload }) => {
        state.userSessions = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getMainUserSessions.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(removeSessions.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(removeSessions.fulfilled, (state, { payload }) => {
        const tempSessions = [...state.userSessions];

        state.userSessions = tempSessions.filter((i) => !payload.ids?.includes(i.id));
        state.isLoading = false;
      })
      .addCase(removeSessions.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(getMoreUsers.pending, (state) => {
        state.isLoadingExpand = true;
        state.errorMsg = null;
      })
      .addCase(getMoreUsers.fulfilled, (state, { payload }) => {
        state.users = [...state.users, ...payload?.response?.data.content];
        state.lengthList = payload?.response?.data.content.length;
        state.isLoadingExpand = false;
      })
      .addCase(getMoreUsers.rejected, (state, { payload }) => {
        state.isLoadingExpand = false;
        state.errorMsg = payload;
      })

      .addCase(getDepartments.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getDepartments.fulfilled, (state, { payload }) => {
        state.departments = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getDepartments.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getUserInfo.pending, (state) => {
        state.isLoading = true;
        state.isLoadingUserInfo = true;
        state.errorMsg = null;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.userInfo = payload.response.data;
        state.isLoading = false;
        state.isLoadingUserInfo = false;
      })
      .addCase(getUserInfo.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isLoadingUserInfo = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getUserStatistic.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserStatistic.fulfilled, (state, { payload }) => {
        state.userStatistic = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserStatistic.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getUserMonthGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMsgUserMonthGoal = null;
      })
      .addCase(getUserMonthGoal.fulfilled, (state, { payload }) => {
        state.userMonthGoal = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserMonthGoal.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsgUserMonthGoal = payload;
      })

      .addCase(getUserPersonalGoals.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserPersonalGoals.fulfilled, (state, { payload }) => {
        state.userPersonalGoals = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserPersonalGoals.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getUserBusinessGoals.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserBusinessGoals.fulfilled, (state, { payload }) => {
        state.userBusinessGoals = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserBusinessGoals.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getUserBusinessTasks.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserBusinessTasks.fulfilled, (state, { payload }) => {
        state.userBusinessTasks = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserBusinessTasks.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getUserDetailedBusinessGoals.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserDetailedBusinessGoals.fulfilled, (state, { payload }) => {
        state.userDetailedBusinessGoals = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserDetailedBusinessGoals.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getUserTasksOfDay.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserTasksOfDay.fulfilled, (state, { payload }) => {
        state.userTasksOfDay = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserTasksOfDay.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(getUserTasksOfMonth.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserTasksOfMonth.fulfilled, (state, { payload }) => {
        state.userTasksOfMonth = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getUserTasksOfMonth.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = payload;
      })

      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.errorMsgCreateUser = null;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.errorMsgCreateUser = null;
        state.isLoading = false;
        // state.users = [...state.users, payload.response.data];
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsgCreateUser = payload;
      })

      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.currentUser = null;
        state.users = [...state.users].filter((item) => item.id !== payload.id);
        state.isLoading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      })

      .addCase(updateUser.pending, (state) => {
        // state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.currentUser = payload?.response?.data;
        state.users = state.users.map((user) => (user.id === payload.response.data.id ? payload.response.data : user));
        state.isLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        state.errorMsg = action.payload;
      });
  },
});

export const { setFilter, addCurrentUser, currentDep, changeCurrentMonthDay } = userSlice.actions;
export const selectUserSlice = (state: { user: IState }) => state.user;
export default userSlice.reducer;
