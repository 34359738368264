import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import authReducer from './auth';
import adminReducer from './admin';
import favoriteReducer from './favorite';
import otherReducer from './other';
import statsReducer from './statistic';
import dynamicsReducer from './dynamics';
import deviceSlice from './device/device.slice';
import assistSlice from './assistants/assistants.slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    admin: adminReducer,
    favorite: favoriteReducer,
    other: otherReducer,
    stats: statsReducer,
    dynamics: dynamicsReducer,
    device: deviceSlice,
    assist: assistSlice,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
