import React, { FC, useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import './barStatChart.scss';
import { IMonthBusinessGoals } from 'interfaces/dynamics.interfaces';
import { data } from '@store/dynamics/constants';

interface IBarChartMonthBusinessGoals {
  data: IMonthBusinessGoals[];
  externalTooltip?: ({ active, payload, label }: any) => JSX.Element | null;
}

export const BarStatChart: FC<IBarChartMonthBusinessGoals> = (props) => {
  const [isWideScreen, setIsWideScreen] = useState(true);

  const numberAngle = props.data.length > 7 ? -90 : -25;
  const numberMargin = props.data.length > 7 ? 45 : 12;

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1049px)');
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <ResponsiveContainer width="100%" aspect={isWideScreen ? 1.36 : 1.86}>
      <BarChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 7,
          right: 0,
          left: -30,
          bottom: numberMargin,
        }}
      >
        <CartesianGrid vertical={false} height={350} />
        <XAxis
          tick={{ fill: '#274335' }}
          angle={numberAngle}
          textAnchor="end"
          fontSize={11}
          tickLine={false}
          axisLine={false}
          interval={0}
          fontFamily={'Roboto'}
          dataKey="yearMonth"
        />
        <YAxis
          tick={{ fill: '#274335' }}
          fontFamily={'Roboto'}
          fontSize={14}
          fontWeight={400}
          tickFormatter={(value) => (Number.isInteger(value) ? value : '')}
          tickLine={false}
          orientation="left"
          axisLine={false}
        />
        <Tooltip content={props.externalTooltip} />
        <Bar dataKey="totalGoals" fill="#007AFF" radius={5} maxBarSize={57} width={17} tooltipType="none" />
      </BarChart>
    </ResponsiveContainer>
  );
};
