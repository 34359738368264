import { colorsVar } from '@components/constants/colorVariables';

export interface ILineRectangles {
  name: string;
  color: string;
}
export const mapTitleLinesPlaningTasks = [
  { name: 'total', color: colorsVar.blueLine },
  { name: 'urgent', color: colorsVar.violetLine },
  { name: 'important', color: colorsVar.greenLine },
  { name: 'contact', color: colorsVar.orangeLine },
];

export const mapPlanedTasks = [
  [
    { name: 'totalPlanned', color: colorsVar.blueLine },
    { name: 'totalPlanned3', color: 'none' },
  ],
  [
    { name: 'personal', color: colorsVar.violetLine },
    { name: 'delegated', color: colorsVar.yellowLine },
  ],
];
export const mapCompleteTasks = [
  [
    { name: 'tasksWithStatus', color: 'none' },
    { name: 'followPlan', color: colorsVar.greenLine },
  ],
  [
    { name: 'notEngaged', color: colorsVar.violetLine },
    { name: 'workAwayPlan', color: colorsVar.yellowLine },
  ],
];
export const mapCompleteTasksOfUser = [
  [
    { name: 'workAwayPlan', color: colorsVar.yellowLine },
    { name: 'followPlan', color: colorsVar.greenLine },
  ],
  [
    { name: 'notWorkPlanForExternalReasons', color: colorsVar.violetLine },
    { name: 'notWorkPlanForPersonalReasons', color: colorsVar.blueLine },
  ],
];
