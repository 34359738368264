import React, { FC } from 'react';

import { ContainerTable, LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { IUserDetailedBusinessGoals } from 'interfaces/user.interfaces';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

interface IBTasksBusinessGoals {
  data: IUserDetailedBusinessGoals[];
}

export const TasksBusinessGoals: FC<IBTasksBusinessGoals> = (props) => {
  return (
    <Container className="flex flex-wrap">
      {props.data.map((item, i) => (
        <ContainerTable className="py-2 px-5 h-[82px] " width="calc(50% - 10px)" key={uniqueId('item')}>
          <div className="w-full h-[28px]">
            <LineText fontSize={'16px'} bold="700" alignText="start">
              <FormattedMessage id="goal" />
              {` ${i + 1}`}
            </LineText>
          </div>
          <div className="flex justify-start w-full  gap-5">
            <LineText fontSize={'14px'}>
              <FormattedMessage id="tasksToGoal" />
            </LineText>
            <ContainerTable
              className="py-[5px] px-[5px] h-[34px] justify-center"
              background={'#F5F9F8'}
              borderRadius={'5px'}
              width="fit-content"
            >
              <LineText fontSize={'16px'} bold={'700'}>
                {`${item.completedTasks}/${item.totalTasks}`}
              </LineText>
            </ContainerTable>
          </div>
        </ContainerTable>
      ))}
    </Container>
  );
};
const Container = styled.div`
  gap: 20px;
`;
