import { IFilter } from 'interfaces/filter.interfaces';

export const defaultFilter: IFilter = {
  testOnly: null,
  loggedIn: null, // request only true
  isEnabled: null,
  notLoggedIn: null, // request only true
  departmentId: null,
  firstEntryDateStartRange: null,
  firstEntryDateEndRange: null,
  lastEntryDateStartRange: null,
  lastEntryDateEndRange: null,
};
export const defaultGoals: string[] = [
  'FAMILY_AND_HOME',
  'FINANCE_AND_CAREER',
  'PHYSICAL_CONDITION_AND_HEALTH',
  'DEVELOPMENT_AND_SELF_EDUCATION',
  'SOCIAL_AND_CULTURAL',
  'SPIRITUAL_AND_ETHICAL',
];
export const optionsPersonalGoals = [
  { fill: '#219653', title: 'Дом / Семья', definition: 'home', active: true, nameLine: 'FAMILY_AND_HOME' },
  { fill: '#FF3B30', title: 'Финансы', definition: 'finance', active: true, nameLine: 'FINANCE_AND_CAREER' },
  { fill: '#FFB800', title: 'Духовные', definition: 'social', active: true, nameLine: 'SPIRITUAL_AND_ETHICAL' },
  { fill: '#FB9231', title: 'Социальные', definition: 'ethical', active: true, nameLine: 'SOCIAL_AND_CULTURAL' },
  {
    fill: '#007AFF',
    title: 'Саморазвитие',
    definition: 'educat',
    active: true,
    nameLine: 'DEVELOPMENT_AND_SELF_EDUCATION',
  },
  { fill: '#8264FB', title: 'Здоровье', definition: 'health', active: true, nameLine: 'PHYSICAL_CONDITION_AND_HEALTH' },
];
