import React, { ChangeEvent, useCallback, useEffect, useState, useRef } from 'react';

import { ModalRenderWithCondition } from '@components/hoc/ModalRenderWithCondition';
import { ModalFilter } from '@components/shared/modalFilter';
import { ModalUser } from '@components/shared/modalUser';
import { ControlBlock } from '@components/shared/controlBlock';
import { titleHome } from './constants';
import { LIMIT_PAGE } from '../../constants/constants';
import { useDispatch } from 'react-redux';
import { createUser, deleteUser, getMoreUsers, getUsers, getDepartments, updateUser } from '@store/user/api';
import { useUsers } from '@store/user/selectors';
import { RenderTableWithCondition } from '@components/hoc/RenderTableWithCondition';
import { usePagination } from '../../../hooks/usePagination';
import { Table } from './components/Table';
import { setFilter } from '@store/user/slice';
import { getIdDepartment, getOptionsDepartments } from '../../../utils/getOptionsDepartments';
import { useAuthSelector } from '@store/auth/selectors';
import { getFilterWithoutNull } from '../../../utils/getOptionsDepartments';
import { debounce } from 'lodash';
import notify from '@utils/notify';
import ModalNotification from '@components/shared/modalNotification/ModalNotification';

interface IDataNotification {
  id: string;
  data: { isEnabled: boolean };
}

export const Employees = () => {
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isVisibleUser, setIsVisibleUser] = useState(false);
  const [isVisibleNotification, setVisibleNotification] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');
  const [newNotification, setNewNotification] = useState({} as IDataNotification);

  const touchedSearchRef = useRef(false);
  if (search) touchedSearchRef.current = true;

  const text = newNotification?.data?.isEnabled ? 'giveRights' : 'takeRights';

  // @ts-ignore
  const { users, isLoadingExpand, lengthList, filter, departments, isLoading, errorMsgCreateUser } = useUsers();
  const filterOptionsDeps = departments ? [...getOptionsDepartments(departments)] : undefined;

  const { supervisor } = useAuthSelector((state) => state.auth);

  const { nextLoad, start } = usePagination(lengthList, LIMIT_PAGE);

  const dispatch = useDispatch();

  const debounceValue = debounce((value) => {
    setSearch(value);
  }, 2000);

  useEffect(() => {
    const usedFilter = Object.values(filter).every((value) => {
      if (value === null) {
        return true;
      }
      return false;
    });
    setIsFiltered(usedFilter);
  }, [filter]);

  useEffect(() => {
    if (!isFiltered) {
      handleFilteredUser();
    } else {
      const paramsString = new URLSearchParams({
        thirdPartyUsersOnly: 'false',
      }).toString();
      dispatch(getUsers(paramsString));
    }
  }, []);

  useEffect(() => {
    if (!departments?.length) {
      dispatch(getDepartments());
    }
  }, []);

  useEffect(() => {
    if (search || touchedSearchRef.current) {
      const tempObj = getFilterWithoutNull(filter);
      const paramsString = new URLSearchParams({
        query: search,
        thirdPartyUsersOnly: 'false',
        ...tempObj,
      }).toString();
      dispatch(getUsers(paramsString));
    }
  }, [search]);

  const handleGetMoreUsers = () => {
    nextLoad();
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      thirdPartyUsersOnly: 'false',
      ...tempObj,
      page: `${start}`,
    }).toString();
    if (Number.isInteger(lengthList / LIMIT_PAGE)) {
      dispatch(getMoreUsers(paramsString));
    }
  };

  const handleCreateUser = async (data: any) => {
    const idDep = getIdDepartment(departments, data.departmentId);
    const newData = {
      // ...data, type: 'LOGIN',
      ...data,
      departmentId: idDep,
      type: 'PHONE_NUMBER',
    };
    // @ts-ignore
    const response = await dispatch(createUser(newData));
    if (response.error) {
      notify.error(response.payload);
    } else {
      setIsVisibleUser(false);
    }
  };

  const handleFilteredUser = () => {
    const tempObj = getFilterWithoutNull(filter);
    const paramsString = new URLSearchParams({
      thirdPartyUsersOnly: 'false',
      ...tempObj,
    }).toString();

    setIsVisibleFilter(false);
    dispatch(getUsers(paramsString));
  };

  const handleSearchUsers = (event: ChangeEvent<HTMLInputElement>) => {
    debounceValue(event.target.value);
  };

  const handleDeleteUser = useCallback(
    (id: string) => {
      // @ts-ignore
      dispatch(deleteUser(id));
    },
    [users],
  );

  const handleChangeTest = useCallback((id: string, login: string, test: boolean) => {
    const newData = {
      id,
      data: {
        isTest: test,
      },
    };
    // @ts-ignore
    dispatch(updateUser(newData));
  }, []);

  const handleChangeAccess = (id: string, login: string, isEnabled: boolean) => {
    const newData = {
      id,
      data: {
        isEnabled,
        // login, password: '4444'
      },
    };
    setNewNotification(newData);
    setVisibleNotification(true);
  };
  const confirmAccess = () => {
    // @ts-ignore
    dispatch(updateUser(newNotification));
    setVisibleNotification(false);
    setNewNotification({} as IDataNotification);
  };

  const handleExcludeUser = (id: string, spaceId: string) => {
    const newData = {
      id,
      spaceId,
    };
    // @ts-ignore
    dispatch(deleteUser(newData));
  };

  return (
    <>
      <ControlBlock
        title={titleHome}
        supervisor={supervisor}
        openForm={setIsVisibleUser}
        openFilter={setIsVisibleFilter}
        handleSearch={handleSearchUsers}
      />

      <RenderTableWithCondition condition={!users?.length && !search && isFiltered}>
        <Table
          usersList={users}
          isLoading={isLoading}
          supervisor={supervisor}
          lengthList={lengthList}
          isLoadingExpand={isLoadingExpand}
          handleGetUsers={handleGetMoreUsers}
          handleDeleteUser={handleDeleteUser}
          handleChangeTest={handleChangeTest}
          handleExcludeUser={handleExcludeUser}
          handleChangeAccess={handleChangeAccess}
        />
      </RenderTableWithCondition>

      <ModalRenderWithCondition condition={isVisibleUser}>
        <ModalUser
          isEmployee={true}
          setIsVisible={setIsVisibleUser}
          handleCreate={handleCreateUser}
          options={departments && getOptionsDepartments(departments)}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleFilter}>
        <ModalFilter
          filter={filter}
          setFilter={setFilter}
          isFiltered={isFiltered}
          supervisor={supervisor}
          options={filterOptionsDeps}
          setIsVisible={setIsVisibleFilter}
          handleFiltered={handleFilteredUser}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleNotification}>
        <ModalNotification confirmAccess={confirmAccess} setIsVisible={setVisibleNotification} textTitle={text} />
      </ModalRenderWithCondition>
    </>
  );
};
