import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import dayjs from 'dayjs';
import { ReactComponent as LeftIcon } from '@assets/icons/chevron-left.svg';
import { ReactComponent as RightIcon } from '@assets/icons/chevron-right.svg';
import Calendar from 'react-calendar';
import { formattedMonthTitle } from '../../../utils/formattedMonthTitle';
import { useClickAway } from 'react-use';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { ValuePiece } from './SelectDateMonth';
import { CalendarPosition } from 'interfaces/selectDate.interfaces';
import 'react-calendar/dist/Calendar.css';
import './selectDate.scss';

const customPrev2Label = <div className="prev2Label"></div>;

interface ISelectDate {
  id: string;
  type: string;
  value: string;
  onChange: (value: dayjs.Dayjs) => void;
  position: CalendarPosition;
}

export const SelectDate = ({ onChange, value, id, position }: ISelectDate) => {
  const [isVisible, setVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(value);
  const [startDate, setStartDate] = useState(new Date());

  const ref = useRef(null);
  useClickAway(ref, () => {
    setVisible(false);
  });

  useEffect(() => {
    setCurrentDate(value ?? '');
  }, [value]);

  const handlePickDate = (event: Value | ValuePiece) => {
    const currDate = dayjs(event as Date | null).format('DD.MM.YY');

    setCurrentDate(currDate);
    onChange(dayjs(event as Date | null));
    setVisible(false);
  };

  return (
    <div className="select-date" ref={ref}>
      <div className={`select-body ${isVisible ? 'active' : ''}`}>
        <div className="select" onClick={() => setVisible(!isVisible)}>
          <span>{currentDate}</span>
          <CalendarIcon />
        </div>
        {isVisible ? (
          <div className={`calendar-wrapper ${position}`}>
            <>
              <Calendar
                formatMonthYear={formattedMonthTitle}
                navigationAriaLabel={'none'}
                tileClassName="content"
                prev2Label={customPrev2Label}
                next2Label={''}
                prevLabel={<LeftIcon fill="#297952" />}
                nextLabel={<RightIcon fill="#297952" />}
                onChange={handlePickDate}
                formatLongDate={(locale, date) => dayjs(locale).format('DD-MM-YYYY')}
                defaultView="month"
                value={startDate}
              />
            </>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
