import { createAsyncThunk } from '@reduxjs/toolkit';
import { dynamicsService } from '@services/dynamics.service';
import { userService } from '@services/user.service';

const getError = (error: any, thunkAPI: any) => {
  const message = (error.data && error.data.message) || error.message || error.toString();
  return thunkAPI.rejectWithValue(message);
};

export const getMonthBusinessGoals: any = createAsyncThunk(
  'dynamics/getMonthBusinessGoals',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await dynamicsService.getMonthBusinessGoals(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getDynamicsPersonalGoals: any = createAsyncThunk(
  'dynamics/getDynamicsPersonalGoals',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await userService.getUserPersonalGoals(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getDynamicsPlaningTasks: any = createAsyncThunk(
  'dynamics/getDynamicsPlaningTasks',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await dynamicsService.getDynamicsPlaningTasks(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getDynamicsCompletionTasks: any = createAsyncThunk(
  'dynamics/getDynamicsCompletionTasks',
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await dynamicsService.getDynamicsCompletionTasks(id, params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);
